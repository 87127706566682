<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex">
                <div class="ms-auto">
                    <router-link class="btn btn-primary float-right" :to="{ name: 'announcement-create' }">
                        <icon name="plus" />
                        New Announcement
                    </router-link>
                </div>
            </div>
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Subject</label>
                    <input class="form-control" type="text" v-model="inputFilter.title" @keyup.enter="fetchAnnouncement">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Message</label>
                    <input class="form-control" type="text" v-model="inputFilter.preview" @keyup.enter="fetchAnnouncement">
                </div>

                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetchAnnouncement" :is-loading="isFetchingAnnouncements"></button-filter>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th width="30%">Message</th>
                            <th class="text-center">Recipient</th>
                            <th class="text-center">Scheduled At</th>
                            <th class="text-center">Status</th>
                            <th class="text-center">Created At</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="announcements.length > 0">
                        <tr v-for="announcement in announcements" :key="announcement.id">
                            <td>{{ announcement.id }}</td>
                            <td>{{ announcement.title }}</td>
                            <td>{{ announcement.preview }}</td>
                            <td>
                                <p v-if="!announcement.cohort">
                                    {{ announcementRecipients.find(status => status.key === announcement.recipient).label }}
                                </p>
                                <router-link
                                    v-else
                                    class="text-blue"
                                    :to="{ name: 'notification-cohort-show', params: { cohortId: announcement.cohort.id } }"
                                    >
                                    {{ announcement.cohort.name ?? '' }}
                                </router-link>
                            </td>
                            <td class="text-center"><announcement-scheduled-at :send-at="announcement.sendAt" /></td>
                            <td class="text-center"><div class="badge badge-pill" :class="getStatusBadgeColor(announcement.status)">{{ announcementStatuses.find(status => status.key === announcement.status).label }}</div></td>
                            <td class="text-center">{{ formatDate(announcement.createdAt, 'DD-MMM-YYYY hh:mm A') }}</td>
                            <td class="text-end">
                                <div class="btn-group">
                                    <router-link class="btn btn-icon btn-primary" :to="{ name: 'announcement-edit', params: { announcementId: announcement.id } }">
                                        <icon name="edit" v-if="isDraft(announcement)" />
                                        <icon name="eye" v-else />
                                    </router-link>
                                    <button class="btn btn-icon btn-success" :class="{ 'disabled': !isDraft(announcement) }" @click.prevent="publishAnnouncement(announcement)" :disabled="!isDraft(announcement)">
                                        <icon name="send" />
                                    </button>
                                    <button class="btn btn-icon btn-danger" @click.prevent="deleteAnnouncement(announcement)" :disabled="!isAllowToDelete(announcement)">
                                        <icon name="trash" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="8" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isFetchingAnnouncements" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { formatDate, fromNow, toLocaleDate } from '@/composable/useDate'
import useMetaPage from '@/composable/useMetaPage'
import { AnnouncementStatusEnum } from '@/enums/AnnouncementStatusEnum'
import AnnouncementScheduledAt from '@/pages/marketings/announcements/AnnouncementScheduledAt.vue'
import AnnouncementService from '@/services/AnnouncementService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import Announcement from '@/types/Announcement'
import UserFilter from '@/types/Filter'
import { storeToRefs } from 'pinia'
import { ref, watch } from 'vue'

const { addToastNotification } = useNotificationsStore()
const { announcementStatuses, announcementRecipients } = storeToRefs(useResourcesStore())
const announcements = ref<Array<Announcement>>([])
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const inputFilter = ref<UserFilter>({
    title: '',
    preview: '',
})

const isFetchingAnnouncements = ref<boolean>(false)

const fetchAnnouncement = () => {
    isFetchingAnnouncements.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    AnnouncementService.index(query)
        .then(({ data: { data, meta } }) => {
            announcements.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isFetchingAnnouncements.value = false
        })
}

const publishAnnouncement = (announcement: Announcement) => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to publish announcement #${announcement.id}? Published announcement cannot be edited any more.`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            AnnouncementService.publish(announcement.id)
                .then(() => {
                    fetchAnnouncement()
                    addToastNotification({
                        message: `Announcement #${announcement.id} has been published`,
                        type: 'success',
                    })
                })
                .catch(({ response: { status, data } }) => {
                    let message = 'Internal server error. Please contact tech team.'
                    if (status === 403) {
                        message = data.message
                    }

                    addToastNotification({
                        message,
                        type: 'danger',
                    })
                })
        }
    })
}

const deleteAnnouncement = (announcement: Announcement) => {
    if (!isAllowToDelete(announcement)) {
        addToastNotification({
            message: `Unable to delete announcement as it is about to send`,
            type: 'danger',
        })
        return
    }

    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete announcement #${announcement.id}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            AnnouncementService.destroy(announcement.id)
                .then(() => {
                    fetchAnnouncement()
                    addToastNotification({
                        message: `Announcement #${announcement.id} has been deleted`,
                        type: 'success',
                    })
                })
                .catch(({ response: { status, data } }) => {
                    let message = 'Internal server error. Please contact tech team.'
                    if (status === 403) {
                        message = data.message
                    }

                    addToastNotification({
                        message,
                        type: 'danger',
                    })
                })
        }
    })
}

const resetFilter = () => {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }

    fetchAnnouncement()
}

const getStatusBadgeColor = (statusKey: number) => {
    switch (statusKey) {
        case AnnouncementStatusEnum.STATUS_DRAFT:
            return 'bg-info'
        case AnnouncementStatusEnum.STATUS_PUBLISHED:
            return 'bg-primary'
        case AnnouncementStatusEnum.STATUS_SENDING:
            return 'bg-warning'
        case AnnouncementStatusEnum.STATUS_SENT:
            return 'bg-success'
        default:
            return 'bg-grey'
    }
}

const isDraft = (announcement: Announcement) => {
    return announcement.status === AnnouncementStatusEnum.STATUS_DRAFT
}

const isAllowToDelete = (announcement: Announcement) => {
    // only allow user to delete announcement if:
    // 1. status is 1 (draft) or 2 (published)
    // 2. announcement is not about to send in 1 minute

    const deleteableAnnouncement: Array<number> = [
        AnnouncementStatusEnum.STATUS_DRAFT,
        AnnouncementStatusEnum.STATUS_PUBLISHED,
    ]

    const announcementSendAt = new Date(announcement.sendAt).getTime() / 1000

    return deleteableAnnouncement.includes(announcement.status) || (announcementSendAt - ((new Date().getTime()) / 1000)) > 60
}

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetchAnnouncement()
    }
)

fetchAnnouncement()

window.Echo.private('backoffice')
    .listen('.AnnouncementUpdated', ({ model }: any) => {
        const announcementIndex = announcements.value.findIndex(announcement => announcement.id === model.id)
        if (announcementIndex === -1) {
            return
        }

        announcements.value[announcementIndex] = model
    })

</script>

<style scoped>
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>
