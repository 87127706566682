import { getQueryString } from "@/composable/http";
import httpClientV2 from "@/services/HttpClientV2";
import StoreOrderFormData from "@/types/formData/StoreOrderFormData";
import GenerateOrderPreviewFormData from "@/types/formData/GenerateOrderPreviewFormData";

const endpoint = "/backoffice/orders";

export default {
  index(query: object = {}) {
    const queryString = getQueryString(query);
    return httpClientV2.get(`${endpoint}?${queryString}`);
  },

  store(data: StoreOrderFormData) {
    return httpClientV2.post(endpoint, data);
  },

  show(id: number) {
    return httpClientV2.get(`/backoffice/orders/${id}`);
  },

  update(id: number, data: object = {}) {
    return httpClientV2.patch(`/backoffice/orders/${id}`, data);
  },

  generatePreview(data: GenerateOrderPreviewFormData) {
    return httpClientV2.post("/backoffice/orders/preview", data);
  },

  generateReport(data: object = {}) {
    return httpClientV2.post("/backoffice/reports/orders", data);
  },

  getInvoicePdf(id: number) {
    return httpClientV2.get(`/backoffice/reports/orders/${id}/pdf`);
  },
};
