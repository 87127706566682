import {defineStore} from 'pinia'
import {computed, ref, shallowRef} from 'vue'
import CarerApplication from '@/types/CarerApplication'
import SessionCarerApplicationService from '@/services/SessionCarerApplicationService'
import Session from '@/types/Session'
import {useNotificationsStore} from '@/stores/notifications'
import {OrderStatusEnum} from '@/enums/OrderStatusEnum'
import {
    ActiveSessionCarerApplicationStatuses,
    InactiveSessionCarerApplicationStatuses,
    SessionCarerApplicationStatusEnum
} from '@/enums/SessionCarerApplicationStatusEnum'
import SessionCarerApplicationList
    from '@/pages/sessions/components/SessionCarerApplication/SessionCarerApplicationList.vue'
import AdditionalFeeCarerApplication
    from '@/pages/sessions/components/SessionCarerApplication/AdditionalFeeCarerApplication.vue'
import CancelSessionCarerApplication
    from '@/pages/sessions/components/SessionCarerApplication/CancelSessionCarerApplication.vue'
import {SessionRateAmount} from '@/types/formData/SessionRateAmount'
import DestroySessionCarerApplicationFormData from '@/types/formData/DestroySessionCarerApplicationFormData'
import CreateCarerApplicationForSession
    from '@/pages/sessions/components/SessionCarerApplication/CreateCarerApplicationForSession.vue'
import StoreSessionCarerApplicationFormData from '@/types/formData/StoreSessionCarerApplicationFormData'
import User from '@/types/User'
import SessionAssignedCarerService from '@/services/SessionAssignedCarerService'
import CarerApplicationAdditionalFeeService from '@/services/CarerApplicationAdditionalFeeService'
import {AxiosError} from 'axios'
import UpdateAdditionalFeeFormData from '@/types/formData/UpdateAdditionalFeeFormData'

export const useSessionCarerApplicationStore = defineStore('sessionCarerApplication', () => {

    const {addToastNotification} = useNotificationsStore()

    const currentComponent = shallowRef(SessionCarerApplicationList)
    const setCurrentComponent = (component: any) => currentComponent.value = component

    const isMainScreen = computed(() => currentComponent.value === SessionCarerApplicationList)
    const canBackToMainScreen = computed(() => currentComponent.value !== SessionCarerApplicationList)
    const backToMainScreen = () => setCurrentComponent(SessionCarerApplicationList)


    const currentScreenTitle = computed(() => {
        let title
        switch (currentComponent.value) {
            case SessionCarerApplicationList:
                title = `Carer Applications for Session ${ currentSession.value?.no }`
                break
            case AdditionalFeeCarerApplication:
                title = `Travel Incentive for Carer ${ currentSession.value?.carer?.mainProfile?.fullName }'s Application`
                break
            case CreateCarerApplicationForSession:
                title = `Create Carer Application for Session ${ currentSession.value?.no }`
                break
            case CancelSessionCarerApplication:
                title = 'Cancel carer\'s application'
                break

        }
        return title
    })

    const currentSession = ref<Session | null>(null)
    let carerApplications = ref<Array<CarerApplication>>([])
    const preferredCarers = ref<Array<User>>([])

    const isInitialised = ref(false)
    const isFetchingCarerApplications = ref<boolean>(false)
    const isSavingCarerApplication = ref<boolean>(false)
    const canPenaliseCarer = ref<boolean>(true)

    const isPaidSession = computed(() => currentSession.value?.order?.status === OrderStatusEnum.STATUS_PAID)
    const replacementBonus = computed(() => currentSession.value?.eligibleReplacementBonus as SessionRateAmount)

    const activeCarerApplications = computed<Array<CarerApplication>>(() => {
        return carerApplications.value.filter((application: CarerApplication) => {
            return ActiveSessionCarerApplicationStatuses.includes(application.sessionCarerApplicationStatusId)
        })
    })

    const cancelledCarerApplications = computed<Array<CarerApplication>>(() => {
        return carerApplications.value.filter((application: CarerApplication) => {
            return InactiveSessionCarerApplicationStatuses.includes(application.sessionCarerApplicationStatusId)
        })
    })

    const findApplication = (id: number) => {
        const index = carerApplications.value?.findIndex((application) => application.id === id)

        if (index !== -1) {
            return carerApplications.value[index]
        }

        return null
    }

    const fetchCarerApplications = async () => {

        if (!currentSession.value?.id) {
            return
        }

        isFetchingCarerApplications.value = true

        try {
            const {data} = await SessionCarerApplicationService.index(currentSession.value?.id)
            carerApplications.value = data.data
        } catch (err) {
            addToastNotification({
                message: 'Internal server error when fetching carer applications. Please contact tech team.',
                type: 'danger'
            })
        }

        setTimeout(() => isFetchingCarerApplications.value = false, 1000)
    }

    const deleteApplication = async (applicationId: number, carerName?: string) => {
        try {
            const data: DestroySessionCarerApplicationFormData = {
                cancel_reason: cancelApplicationForm.value?.cancel_reason,
                penalise_carer: cancelApplicationForm.value?.penalise_carer === 'true',
                add_replacement_bonus: cancelApplicationForm.value?.add_replacement_bonus
            }
            await SessionCarerApplicationService.destroy(applicationId, data)

            await fetchCarerApplications()

            addToastNotification({
                message: `${ carerName }'s application for this session is deleted.`,
                type: 'success'
            })
        } catch (err) {
            addToastNotification({
                message: `Error while deleting ${ carerName }'s application for this session. Please try again.`,
                type: 'danger'
            })
        }
    }

    const updateApplicationDetail = (model: CarerApplication) => {
        const index = carerApplications.value?.findIndex((application) => application.id === model.id)

        if (index !== -1) {
            carerApplications.value[index] = model

            return {success: true}
        } else {
            return {success: false}
        }
    }

    const setAsInitialised = (value = true) => isInitialised.value = value

    /*
       * Create Application
       *
       **/

    const createApplicationForm = ref<{
        carer: User | null,
        additional_fee: number
    }>({
        carer: null,
        additional_fee: 0
    })


    const createApplication = () => {
        setCurrentComponent(CreateCarerApplicationForSession)

    }

    const confirmCreateApplication = async () => {
        if (!currentSession.value) {
            addToastNotification({
                message: 'Failed to confirm create application due to invalid session id. Please contact tech team.',
                type: 'danger'
            })
            return
        }

        isSavingCarerApplication.value = true

        try {
            const data: StoreSessionCarerApplicationFormData = {
                carer_user_id: createApplicationForm.value?.carer?.id ?? 0,
                additional_fee: createApplicationForm.value?.additional_fee ?? 0
            }

            await SessionCarerApplicationService.store(currentSession.value?.id, data)

            await fetchCarerApplications()
            backToMainScreen()

            addToastNotification({
                message: `Successfully created new carer application for Session ${ currentSession.value?.no }`,
                type: 'success'
            })

        } catch (e) {
            if (e instanceof AxiosError) {
                if (e.response && e.response.status === 422) {
                    addToastNotification({
                        message: e.response.data.message,
                        type: 'danger'
                    })
                }
            } else {
                addToastNotification({
                    message: 'Failed to confirm create application. Please try again',
                    type: 'danger'
                })
            }
        } finally {
            isSavingCarerApplication.value = false
        }
    }


    /*
    * Cancel Application
    *
    **/

    const cancelApplicationForm = ref<{
        application_id: number
        carer_user_id: number
        status?: number
        cancel_reason?: string
        penalise_carer?: boolean|string
        add_replacement_bonus?: boolean
    }>({
        application_id: 0,
        carer_user_id: 0
    })

    const cancelApplication = (applicationId: number) => {
        setCurrentComponent(CancelSessionCarerApplication)

        const application = findApplication(applicationId)

        if (application) {
            cancelApplicationForm.value = {
                application_id: application.id,
                carer_user_id: application.carerUserId
            }
        }
    }

    const confirmCancelApplication = async () => {
        if (!cancelApplicationForm.value?.application_id) {
            addToastNotification({
                message: 'Failed to confirm cancel application due to invalid id. Please contact tech team.',
                type: 'danger'
            })
            return
        }
        try {
            const data: DestroySessionCarerApplicationFormData = {
                status: cancelApplicationForm.value?.status,
                cancel_reason: cancelApplicationForm.value?.cancel_reason,
                penalise_carer: cancelApplicationForm.value?.penalise_carer === 'true',
                add_replacement_bonus: cancelApplicationForm.value?.add_replacement_bonus
            }

            await SessionCarerApplicationService.destroy(cancelApplicationForm.value?.application_id, data)

            addToastNotification({
                message: `Successfully cancelled carer application.`,
                type: 'success'
            })

            await fetchCarerApplications()
            setSessionAssignedCarer(null)
            backToMainScreen()

        } catch (err) {
            const error = err as AxiosError
            
            if (error.response?.status === 422) {
                const responseData = error.response.data as { message: string }

                addToastNotification({
                    message: responseData.message,
                    type: 'danger'
                })
            } else {
                addToastNotification({
                    message: 'Failed to confirm cancel session details. Please try again',
                    type: 'danger'
                })
            }
        }
    }

    /*
    *   assigns Carer to Session.
    *
    **/
    const assignCarer = async (applicationId: number) => {

        const application = findApplication(applicationId)

        if (!currentSession.value || !application) {
            return
        }

        try {
            await SessionAssignedCarerService.store(currentSession.value?.id, {carerUserId: application.carerUserId})
            addToastNotification({
                message: `Carer ${ application.carer?.mainProfile?.fullName } has been assigned to session ${ currentSession.value?.no }`,
                type: 'success'
            })

            // Set session's assigned carer
            setSessionAssignedCarer(application.carer)

            await fetchCarerApplications()

        } catch (err) {

            const error = err as AxiosError

            const defaultMessage = `Error while unassigning Carer ${ application.carer?.mainProfile?.fullName } from session  ${ currentSession.value?.no }. Please try again.`

            if (error.response?.status === 422) {
                const responseData = error.response.data as { message?: string }
                addToastNotification({
                    message: responseData.message ?? defaultMessage,
                    type: 'danger'
                })
            } else {
                addToastNotification({
                    message: defaultMessage,
                    type: 'danger'
                })
            }
        }
    }

    const unassignCarer = async (applicationId: number) => {

        const application = findApplication(applicationId)

        if (!currentSession.value || !application) {
            return
        }

        try {
            await SessionAssignedCarerService.destroy(currentSession.value?.id)
            addToastNotification({
                message: `Carer ${ application.carer?.mainProfile?.fullName } has been unassigned from session ${ currentSession.value?.no }`,
                type: 'success'
            })

            // Set session's assigned carer's id as undefined
            setSessionAssignedCarer(null)

            await fetchCarerApplications()

        } catch (err) {
            addToastNotification({
                message: `Error while unassigning Carer ${ application.carer?.mainProfile?.fullName } from session  ${ currentSession.value?.no }. Please try again.`,
                type: 'danger'
            })
        }
    }

    const setSessionAssignedCarer = (carer: User | null = null) => {
        if (!currentSession.value) {
            return
        }
        currentSession.value.carer = carer ?? null
        currentSession.value.carerUserId = carer?.id ?? null
    }

    /*
    * Update Additional Fee
    *
    **/

    const isSavingAdditionalFee = ref<boolean>(false)

    const isDestroyingAdditionalFee = ref<boolean>(false)

    const additionalFeeForm = ref<{
        application_id: number
        status: number
        additional_fee: number
        negotiate_additional_fee?: number
        negotiation_status?: string
        additional_fee_reason?: string
    }>({ 
        status: 0,
        application_id: 0,
        additional_fee: 0
    })

    const editAdditionalFee = (applicationId: number) => {
        setCurrentComponent(AdditionalFeeCarerApplication)

        const application = findApplication(applicationId)

        if (!currentSession.value || !application) {
            return
        }

        additionalFeeForm.value.application_id = applicationId
        additionalFeeForm.value.status = application.status.id
        
        if(additionalFeeForm.value.status == SessionCarerApplicationStatusEnum.ID_NEGOTIATED && application.negotiateAdditionalFee == null) {
            additionalFeeForm.value.negotiate_additional_fee = 0
        }

        if(application.additionalFee != null)
            additionalFeeForm.value.additional_fee = application.additionalFee / 100
        if(application.negotiateAdditionalFee != null)
            additionalFeeForm.value.negotiate_additional_fee = application.negotiateAdditionalFee / 100
        if(application.additionalFeeReason != null)
            additionalFeeForm.value.additional_fee_reason = application.additionalFeeReason
        if(application.negotiationStatus != null)
            additionalFeeForm.value.negotiation_status = application.negotiationStatus
    }

    const updateAdditionalFee = async () => {
        isSavingAdditionalFee.value = true
        try {
            const formData: UpdateAdditionalFeeFormData = {
                status: additionalFeeForm.value?.status,
            }

            if(additionalFeeForm.value?.additional_fee != null && additionalFeeForm.value?.additional_fee != 0)
                formData.additional_fee = additionalFeeForm.value?.additional_fee * 100

            if(additionalFeeForm.value?.negotiate_additional_fee != null && additionalFeeForm.value?.negotiate_additional_fee != 0)
                formData.negotiate_additional_fee = additionalFeeForm.value?.negotiate_additional_fee * 100

            if(additionalFeeForm.value?.additional_fee_reason != null && additionalFeeForm.value?.additional_fee_reason != '')
                formData.additional_fee_reason = additionalFeeForm.value?.additional_fee_reason
            
            if(additionalFeeForm.value?.negotiation_status != null && additionalFeeForm.value?.negotiation_status != '')
                formData.negotiation_status = additionalFeeForm.value?.negotiation_status

            const {data} = await CarerApplicationAdditionalFeeService.patch(additionalFeeForm.value.application_id, formData)
            
            setCurrentComponent(SessionCarerApplicationList)
            await fetchCarerApplications()
        } catch (e) {
            if (e instanceof AxiosError) {
                if (e.response && e.response.status === 422) {
                    addToastNotification({
                        message: e.response.data.message,
                        type: 'danger'
                    })
                }
            } else {
                addToastNotification({
                    message: 'Failed to update travel incentive. Please try again',
                    type: 'danger'
                })
            }
        }
        isSavingAdditionalFee.value = false
    }

    const destroyAdditionalFee = async () => {
        isDestroyingAdditionalFee.value = true
        try {
            await CarerApplicationAdditionalFeeService.delete(additionalFeeForm.value.application_id)
            
            await fetchCarerApplications()

            additionalFeeForm.value.additional_fee = 0
            additionalFeeForm.value.negotiate_additional_fee = 0
            additionalFeeForm.value.additional_fee_reason = ''
            additionalFeeForm.value.negotiation_status = ''

            addToastNotification({
                message: 'Successful remove travel incentive',
                type: 'success'
            })

            setCurrentComponent(SessionCarerApplicationList)
        } catch (e) {
            addToastNotification({
                message: 'Failed to remove travel incentive. Please try again',
                type: 'danger'
            })
        }
        isDestroyingAdditionalFee.value = false
    }

    const checkCarerIsAssignedToSession = (carerId: number) => currentSession.value?.carerUserId === carerId

    const init = async (session: Session) => {
        currentSession.value = session
        preferredCarers.value = session.preferredCarers
        await fetchCarerApplications()
        setAsInitialised()
    }

    const initListeners = () => {
        window.Echo?.private('backoffice')
            .listen('.SessionCarerApplicationUpdated', ({model}: any) => {
                const index = carerApplications.value?.findIndex((application) => application.id === model.id)
                if (index !== -1) {
                    carerApplications.value[index] = model
                }
            })
    }

    const reset = () => {
        setAsInitialised(false)
        currentSession.value = null
        carerApplications.value = []
        createApplicationForm.value = {
            carer: null,
            additional_fee: 0
        }
        cancelApplicationForm.value = {
            application_id: 0,
            carer_user_id: 0
        }
        setCurrentComponent(SessionCarerApplicationList)
    }

    const strikeThrough = (strike: boolean) => {
        return {
            'text-decoration-line-through': strike
        }
    }

    return {
        isInitialised,
        isFetchingCarerApplications,
        isSavingCarerApplication,
        currentComponent,
        currentSession,
        isPaidSession,
        canPenaliseCarer,
        replacementBonus,
        preferredCarers,
        carerApplications,
        activeCarerApplications,
        cancelledCarerApplications,
        currentScreenTitle,
        isMainScreen,
        canBackToMainScreen,
        createApplicationForm,
        confirmCreateApplication,
        updateApplicationDetail,
        cancelApplicationForm,
        confirmCancelApplication,
        createApplication,
        checkCarerIsAssignedToSession,
        cancelApplication,
        assignCarer,
        unassignCarer,
        additionalFeeForm,
        isSavingAdditionalFee,
        isDestroyingAdditionalFee,
        editAdditionalFee,
        updateAdditionalFee,
        destroyAdditionalFee,
        reset,
        init,
        fetchCarerApplications,
        deleteApplication,
        backToMainScreen,
        initListeners,
        strikeThrough
    }
})