<template>
    <div class="modal-body bg-white">
        <!--  Form: Update Session -->
        <form>
            <div class="row">
                <div class="col-6 mb-3">
                    <form-label label="Request from Carer"/>
                    <input type="number" class="form-control bg-white" v-model="additionalFeeForm.additional_fee">
                </div>
                <div class="col-6 mb-3">
                    <form-label label="Negotiate From Customer"/>
                    <input type="number" class="form-control bg-white" v-model="additionalFeeForm.negotiate_additional_fee">
                </div>
            </div>
            <div class="row">
                <div class="col-6 mb-3">
                    <form-label label="Reasons"/>
                    <input type="text" class="form-control bg-white" v-model="additionalFeeForm.additional_fee_reason">
                </div>
                <div class="col-6 mb-3">
                    <form-label label="Negotiation Action From Carer"/>
                    <select class="form-control bg-white" v-model="additionalFeeForm.negotiation_status">
                        <option :value="NegotiationStatusEnum.ACCEPTED">Accepted</option>
                        <option :value="NegotiationStatusEnum.REJECTED">Rejected</option>
                    </select>
                    <small class="text-end text-muted fw-normal">Leave empty of carer not yet decided</small>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer row">
        <div class="col">
            <button v-if="additionalFeeForm.additional_fee != null" class="btn btn-danger" @click.prevent="destroyAdditionalFee" :disabled="isDestroyingAdditionalFee">
                <span v-if="isDestroyingAdditionalFee">Removing...</span>
                <span v-else>Remove</span>
            </button>
        </div>
        <div class="col text-end">
            <button class="btn btn-primary" @click.prevent="updateAdditionalFee" :disabled="isSavingAdditionalFee">
                <span v-if="isSavingAdditionalFee">Saving...</span>
                <span v-else>Save</span>
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import FormLabel from '@/components/form/FormLabel.vue'
import HelperText from '@/components/form/HelperText.vue'
import { NegotiationStatusEnum } from '@/enums/NegotiationStatusEnum'
import { SessionCarerApplicationStatusEnum } from '@/enums/SessionCarerApplicationStatusEnum'
import {useSessionCarerApplicationStore} from '@/stores/sessionCarerApplication'
import {storeToRefs} from 'pinia'

const store = useSessionCarerApplicationStore()
const {
    isSavingAdditionalFee,
    isDestroyingAdditionalFee,
    additionalFeeForm,
} = storeToRefs(store)

const {
    updateAdditionalFee,
    destroyAdditionalFee
} = store
</script>