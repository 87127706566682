<template>
    <button class="btn  btn-primary " @click="openModal()">
        Status: {{ selectedState }}
    </button>
    <Teleport to="body">
        <kc-modal :modal-id="modalId" modal-size="xl" title="Update Carer's Status">
            <vue-select :options="sortedCarerStates" v-model="selectedState"></vue-select>

            <template v-if="'Approved' === selectedState">
                <div class="mt-3">
                    <div class="form-group">
                        <Checkbox v-model="approveForm.initiate_handholding" name="input-initiate-handholding"
                            value="true" label="Initiate Handholding" />
                    </div>
                </div>

            </template>
            <template v-if="'Banned' === selectedState">
                <div class="modal-body bg-white">
                    <form id="formBanCarer">
                        <div class="form-group mb-3 row">
                            <label class="form-label col-form-label">Specify ban reason</label>
                            <div class="col">
                                <textarea class="form-control" name="ban_reason" rows="5" v-model="bannedForm.banReason"
                                    maxlength="250"></textarea>
                            </div>
                            <span class="text-end">{{ bannedForm.banReason.length }} / 250 </span>
                        </div>
                    </form>
                </div>
            </template>

            <template #footer>
                <button :id="`${modalId}-close-button`" class="btn btn-light" type="button"
                    @click="openModal(false)">Close
                </button>
                <button @click.prevent="handleSubmit" class="btn btn-primary">
                    <span v-if="!isSubmitting">Confirm</span>
                    <span v-else>Submitting...</span>
                </button>
            </template>
        </kc-modal>
    </Teleport>
</template>

<script setup lang="ts">
import CarerService from '@/services/users/CarerService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import User from '@/types/User'
import { useModal } from '@/composable/useModal'
import FormLabel from '@/components/form/FormLabel.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import UpdateCarerStateFormData from '@/types/formData/UpdateCarerStateFormData'
import { AxiosError } from 'axios'

const { carerStates } = storeToRefs(useResourcesStore())
const { addToastNotification } = useNotificationsStore()

const sortedCarerStates = computed(() => {
    return [...carerStates.value].sort((a, b) => a.localeCompare(b))
})


const props = defineProps({
    user: { type: Object as () => User, required: true }
})

let {
    modalId,
    openModal,
} = useModal(`update-carer-state-${props?.user?.id}`)

const selectedState = ref(props.user?.carer?.state ?? '')

const isSubmitting = ref(false)
const setIsSubmitting = (value = true) => isSubmitting.value = value

const approveForm = ref({
    initiate_handholding: false
})

const bannedForm  = ref({
    banReason: ''
})

const getFormattedData = () => {

    let data: UpdateCarerStateFormData = {
        state: selectedState.value
    }

    switch (selectedState.value) {
        case 'Approved':
            data.initiate_handholding = approveForm.value?.initiate_handholding ?? false
            break
        case 'Banned':
            data.ban_reason = bannedForm.value.banReason
    }

    return data
}
const handleSubmit = async () => {
    const data = getFormattedData()

    try {
        const response = await CarerService.updateState(props.user?.carer?.id, data)
        selectedState.value = response.data?.data?.state
        addToastNotification({
            message: `Carer state changed to ${selectedState.value}`,
            type: 'success',
        })
        openModal(false)
    } catch (error) {
        if (error instanceof AxiosError) {
            const errorMessage = error.response?.data?.message || 'An unexpected error occurred'
            addToastNotification({
                message: errorMessage,
                type: 'danger'
            })
        } else {
            addToastNotification({
                message: 'An unexpected error occurred',
                type: 'danger'
            })
        }
    }
}

const updateCarerState = async (state: string) => {

}
</script>
