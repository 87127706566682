<template>
    <overlay-spinner :is-showing="isFetchingBooking" v-if="isFetchingBooking && booking" />
    <template v-else>
        <template v-if="mode === 'create'">
            <div class="page-header mb-3" v-if="props.bookingId && booking">
                <p class="text-muted mb-1">Add New Session to Booking</p>
                <h2 class="page-title">{{ booking.no }}</h2>
            </div>
            <div class="page-header mb-3" v-else>
                <h2 class="page-title">Add New Session</h2>
            </div>
        </template>
        <template v-else>
            <div class="page-header mb-3">
                <p class="text-muted mb-1">Update Session</p>
                <h2 class="page-title" v-if="props.job">{{ props.job.no }}</h2>
            </div>
        </template>
        
        <div class="card">
            <form @submit.prevent="saveSession">
                <div class="card-body">
                    <div class="form-group mb-3 row" v-if="!props.bookingId">
                        <label class="form-label col-3 col-form-label">Booking No <span class="text-danger">*</span></label>
                        <div class="col">
                            <vue-select :options="bookingOptions" 
                                @search="onSearchBooking" 
                                v-model="inputUser.booking_id" 
                                :reduce="(booking: any) => booking.id" 
                                label="name" 
                                :class="{ 'is-invalid': hasError('booking') }"
                                @option:change="clearError('booking')"
                                placeholder="Search booking"/>
                            <small class="invalid-feedback" v-if="hasError('booking')">{{ getError('booking') }}</small>
                        </div>
                    </div>
                    
                    <template v-if="booking">
                        <div class="form-group mb-3 row">
                            <label class="form-label col-3 col-form-label">Session Date</label>
                            <div class="col">
                                <input class="form-control" :class="{ 'is-invalid': hasError('date') }" type="date" v-model="inputUser.date" @focus="clearError('date')">
                                <small class="invalid-feedback" v-if="hasError('date')">{{ getError('date') }}</small>
                            </div>
                        </div>
                        <div class="form-group mb-3 row">
                            <label class="form-label col-3 col-form-label">Session Start Time</label>
                            <div class="col">
                                <input class="form-control" :class="{ 'is-invalid': hasError('start_time') }" type="time" v-model="inputUser.start_time" @focus="clearError('start_time')">
                                <small class="invalid-feedback" v-if="hasError('start_time')">{{ getError('start_time') }}</small>
                            </div>
                        </div>
                        <div class="form-group mb-3 row">
                            <label class="form-label col-3 col-form-label">Duration (Hour)</label>
                            <div class="col">
                                <input class="form-control" :class="{ 'is-invalid': hasError('duration') }" type="number" v-model="inputUser.duration" @focus="clearError('duration')">
                                <small class="invalid-feedback" v-if="hasError('duration')">{{ getError('duration') }}</small>
                            </div>
                        </div>
                        <div class="form-group mb-3 row">
                            <div class="col-3 col-form-label">
                                <label class="form-label">Address</label>
                                <a href="#" class="fw-bold" role="button" data-bs-toggle="modal" data-bs-target="#modal-add-address" aria-label="Add New Address">
                                    Add New Address
                                </a>
                            </div>
                            <div class="col">
                                <ul class="list-unstyled border rounded">
                                    <li class="p-3" :class="{ 'border-bottom': index !== booking.user.addresses.length - 1 }"  v-for="(address, index) in booking.user.addresses" :key="address.id">
                                        <label class="form-check mb-0" :for="`address-${address.id}`">
                                            <input class="form-check-input" type="radio" name="address" v-model="inputUser.address" :value="address.id" :id="`address-${address.id}`">
                                            <span class="form-check-label">
                                                <p class="fw-bold mb-1">{{ address.name }}</p>
                                                <p class="text-muted mb-0">{{ address.address_1 }}</p>
                                            </span>
                                        </label>
                                    </li>
                                </ul>
                                <small class="text-danger" v-if="hasError('address')">{{ getError('address') }}</small>
                            </div>
                        </div>
                        <div class="form-group mb-3 row">
                            <div class="col-3 col-form-label">
                                <label class="form-label">Children</label>
                                <a href="#" class="fw-bold" role="button" data-bs-toggle="modal" data-bs-target="#modal-add-child" aria-label="Add New Child">
                                    Add New Child
                                </a>
                            </div>
                            <div class="col">
                                <ul class="list-unstyled border rounded">
                                    <li class="p-3" :class="{ 'border-bottom': index !== booking.user.children.length - 1 }"  v-for="(child, index) in booking.user.children" :key="child.id">
                                        <label class="form-check mb-0" :for="`child-${child.id}`">
                                            <input class="form-check-input" type="checkbox" name="child" v-model="inputUser.children" :value="child.id" :id="`child-${child.id}`">
                                            <span class="form-check-label">
                                                <p class="fw-bold mb-1">{{ child.name }}</p>
                                                <span class="text-muted mb-0">{{ child.gender === 'G' ? 'Girl' : 'Boy' }}</span>, 
                                                <span class="text-muted mb-0">{{ date.toAge(child.birth_date) }}</span>
                                            </span>
                                        </label>
                                    </li>
                                </ul>
                                <small class="text-danger" v-if="hasError('children')">{{ getError('children') }}</small>
                            </div>
                        </div>
                        <div class="form-group mb-3 row">
                            <div class="col-3 col-form-label">
                                <label class="form-label">Remark</label>
                            </div>
                            <div class="col">
                                <textarea v-model="inputUser.remark" 
                                    class="form-control" 
                                    :class="{ 'is-invalid': hasError('remark') }" 
                                    name="remark" rows="5" 
                                    @focus="clearError('remark')">
                                </textarea>
                                <small class="invalid-feedback" v-if="hasError('remark')">{{ getError('remark') }}</small>
                            </div>
                        </div>
                    </template>
                </div>
            
                <div class="card-footer">
                    <div class="d-flex space-x-2">
                        <button class="btn btn-light" @click="router.go(-1)">Cancel</button>
                        <button class="btn btn-primary" type="submit">Save Session</button>
                    </div>
                </div>
            </form>

            <template v-if="inputUser.booking_id && booking">
                <address-form :user-id="booking.user.id" :component-slug="'job-create'" @updated-or-created="fetchBooking"></address-form>
                <child-form :user-id="booking.user.id" @updated-or-created="fetchBooking"></child-form>
            </template>
        </div>
    </template>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { ref, watch, onMounted } from 'vue'
import _ from 'lodash/fp'
import router from '@/router'
import date from '@/utils/date'
import { useNotificationsStore } from '@/stores/notifications'
import BookingService from '@/services/BookingService'
import UserInput from '@/types/Input'
import useFormError from '@/composable/useFormError'
import JobService from '@/services/JobService'
import AddressForm from '@/pages/address/AddressForm.vue'
import ChildForm from '@/pages/child/ChildForm.vue'
import { rules as JobRule, $externalResults } from '@/rules/JobRule'
import AddressV1 from '@/types/AddressV1'
import Child from '@/types/Child'

const { addToastNotification } = useNotificationsStore()
const booking = ref<any>()
const bookingOptions = ref<Array<any>>()
const isFetchingBooking = ref<boolean>()
const inputUser = ref<UserInput>({
    booking_id: null,
    date: null,
    start_time: null,
    duration: null,
    address: null,
    children: <Array<Number>>[],
    remark: null,
})
const props = defineProps({
    bookingId: { type: Number, default: null },
    job: { type: Object },
    redirectTo: { type: Object, default: { name: 'job-index' } }
})
const mode = props.job ? 'update' : 'create'
const v$ = useVuelidate(JobRule, { inputUser }, { $stopPropagation: true, $externalResults })
const { hasError, getError, clearError, clearAllErrors } = useFormError(v$)

onMounted(() => {
    if (props.bookingId) {
        inputUser.value.booking_id = props.bookingId
    }

    if (props.job !== undefined) {
        let jobObj = props.job
        inputUser.value = {
            booking_id: props.bookingId,
            date: date.utc(jobObj.started_at, 'YYYY-MM-DD'),
            start_time: date.utc(jobObj.started_at, 'hh:mm'),
            duration: jobObj.total_hours,
            remark: jobObj.remark,
            address: jobObj.address.id,
            children: jobObj.children.map((child: Child) => child.id)
        }
    }
})

watch(
    () => inputUser.value.booking_id,
    () => {
        fetchBooking('disable-reload')
    }
)

function fetchBooking (reloadComponentSlug: string = '') {
    isFetchingBooking.value = (reloadComponentSlug === '') // this will partially reload the async component

    BookingService.show(inputUser.value.booking_id)
        .then(({ data: data }) => {
            booking.value = data
        })
        .catch(() => {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger',
            })
        })
        .finally(() => {
            isFetchingBooking.value = false
        })
}

async function saveSession () {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (validated) {
        let formattedData = formatData(inputUser.value)

        const jobService =
            mode === 'create'
                ? JobService.store(formattedData)
                : JobService.update(props.job?.id, formattedData)

        jobService.then(() => {
            addToastNotification({
                message: `Session ${mode}d`,
                type: 'success',
            })
        })
            .catch(({ response: { data, status } }) => {
                if (status === 422) {
                    Object.assign($externalResults.value.inputUser, data.errors)
                } else {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                }
            })
    }
}

const onSearchBooking = (search: string, loading: Function) => {
    if (search.length) {
        searchBookings(search, loading)
    }
}

const searchBookings = _.debounce(350, (search, loading) => {
    loading(true)

    BookingService.index({ search })
        .then(({ data: data }) => {
            bookingOptions.value = data.map((booking: any) => {
                return {
                    id: booking.id,
                    name: booking.no,
                    data: booking,
                }
            })
        })
        .catch(() => { })
        .finally(() => {
            loading(false)
        })
})

function formatData (obj: UserInput) {
    let start_time: string = date.toLocale(obj.date + obj.start_time)
    let end_time: string = date
        .object(start_time)
        .add(obj.duration, 'hour')
        .format('DD-MMM-YYYY h:mm:ss A')

    let addressObj: AddressV1 = booking.value.user.addresses.find((address: AddressV1) => address.id === obj.address)
    let childrenArr: Array<Child> = booking.value.user.children.filter((child: Child) => obj.children.includes(child.id))

    return {
        date: obj.date,
        assigned_carer: null,
        booking_id: booking.value.id,
        start_time: start_time,
        end_time: end_time,
        duration: obj.duration,
        state_id: addressObj.state_id,
        address: addressObj,
        bonus: 0,
        children: childrenArr,
        remark: obj.remark,
    }
}
</script>