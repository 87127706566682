<template>
    <div>
        <div @click="openModal">
            <template v-if="session?.carerApplicationsCount">
                <button class="btn btn-sm ">
                    <span class="text-success border-end fw-bold" style="padding-right:0.5rem">
                        {{ activeApplicationsCount }} active
                    </span>
                    <span class="text-danger fw-bold" style="padding-left:0.5rem">
                        {{ inactiveApplicationsCount }} inactive
                    </span>
                </button>
            </template>
            <template v-else>
                <button class="btn btn-sm ">
                    0 applications
                </button>
            </template>
        </div>
        <Teleport to="body">
            <kc-modal :modal-id="modalId" modal-size="xl">
                <div class="modal-header">
                    <div class="d-flex flex-row ">
                        <button v-if="canBackToMainScreen" @click.prevent="backToMainScreen"
                            class="d-inline-flex align-items-center btn btn-sm text-secondary ">
                            <icon name="chevron-left" class="text-secondary" />
                            <span>Back</span>
                        </button>
                        <div class="col align-items-center" :class="canBackToMainScreen ? 'mx-3' : 'mx-0'">
                            <h3 class=" align-middle"> {{ currentScreenTitle }} </h3>
                        </div>
                    </div>
                    <button class="btn-close" @click="openModal(false)"></button>
                </div>
                <div class="modal-body bg-white">
                    <template v-if="isInitialised">
                        <component :is="currentComponent"></component>
                        <div v-if="isMainScreen" class="d-flex flex-row justify-content-center">
                            <button class="btn btn-primary" @click.prevent="createApplication">
                                <icon name="plus" />
                                Add New Application
                            </button>
                        </div>
                    </template>
                    <template v-else>
                        <OverlaySpinner :is-showing="true" />
                    </template>
                </div>
            </kc-modal>
        </Teleport>
    </div>
</template>

<script setup lang="ts">

import { useNotificationsStore } from '@/stores/notifications'
import Session from '@/types/Session'
import { storeToRefs } from 'pinia'
import { useSessionCarerApplicationStore } from '@/stores/sessionCarerApplication'
import Icon from '@/components/Icon.vue'
import { useModal } from '@/composable/useModal'
import OverlaySpinner from '@/components/OverlaySpinner.vue'
import { computed } from 'vue'

const { addToastNotification } = useNotificationsStore()

const store = useSessionCarerApplicationStore()
const {
    isInitialised,
    carerApplications,
    currentComponent,
    currentScreenTitle,
    isMainScreen,
    canBackToMainScreen,
    isFetchingCarerApplications
} = storeToRefs(store)

const {
    init,
    fetchCarerApplications,
    deleteApplication,
    reset,
    backToMainScreen,
    createApplication,
    initListeners
} = store

const props = defineProps({
    session: { type: Object as () => Session, required: true }
})

let {
    modalId,
    openModal,
    onModalShown,
    onModalHidden
} = useModal(`modal-session-${props.session?.id}-carer-applications`)

onModalShown(() => {
    if (props.session) {
        init(props.session)
    }
    initListeners()
})
onModalHidden(() => reset())

const totalApplicationsCount = computed(() => props.session?.carerApplicationsCount)

const activeApplicationsCount = computed(() => props.session?.acceptedAndActiveCarerApplicationsCount)

const inactiveApplicationsCount = computed(() => {
    if (!totalApplicationsCount.value) {
        return
    }

    return totalApplicationsCount.value - activeApplicationsCount.value
})


const applicationsCount = computed(() => {

    const acceptedAndActiveCount = props.session?.acceptedAndActiveCarerApplicationsCount
    const allCount = props.session?.carerApplicationsCount

    let text

    switch (allCount) {
        case undefined:
            text = 'N/A applications'
            break
        case 0:
            text = '0 applications'
            break
        case 1:
            text = '1 application'
            break
        default:
            text = `${allCount} applications`
    }

    if (allCount > 0) {
        if (acceptedAndActiveCount !== undefined) {
            text = `${acceptedAndActiveCount} / ${text}`
        } else {
            text = `- / ${text}`
        }
    }

    return text
})


</script>
