<template>
    <button @click="openModal" class="btn btn-sm btn-icon btn-danger">
        <icon name="trash" />
    </button>
    <Teleport to="body">
        <kc-modal :modal-id="modalId" modal-size="md">
            <div class="modal-header">
                <h4 class="modal-title">
                    Remove admin from Channel {{ channelName }} ?
                </h4>
                <button type="button" class="btn-close" aria-label="Close" @click="openModal(false)"></button>
            </div>
            <div class="modal-body bg-white">
                <p>Are you sure you want to remove <span class="fw-bold">{{ userName }}</span> ?</p>
            </div>
            <div class="modal-footer pt-2 border-top">
                <button :id="`${modalId}-close-button`" class="btn btn-light" type="button"
                    @click="openModal(false)">Close
                </button>
                <button @click="handleSubmit" class="btn btn-primary">
                    <span v-if="!isSubmitting">Submit</span>
                    <span v-else>Submitting...</span>
                </button>
            </div>
        </kc-modal>
    </Teleport>
</template>

<script setup lang="ts">


import { useModal } from '@/composable/useModal'
import { ref } from 'vue'
import SendbirdService from '@/services/SendbirdService'
import { useNotificationsStore } from '@/stores/notifications'
import { AxiosError } from 'axios'

const { addToastNotification } = useNotificationsStore()

const props = defineProps({
    userId: { type: Number, required: true },
    channelUrl: { type: String, required: true },
    userName: { type: String },
    channelName: { type: String }
})

let {
    modalId,
    openModal,
    onModalShown,
    onModalHidden
} = useModal(`remove-user-${props.userId}-chat-channel-modal`)

const isSubmitting = ref(false)

const handleSubmit = async () => {
    if (!props.userId || !props.channelUrl) {
        addToastNotification({
            type: 'danger',
            message: `Failed to submit request to remove user ${props.userId} ${props.userName}} from channel ${props.channelName}`
        })
        return
    }

    try {
        await SendbirdService.removeUser({
            user_id: props.userId,
            channel_url: props.channelUrl,
            should_remove_operator_status: true
        })
        addToastNotification({
            message: 'Successfully removed admin',
            type: 'success'
        })
        openModal(false)
    } catch (error: any) {
        addToastNotification({
            message: 'Failed to remove admin from channel. ' + error.response?.data.message,
            type: 'danger'
        })
    }
}

</script>