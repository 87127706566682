<template>

    <button class="btn btn-icon btn-green "
            @click="openModal()"
            :disabled="!session"
    >
        <icon name="brand-whatsapp"/>
    </button>

    <Teleport to="body">
        <kc-modal :modal-id="modalId" modal-size="lg">

            <div class="modal-header">
                <h4 class="modal-title">
                    WhatsApp Template Session - {{ session.no }}
                </h4>
                <button type="button" class="btn-close" aria-label="Close"
                        @click="openModal(false)"
                ></button>
            </div>

            <div class="modal-body bg-white">

<pre ref="sessionWhatsappTemplate" style="white-space: pre-wrap;">

Good morning afternoon evening Mr/Mrs {{ session.customer.mainProfile?.fullName }}. We are from Kiddocare. We have received your booking as shown below:

User ID: **{{ session.customer.mainProfile?.onlineId }}**
Session no: **{{ session.no ?? 'N/A' }}**
Date: **{{ session.startedAtDate }} ({{ session.startedAtDay }})**
Time: {{ session.startedAtTime }} - {{ session.endedAtTime }}
Duration: **{{ session.totalHours }} hours**
Address: {{ getFormattedAddress(session.address, addressStates) }}
Assigned Carer : **{{ session.carer?.mainProfile?.fullName ?? 'Finding Carer' }}**

**No of dependents - {{ session.dependents.length }}**
<span v-for="(dependent, index) in session.dependents" :key="index">
Child {{ index + 1 }}: {{ dependent['name'] }} ({{ dependent['gender'] == GenderEnum.ID_MALE ? 'Male' : 'Female' }})
Age: {{ dependent['age'] }}
Medical Conditions : {{ dependent['medical'] ?? 'N/A' }}
Allergies : {{ dependent['allergies'] ?? 'N/A' }}
Language: {{ formatLanguages(dependent['languages']) }}
Religion: {{ dependent['religion']?.label ?? 'N/A' }}
</span>
Adult presence: {{ session.metadata?.adult_details ?? '-' }}
Pets : {{ session.metadata?.pet_details ?? '-' }}
Special Requirements : {{ session.metadata?.other_details ?? '-' }}

Please review and confirm details above
</pre>
            </div>

            <div class="modal-footer pt-2 border-top">
                <button :id="`${modalId}-close-button`"
                        class="btn btn-light" type="button"
                        @click="openModal(false)"
                >Close
                </button>
                <CopyButton :data="sessionWhatsappTemplate?.innerText ?? ''"
                            resource-name="Whatsapp Template"
                            label="Copy"
                />
            </div>


        </kc-modal>
    </teleport>
</template>

<script setup lang="ts">
import CopyButton from '@/components/CopyButton.vue'
import { getFormattedAddress } from '@/composable/useAddress'
import { toAge } from '@/composable/useDate'
import { useModal } from '@/composable/useModal'
import { GenderEnum } from '@/enums/GenderEnum'
import { useResourcesStore } from '@/stores/resources'
import Language from '@/types/Language'
import Session from '@/types/Session'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

const { addressStates } = storeToRefs(useResourcesStore())

const props = defineProps({
    session: {type: Object as () => Session, required: true}
})

let {
    modalId,
    openModal,
    onModalShown,
    onModalHidden
} = useModal(`session-whatsapp-template-${ props.session?.no }-modal`)

const sessionWhatsappTemplate = ref<HTMLElement | null>(null)

function formatLanguages(languages: Array<Language>) {
    let format: Array<String> = []

    languages?.forEach(function (language: Language) {
        format.push(language.name)
    })

    return format.join(', ')
}

</script>
