<template>
    <form @submit.prevent="saveAddress">
        <div class="pb-3">
            <FormLabel label="Name" required />
            <input class="form-control" :class="{ 'is-invalid': hasError('name') }" type="text" v-model="form.name"
                @focus="clearError('name')">
            <ErrorMessage :has-error="hasError('name')" :message="getError('name')" />
        </div>

        <div class="pb-3 d-flex row">
            <div class="col-sm-12 col-xl-4">
                <FormLabel label="Unit No." required />
                <input class="form-control" :class="{ 'is-invalid': hasError('unit_no') }" type="text"
                    v-model="form.unit_no" @focus="clearError('unit_no')" />
                <ErrorMessage :has-error="hasError('unit_no')" :message="getError('unit_no')" />
            </div>
            <div class="col-sm-12 col-xl-4">
                <FormLabel label="Floor/Level" />
                <input class="form-control" :class="{ 'is-invalid': hasError('floor_level') }" type="text"
                    v-model="form.floor_level" @focus="clearError('floor_level')" />
                <ErrorMessage :has-error="hasError('floor_level')" :message="getError('floor_level')" />
            </div>
            <div class="col-sm-12 col-xl-4">
                <FormLabel label="Block Building" />
                <input class="form-control" :class="{ 'is-invalid': hasError('block_building') }" type="text"
                    v-model="form.block_building" @focus="clearError('block_building')" />
                <ErrorMessage :has-error="hasError('block_building')" :message="getError('block_building')" />
            </div>
        </div>

        <div class="pb-3">
            <FormLabel label="Address Line 1" required />
            <input class="form-control" :class="{ 'is-invalid': hasError('address_1') }" type="text"
                v-model="form.address_1" @focus="clearError('address_1')" />
            <ErrorMessage :has-error="hasError('address_1')" :message="getError('address_1')" />
        </div>

        <div class="pb-3">
            <FormLabel label="Address Line 2" />
            <input class="form-control" :class="{ 'is-invalid': hasError('address_2') }" type="text"
                v-model="form.address_2" @focus="clearError('address_2')" />
            <ErrorMessage :has-error="hasError('address_2')" :message="getError('address_2')" />
        </div>

        <div class="pb-3">
            <FormLabel label="Postcode" required />
            <input class="form-control" :class="{ 'is-invalid': hasError('postcode') }" type="text"
                v-model="form.postcode" @input="findPostcode(($event.target as HTMLInputElement).value)"
                @focus="clearError('postcode')" maxlength="5" />
            <ErrorMessage :has-error="hasError('postcode')" :message="getError('postcode')" />
        </div>

        <div class="pb-3">
            <FormLabel label="City" />
            <input class="form-control" v-model="postcode.city" type="text" disabled />
        </div>

        <div class="pb-3">
            <FormLabel label="State" />
            <input class="form-control" :value="getState(postcode.stateId)" type="text" disabled />
        </div>

        <div class="pb-3">
            <FormLabel label="Remarks" />
            <input class="form-control" :class="{ 'is-invalid': hasError('remarks') }" type="text"
                v-model="form.remarks" @focus="clearError('remarks')" />
            <ErrorMessage :has-error="hasError('remarks')" :message="getError('remarks')" />
        </div>

        <div class="pb-3">
            <label class="form-check">
                <input type="checkbox" class="form-check-input" v-model="form.is_primary" />
                <span class="form-check-label">Set As Primary Address?</span>
            </label>
        </div>

        <!-- Action Buttons -->
        <div class="d-flex w-full justify-content-end">
            <button class="btn btn-primary" type="submit" :disabled="isSubmitting">
                <span v-if="isSubmitting">Saving...</span>
                <span v-else>Save Address</span>
            </button>
        </div>
    </form>
</template>

<script setup lang="ts">
import ErrorMessage from '@/components/form/ErrorMessage.vue'
import FormLabel from '@/components/form/FormLabel.vue'
import AddressService from '@/services/AddressService'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import AddressState from '@/types/AddressState'
import AddressV1 from '@/types/AddressV1'
import CreateAddressFormData from '@/types/formData/CreateAddressFormData'
import Postcode from '@/types/Postcode'
import { AxiosError } from 'axios'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'

const props = defineProps({
    userId: { type: Number, required: true }
})

const emit = defineEmits(['success'])

const { postcodes, addressStates } = storeToRefs(useResourcesStore())
const { addToastNotification } = useNotificationsStore()

interface FormErrors {
    [key: string]: string[] | undefined
}

const errors = ref<FormErrors>({})
const isSubmitting = ref(false)

const form = ref<Partial<AddressV1>>({
    name: '',
    unit_no: '',
    floor_level: '',
    block_building: '',
    address_1: '',
    address_2: '',
    postcode: '',
    remarks: '',
    is_primary: false
})

const postcode = ref<Partial<Postcode>>({
    city: '',
})

const hasError = (field: string): boolean => !!errors.value[field]
const getError = (field: string): string => errors.value[field]?.[0] || ''
const clearError = (field: string): void => {
    if (errors.value[field]) {
        delete errors.value[field]
    }
}

const findPostcode = (inputPostcode: string) => {
    if (inputPostcode.length === 5) {
        const foundPostcode = postcodes.value.find((p: Postcode) => p.postcode === inputPostcode)
        if (foundPostcode) {
            postcode.value = foundPostcode
        }
    }
}

const getState = (inputStateId: number | null = null): string => {
    if (!inputStateId) return ''
    const state = addressStates.value.find((state: AddressState) => state.id === inputStateId)
    return state ? state.name : ''
}

const saveAddress = async () => {
    isSubmitting.value = true
    errors.value = {} // Clear previous errors

    const data: CreateAddressFormData = {
        user_id: props.userId,
        ...form.value
    }

    try {
        await AddressService.store(data)
        addToastNotification({
            message: `Address ${form.value.name} created`,
            type: 'success',
        })
        emit('success')
    } catch (error) {
        if (error instanceof AxiosError) {
            if (error.response?.status === 422) {
                const responseData = error.response.data as { errors?: Record<string, string[]> }
                if (responseData.errors) {
                    errors.value = responseData.errors
                }
            } else {
                const errorMessage = error.response?.data?.message || 'An unexpected error occurred'
                addToastNotification({
                    message: errorMessage,
                    type: 'danger'
                })
            }
        } else {
            addToastNotification({
                message: 'An unexpected error occurred',
                type: 'danger'
            })
        }
    } finally {
        isSubmitting.value = false
    }
}
</script>

<style scoped>
.text-red {
    color: red;
}
</style>