import httpClientV2 from './HttpClientV2'
import httpClient from './HttpClient'

export default {
    getCsrfCookie() {
        return httpClientV2.get('/auth')
    },

    login(data: object) {
        return httpClientV2.post('/auth', data)
    },

    loginViaGoogle() {
        return httpClientV2.get('/auth/sso/google/redirect')
    },

    validateGoogleToken(data:object) {
        return httpClientV2.post('/auth/sso/google/validate-access-token',data)
    },

    logout() {
        return httpClientV2.delete('/auth')
    },

    ping() {
        return httpClientV2.get('/auth/ping')
    },

    forgotPassword(data: object) {
        return httpClientV2.post('/password/forgot', data)
    },

    resetPassword(data: object) {
        return httpClientV2.post('/password/reset', data)
    },

    resendVerifyEmail(data: {}) {
        return httpClient.post(`email/verify/resend`, data)
    },

    broadcast(data: object) {
        return httpClientV2.post('/auth/broadcasting', data)
    },
}
