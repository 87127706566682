    import dayjs from 'dayjs'
    import relativeTime from 'dayjs/plugin/relativeTime'
    import timezone from 'dayjs/plugin/timezone'
    import utc from 'dayjs/plugin/utc'

    dayjs.extend(relativeTime)
    dayjs.extend(utc)
    dayjs.extend(timezone)

    const DEFAULT_FORMAT = 'YYYY-MM-DD HH:mm:ss'
    const LOCALE_TIMEZONE = 'Asia/Kuala_Lumpur'
    export const DEFAULT_DATE_FORMAT = 'DD MMM YYYY hh:mm:ss A'

    export function dateObject(date?: string|number) {
        return date ? dayjs(date) : dayjs()
    }

    export function formatDate(date: string|number, format: string) {
        return dayjs(date).format(format)
    }

    export function toLocaleDate(date: string|number, format: string) {
        return dayjs.utc(date).tz(LOCALE_TIMEZONE).format(format)
    }

    export function difference(date1: string, date2: string, unit: any) {
        return dayjs(date1).diff(date2, unit)
    }

    export function fromNow(date: string|number, format: string = DEFAULT_FORMAT) {
        return dayjs(date, format).fromNow()
    }

    export function now(format: string = DEFAULT_FORMAT) {
        return dayjs().format(format)
    }

    export function toAge(date: string|number) {
        const month: number = dayjs(dayjs().format('YYYY-MM-DD')).diff(date, 'months')
        return month > 12 ? `${Math.floor(month / 12)} years old` : `${month} months`
    }

    export function convertTo12HourFormat(time: string): string {

        const timeRegex = /^(2[0-3]|[01]?[0-9]):([0-5][0-9]):([0-5][0-9]?)$/;
    
        if (!timeRegex.test(time)) {
            console.error(`Invalid time format: "${time}"`);
            throw new Error('Invalid time format');
        }
        const [hours, minutes] = time.split(':').map(Number);

        const hours12 = hours % 12 || 12; 
        const period = hours < 12 ? 'AM' : 'PM'; 
        
        return `${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
    }
