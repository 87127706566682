<template>
    <icon name="pencil" role="button"
          @click.prevent="openModal"/>
    <Teleport to="body">
        <kc-modal :modal-id="modalId" modal-size="xl" :modal-title="`Edit Session ${session?.no}`" @modal-closed="openModal(false)">
            <ul class="nav nav-tabs card-header-tabs  " data-bs-toggle="tabs">
                <li class="nav-item">
                    <a :href="`#tabs-session-${session.id}-reschedule`" class="nav-link active" data-bs-toggle="tab">
                        Reschedule
                    </a>
                </li>
                <li class="nav-item">
                    <a :href="`#tabs-session-${session.id}-update-address`" class="nav-link" data-bs-toggle="tab">
                        Address
                    </a>
                </li>
                <li class="nav-item">
                    <a :href="`#tabs-session-${session.id}-update-dependents`" class="nav-link" data-bs-toggle="tab">
                        Dependents
                    </a>
                </li>
                <li class="nav-item">
                    <a :href="`#tabs-session-${session.id}-update-additional-details`" class="nav-link" data-bs-toggle="tab">
                        Additional Details
                    </a>
                </li>
            </ul>
            <div class="tab-content py-3 ">
                <div :id="`tabs-session-${session.id}-reschedule`" class="tab-pane active show bg-white">
                    <RescheduleSession :session="session" @success="handleSessionUpdated" />
                </div>
                <div :id="`tabs-session-${session.id}-update-address`" class="tab-pane">
                    <UpdateSessionAddress ref="addressComponent" :session="session"
                        :customer-addresses="customerData.addresses" @success="handleSessionUpdated" />
                </div>
                <div :id="`tabs-session-${session.id}-update-dependents`" class="tab-pane">
                    <UpdateSessionDependents ref="dependentsComponent" :session="session"
                        :customer-dependents="customerData.dependents" @success="handleSessionUpdated" />
                </div>
                <div :id="`tabs-session-${session.id}-update-additional-details`" class="tab-pane">
                    <UpdateSessionAdditionalDetails ref="additionalDetailsComponent" :session="session"
                                                    @success="handleSessionUpdated" />
                </div>
            </div>
        </kc-modal>
    </Teleport>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue'
import UpdateSessionAdditionalDetails from '@/components/Session/UpdateSessionAdditionalDetails.vue'
import Session from '@/types/Session'
import RescheduleSession from '@/components/Session/RescheduleSession.vue'
import { useModal } from '@/composable/useModal'
import UpdateSessionAddress from '@/components/Session/UpdateSessionAddress.vue'
import UpdateSessionDependents from '@/components/Session/UpdateSessionDependents.vue'
import { ref } from 'vue'
import Dependent from '@/types/Dependent'
import Address from '@/types/Address'

const emit = defineEmits(['sessionUpdated', 'updated-or-created'])

const props = defineProps({
    session: { type: Object as () => Session, required: true },
    customerData: {
        type: Object as () => {
            addresses: Address[],
            dependents: Dependent[]
        },
        default: {
            addresses: [],
            dependents: []
        }
    },
    fromOrder: { type: Boolean, default: false }
})

const {
    modalId,
    openModal,
} = useModal(`modal-session-${props.session?.id}-edit`)

const addressComponent = ref(null)
const dependentsComponent = ref(null)

const handleSessionUpdated = () => {
    openModal(false)
    emit('sessionUpdated')
}

</script>
