import { getQueryString } from '@/composable/http'
import httpClientV2 from '@/services/HttpClientV2'

const endpoint = '/backoffice/cohorts'

export default {
    index(query: object = {}) {
        return httpClientV2.get(`${endpoint}?${getQueryString(query)}`)
    },
    show(id:number) {
        return httpClientV2.get(`${endpoint}/${id}`)
    },
    update(id: number, data: object = {}) {
        return httpClientV2.patch(`${endpoint}/${id}`, data)
    },
    getCohortUsers(id: number, query: object = {}) {
        return httpClientV2.get(`${endpoint}/users/${id}?${getQueryString(query)}`)
    }
}