export enum SessionEventTypeEnum {
    ID_STATUS_LOCATION_START = 1,
    ID_STATUS_LOCATION_ARRIVED = 2,
    ID_STATUS_PENDING_CHECKLIST_APPROVAL = 3,
    ID_STATUS_CHECKLIST_DECLINED = 4,
    ID_STATUS_CHECKLIST_APPROVED = 5,
    ID_STATUS_PENDING_REPORT_APPROVAL = 6,
    ID_STATUS_REPORT_DECLINED = 7,
    ID_STATUS_REPORT_APPROVED = 8,
    ID_STATUS_COMPLETE = 9
}

export type SessionEventTypeIds =
    SessionEventTypeEnum.ID_STATUS_LOCATION_START
    | SessionEventTypeEnum.ID_STATUS_LOCATION_ARRIVED
    | SessionEventTypeEnum.ID_STATUS_PENDING_CHECKLIST_APPROVAL
    | SessionEventTypeEnum.ID_STATUS_CHECKLIST_DECLINED
    | SessionEventTypeEnum.ID_STATUS_CHECKLIST_APPROVED
    | SessionEventTypeEnum.ID_STATUS_PENDING_REPORT_APPROVAL
    | SessionEventTypeEnum.ID_STATUS_REPORT_DECLINED
    | SessionEventTypeEnum.ID_STATUS_REPORT_APPROVED
    | SessionEventTypeEnum.ID_STATUS_COMPLETE