import CarerApplicationStatus from '@/types/CarerApplicationStatus'
import KeyLabel from '@/types/KeyLabel'
import { defineStore } from 'pinia'
import Race from '@/types/Race'
import Bank from '@/types/Bank'
import Language from '@/types/Language'
import Religion from '@/types/Religion'
import Nationality from '@/types/Nationality'
import MaritalStatus from '@/types/MaritalStatus'
import InvoiceItemType from '@/types/InvoiceItemType'
import RateChildren from '@/types/RateChildren'
import RateHour from '@/types/RateHour'
import RateExtra from '@/types/RateExtra'
import AddressState from '@/types/AddressState'
import PaymentMethod from '@/types/PaymentMethod'
import PaymentProvider from '@/types/PaymentProvider'
import JobStatus from '@/types/JobStatus'
import SessionStatus from '@/types/SessionStatus'
import SessionType from '@/types/SessionType'
import SessionServiceType from '@/types/SessionServiceType'
import BookingStatus from '@/types/BookingStatus'
import SessionCarerApplicationStatus from '@/types/SessionCarerApplicationStatus'
import ReviewQuestion from '@/types/ReviewQuestion'
import Postcode from '@/types/Postcode'
import CarerType from '@/types/CarerType'
import Institution from '@/types/Institution'
import Occupation from '@/types/Occupation'
import CarerAssessmentQuestion from '@/types/CarerAssessmentQuestion'
import DependentType from '@/types/DependentType'
import SessionCancelReasonOption from '@/types/SessionCancelReasonOption'
import SessionDependentRoutineType from '@/types/SessionDependentRoutineType'
import SessionEventType from '@/types/SessionEventType'
import CarerOnboardingStatus from '@/types/CarerOnboardingStatus'
import PlatformContentMediaPreset from '@/types/PlatformContentMediaPreset'
import { CheckinData } from '@/types/CheckinData'
import { CheckoutData } from '@/types/CheckoutData'
import { SessionRateAmount } from '@/types/formData/SessionRateAmount'
import { ref } from 'vue'
import SessionEventTimelineItem from '@/types/SessionEventTimelineItem'

export const useResourcesStore = defineStore('resources', () => {
    const races = ref<Race[]>([])
    const banks = ref<Bank[]>([])
    const languages = ref<Language[]>([])
    const religions = ref<Religion[]>([])
    const nationalities = ref<Nationality[]>([])
    const maritalStatuses = ref<MaritalStatus[]>([])
    const occupations = ref<Occupation[]>([])
    const addressStates = ref<AddressState[]>([])
    const carerStates = ref<string[]>([])
    const carerTypes = ref<CarerType[]>([])
    const dependentTypes = ref<DependentType[]>([])
    const carerApplicationType = ref<string[]>([])
    const invoiceItemTypes = ref<InvoiceItemType[]>([])
    const rateHours = ref<RateHour[]>([])
    const rateExtras = ref<RateExtra[]>([])
    const rateChildren = ref<RateChildren[]>([])
    const bookingStatuses = ref<BookingStatus[]>([])
    const paymentMethods = ref<PaymentMethod[]>([])
    const paymentProviders = ref<PaymentProvider[]>([])
    const jobStatuses = ref<JobStatus[]>([])
    const sessionStatuses = ref<SessionStatus[]>([])
    const sessionTypes = ref<SessionType[]>([])
    const sessionServiceTypes = ref<SessionServiceType[]>([])
    const sessionEventsTimeline = ref<SessionEventTimelineItem[]>([])
    const sessionEventTypes = ref<SessionEventType[]>([])
    const sessionDependentRoutineTypes = ref<SessionDependentRoutineType[]>([])
    const sessionCarerApplicationStatuses = ref<SessionCarerApplicationStatus[]>([])
    const sessionRateAmounts = ref<SessionRateAmount[]>([])
    const sessionRateAmountsOrderable = ref<SessionRateAmount[]>([])
    const carerPayoutScheduleTypes = ref<string[]>([])
    const carerApplicationStatuses = ref<CarerApplicationStatus[]>([])
    const carerReviewQuestions = ref<ReviewQuestion[]>([])
    const defaultCheckinData = ref<CheckinData>()
    const defaultCheckoutData = ref<CheckoutData>()
    const postcodes = ref<Postcode[]>([])
    const institutions = ref<Institution[]>([])
    const carerHighestEducation = ref<any[]>([])
    const carerEducationStatuses = ref<any[]>([])
    const carerWorkStatuses = ref<any[]>([])
    const carerEmploymentStatuses = ref<any[]>([])
    const carerBreadwinnerStatuses = ref<any[]>([])
    const carerIncomeCategories = ref<any[]>([])
    const carerBctTrainingMethods = ref<any[]>([])
    const carerBctAssessmentMethods = ref<any[]>([])
    const carerSopTrainingMethods = ref<any[]>([])
    const carerTrainers = ref<any[]>([])
    const announcementStatuses = ref<any[]>([])
    const announcementRecipients = ref<any[]>([])
    const carerAssessmentTypes = ref<any[]>([])
    const carerIntroQuizzes = ref<CarerAssessmentQuestion[]>([])
    const carerPsychometricQuestions = ref<CarerAssessmentQuestion[]>([])
    const carerIntroQuizStatuses = ref<any[]>([])
    const carerIntroQuizPassingScore = ref<number>(0)
    const carerPsychometricTypes = ref<any[]>([])
    const carerOnboardingStatuses = ref<Record<string, CarerOnboardingStatus>>({})
    const platformContentMediaPresets = ref<PlatformContentMediaPreset[]>([])
    const deactivateAccountOptions = ref<Record<string, any>>({})
    const paymentStatuses = ref<KeyLabel[]>([])
    const sessionCancelReasonOptions = ref({
        customer: [] as SessionCancelReasonOption[]
    })
    const platformContentTypes = ref<KeyLabel[]>([])
    const orderStatuses = ref<KeyLabel[]>([])
    const orderTypes = ref<KeyLabel[]>([])
    const carerPayoutTypes = ref<KeyLabel[]>([])
    const carerPayoutStatuses = ref<KeyLabel[]>([])
    const carerPayoutSchedules = ref<KeyLabel[]>([])
    const sessionSettingsDetails = ref<Array<{ label: string, value: any, unit: string | null }>>([])

    // Add new functions to find specific resources by their ID
    function findRace(id: number | string): Race | null {
        const race = races.value.find(item => item.id === id);
        return race ?? null
    }

    function findReligion(id: number | string): Religion | null {
        const religion = religions.value.find(item => item.id === id);
        return religion ?? null
    }

    function findNationality(id: number | string): Nationality | null {
        const nationality = nationalities.value.find(item => item.id === id);
        return nationality ?? null
    }

    function findOccupation(id: number | string): Occupation | null {
        const occupation = occupations.value.find(item => item.id === id);
        return occupation ?? null
    }

    return {
        races,
        banks,
        languages,
        religions,
        nationalities,
        maritalStatuses,
        occupations,
        addressStates,
        carerStates,
        carerTypes,
        dependentTypes,
        carerApplicationType,
        invoiceItemTypes,
        rateHours,
        rateExtras,
        rateChildren,
        bookingStatuses,
        paymentMethods,
        paymentProviders,
        jobStatuses,
        sessionStatuses,
        sessionTypes,
        sessionServiceTypes,
        sessionEventsTimeline,
        sessionEventTypes,
        sessionDependentRoutineTypes,
        sessionCarerApplicationStatuses,
        sessionRateAmounts,
        sessionRateAmountsOrderable,
        carerPayoutScheduleTypes,
        carerApplicationStatuses,
        carerReviewQuestions,
        defaultCheckinData,
        defaultCheckoutData,
        postcodes,
        institutions,
        carerHighestEducation,
        carerEducationStatuses,
        carerWorkStatuses,
        carerEmploymentStatuses,
        carerBreadwinnerStatuses,
        carerIncomeCategories,
        carerBctTrainingMethods,
        carerBctAssessmentMethods,
        carerSopTrainingMethods,
        carerTrainers,
        announcementStatuses,
        announcementRecipients,
        carerAssessmentTypes,
        carerIntroQuizzes,
        carerPsychometricQuestions,
        carerIntroQuizStatuses,
        carerIntroQuizPassingScore,
        carerPsychometricTypes,
        carerOnboardingStatuses,
        platformContentMediaPresets,
        deactivateAccountOptions,
        paymentStatuses,
        sessionCancelReasonOptions,
        platformContentTypes,
        orderStatuses,
        orderTypes,
        carerPayoutTypes,
        carerPayoutStatuses,
        carerPayoutSchedules,
        sessionSettingsDetails,
        findRace,
        findReligion,
        findNationality,
        findOccupation
    }
})
