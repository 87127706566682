<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex">
                <div class="ms-auto">
                    <router-link class="btn btn-primary float-right" :to="{ name: 'admin-create' }">
                        <icon name="plus" />
                        Add New Admin
                    </router-link>
                </div>
            </div>
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Name</label>
                    <input class="form-control" type="text" v-model="inputFilter.name" @keyup.enter="fetchAdmin">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Email</label>
                    <input class="form-control" type="email" v-model="inputFilter.email" @keyup.enter="fetchAdmin">
                </div>

                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetchAdmin"
                        :is-loading="isFetchingUsers"></button-filter>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>Created At</th>
                            <th>Updated At</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="users.length > 0">
                        <tr v-for="user in users" :key="user.id">
                            <td>{{ user.name }}</td>
                            <td>{{ user.email }}</td>
                            <td>
                                <ul>
                                    <li v-for="role in user.roles">
                                        {{ role.name }}
                                    </li>
                                </ul>
                            </td>
                            <td>{{ user.createdAt }}</td>
                            <td>{{ user.updatedAt }}</td>
                            <td class="text-end">
                                <div class="btn-group">
                                    <router-link class="btn btn-icon btn-primary"
                                        :to="{ name: 'admin-edit', params: { id: user.id } }">
                                        <icon name="edit" />
                                    </router-link>
                                    <button class="btn btn-icon btn-danger" @click.prevent="deleteUser(user)">
                                        <icon name="trash" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="6" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isFetchingUsers" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                @per-page-updated="updatePerPage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import AdminService from '@/services/users/AdminService'
import User from '@/types/User'
import UserFilter from '@/types/Filter'

import { useNotificationsStore } from '@/stores/notifications'
import useMetaPage from '@/composable/useMetaPage'

const { addToastNotification } = useNotificationsStore()
const users = ref<Array<User>>([])
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const inputFilter = ref<UserFilter>({
    name: '',
    email: '',
})

const isFetchingUsers = ref<boolean>(false)

fetchAdmin()

// fetch new data when there is function triggered
watch(
    () => metaPageTriggered.value,
    () => {
        fetchAdmin()
    }
)

const resetFilter = () => {
    for (const key in inputFilter.value) {
        if (inputFilter.value.hasOwnProperty(key)) {
            inputFilter.value[key] = ''
        }
    }

    fetchAdmin()
}

function fetchAdmin() {
    isFetchingUsers.value = true
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    AdminService.index(query)
        .then(({ data: { data, meta } }) => {
            users.value = data
            updateMetaPage(meta)
        })
        .finally(() => {
            isFetchingUsers.value = false
        })
}

function deleteUser(user: User) {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete user ${user.name}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            AdminService.destroy(user.id)
                .then(() => {
                    fetchAdmin()
                    addToastNotification({
                        message: `Admin ${user.name} deleted`,
                        type: 'success',
                    })
                })
                .catch(() => {
                    addToastNotification({
                        message:
                            'Internal server error. Please contact tech team.',
                        type: 'danger',
                    })
                })
        }
    })
}
</script>

<style scoped></style>
