import ErrorBag from '@/types/ErrorBag'
import { helpers, numeric, required } from '@vuelidate/validators'
import { ref } from 'vue'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = () => ({
    inputUser: {
        content: {
            required: helpers.withMessage(
                'This content field is required',
                required
            ),
        },
    },
})

export const AdminLogRule = rules()
