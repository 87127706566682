<template>
    <button class="dropdown-item py-1"
            @click="openModal()"
    >
        {{ cancelStatus.adminLabel }}
    </button>
    <Teleport to="body">
        <kc-modal :modal-id="modalId" modal-size="lg">
            <div class="modal-header">
                <h4 class="modal-title">
                    {{ title }}
                </h4>
                <button type="button" class="btn-close" aria-label="Close"
                        @click="openModal(false)"
                ></button>
            </div>
            <div class="modal-body bg-white">
                <form :id="`${modalId}-form`">

                    <!-- Cancel Reason 1 -->
                    <div v-if="cancelReasonOptions.length" class="form-group mb-3 row">
                        <FormLabel :label="`Please select reason ${canceller} is cancelling the session`" is-required/>
                        <div class="col">
                            <div v-for="(reason, index) in cancelReasonOptions"
                                 :key="`cancel-reason-option-${index}`"
                                 class="row"
                            >
                                <RadioInput
                                    v-model="inputs.cancel_reason"
                                    name="cancel_reason"
                                    :value="reason.label"
                                    :label="reason.label"
                                />
                            </div>
                        </div>
                        <small class="text-red" v-if="errors.cancel_reason">{{ errors.cancel_reason }}</small>
                    </div>
                    <div v-else class="form-group mb-3 row">
                        <FormLabel :label="`Please state reason ${canceller} is cancelling the session`" is-required/>
                        <div class="col">
                        <textarea v-model="inputs.cancel_reason"
                                  class="form-control"
                                  rows="5"
                                  maxlength="150"
                        ></textarea>
                            <div class="row justify-content-between mt-1">
                                <div class="col">
                                    <small class="text-red" v-if="errors.cancel_reason">
                                        {{ errors.cancel_reason }}
                                    </small>
                                </div>
                                <div class="col text-end">
                                    <span>{{ inputs.cancel_reason?.length }} / 150</span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Cancel Reason 2 -->
                    <div class="form-group mb-3 row">
                        <FormLabel :label="`Additional reason by ${canceller} (if any)`" is-required/>
                        <div class="col">
                        <textarea v-model="inputs.cancel_reason_2"
                                  class="form-control"
                                  rows="5"
                                  maxlength="200"
                        ></textarea>
                            <div class="d-flex justify-content-end mt-1">
                                <span>{{ inputs.cancel_reason_2?.length }} / 200</span>
                            </div>
                        </div>
                    </div>

                    <!-- Refund Method -->
                    <template v-if="isRefundable" class="">
                        <div class="border-top  d-flex flex-column gap-2 pt-3">
                            <div class="d-flex flex-row justify-content-between">
                                <div>
                                    <h3>Refund Details</h3>
                                    <FormLabel label="This session is refundable."/>
                                </div>
                                <label class="form-check form-switch">
                                    <input v-model="isEditingRefundDetails" class="form-check-input"
                                           type="checkbox">
                                    <span class="form-check-label"></span>
                                </label>
                            </div>
                            <div v-if="isEditingRefundDetails" class="form-group mb-3 row">
                                <FormLabel label="Refund method" is-required/>
                                <div class="col">
                                    <select class="form-select" v-model="inputs.refund_method">
                                        <option v-for="method in RefundMethodEnum"
                                                :value="method"
                                        >
                                            {{ _.startCase(method) }}
                                        </option>
                                    </select>
                                    <ErrorMessage :has-error="!!errors.refund_method"
                                                  :message="errors.refund_method"
                                    />
                                </div>
                            </div>
                        </div>

                        <template v-if="RefundMethodEnum.BANK_TRANSFER === inputs.refund_method">
                            <div class="form-group mb-3 row">
                                <FormLabel label="Bank" is-required/>
                                <div class="col">
                                    <vue-select
                                        :options="banks"
                                        :reduce="(bank: any) => bank.id"
                                        v-model="inputs.refund_bank_id"
                                        label="name"
                                        placeholder="Select Bank"
                                        @option:change="errors.refund_bank_id =''">
                                        <template v-slot:option="option"
                                        >
                                            {{ _.startCase(option.name) }}
                                        </template>
                                    </vue-select>
                                    <ErrorMessage :has-error="!!errors.refund_bank_id"
                                                  :message="errors.refund_bank_id"
                                    />
                                </div>
                            </div>
                            <div class="form-group mb-3 row">
                                <FormLabel label="Account No." is-required/>
                                <div class="col">
                                    <input v-model="inputs.refund_account_no"
                                           class="form-control bg-white" type="text"
                                           required
                                    />
                                    <ErrorMessage :has-error="!!errors.refund_account_no"
                                                  :message="errors.refund_account_no"
                                    />
                                </div>
                            </div>
                        </template>
                        <template v-else-if="RefundMethodEnum.POINT_CONVERSION === inputs.refund_method">
                            <div class="form-group row">
                                <FormLabel label="Details"/>
                            </div>
                            <div class="form-group mb-3 px-2 row">
                                <table class="table w-full">
                                    <thead>
                                        <tr>
                                            <th>Points To Refund</th>
                                            <th class="text-end">Order</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="loyaltyPointRefunds.length">
                                        <tr v-for="refund in loyaltyPointRefunds" :key="refund.order_id">
                                            <td>{{ refund.amount }}</td>
                                            <td class="text-end">
                                                <router-link target="_blank" :to="{ name: 'order-show', params: { orderId: refund.order_id } }">
                                                    {{ refund.order_no }}
                                                </router-link>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="2" class="text-center">No data</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <small class="text-muted fw-normal">NOTE: Please <b>check</b> the loyalty point amount to be refund is correct or you may refund it manually.</small>
                            </div>
                        </template>
                    </template>

                </form>
            </div>
            <div class="modal-footer pt-2 border-top">
                <button :id="`${modalId}-close-button`"
                        class="btn btn-light" type="button"
                        @click="openModal(false)"
                >Close
                </button>
                <button class="btn btn-primary"
                        @click="submit"
                        :disabled="isSubmitting"
                >
                    <span v-if="!isSubmitting">Save</span>
                    <span v-else>Saving...</span>
                </button>
            </div>
        </kc-modal>
    </Teleport>
</template>

<script setup lang="ts">

import {UpdateSessionStatusFormData} from '@/types/formData/UpdateSessionStatusFormData'
import _ from 'lodash'
import {computed, onMounted, ref, watch} from 'vue'
import Modal from 'bootstrap/js/dist/modal'
import FormLabel from '@/components/form/FormLabel.vue'
import RadioInput from '@/components/form/RadioInput.vue'
import {useResourcesStore} from '@/stores/resources'
import {storeToRefs} from 'pinia'
import {SessionStatusEnum} from '@/enums/SessionStatusEnum'
import SessionStatusType from '@/types/SessionStatus'
import {useNotificationsStore} from '@/stores/notifications'
import SessionCancelReasonOption from '@/types/SessionCancelReasonOption'
import SessionService from '@/services/SessionService'
import {AxiosError} from 'axios'
import {RefundMethodEnum} from '@/enums/RefundMethodEnum'
import ErrorMessage from '@/components/form/ErrorMessage.vue'
import LoyaltyPointRefunds from '@/types/LoyaltyPointRefunds'

interface Form {
    cancel_reason: string | null
    cancel_reason_2: string | null
    refund_method: string | null
    refund_bank_id: string | null
    refund_account_no: string | null
}

const {
    sessionCancelReasonOptions,
    banks
} = storeToRefs(useResourcesStore())
const {addToastNotification} = useNotificationsStore()

const props = defineProps({
    sessionId: {type: Number, required: true},
    cancelStatus: {type: Object as () => SessionStatusType, required: true},
    isRefundable: {type: Boolean, default: false}
})

const emits = defineEmits(['sessionCancelled'])

let modalElement: HTMLElement | null = null
let modal: Modal | null = null

const isSubmitting = ref(false)

const isEditingRefundDetails = ref(false)

watch(() => isEditingRefundDetails.value, function (checked) {
    if (checked) {
        inputs.value.refund_method = null
        inputs.value.refund_bank_id = null
        inputs.value.refund_account_no = null
    }
})

const inputs = ref<Form>({
    cancel_reason: null,
    cancel_reason_2: null,
    refund_method: null,
    refund_bank_id: null,
    refund_account_no: null
})

const errors = ref({
    cancel_reason: '',
    refund_method: '',
    refund_bank_id: '',
    refund_account_no: ''
})

const loyaltyPointRefunds = ref<Array<LoyaltyPointRefunds>>([])

watch(() => inputs.value.refund_method, async function (refund_method) {
    if (refund_method ==  RefundMethodEnum.POINT_CONVERSION) {
        const {data} = await SessionService.calculateRefund(props.sessionId)

        loyaltyPointRefunds.value = data.refunds
    }
})

onMounted(() => {
    modalElement = document.getElementById(modalId.value)

    if (!modalElement) {
        return
    }

    modal = new Modal(modalElement)

    modalElement?.addEventListener('hidden.bs.modal', () => {
        resetInputs()
        resetErrors()
    })
})


const modalId = computed<string>(() => {
    return `${ props.cancelStatus.adminLabel }-session-${ props.sessionId }-modal`
})

const title = computed<string>(() => {
    return `Cancel Session for ${ canceller.value }`
})


const cancelReasonOptions = computed<SessionCancelReasonOption[]>(() => {
    let options: SessionCancelReasonOption[] = []
    switch (props.cancelStatus.id) {
        case SessionStatusEnum.ID_CUSTOMER_CANCELLED:
            options = sessionCancelReasonOptions.value.customer
            break
    }
    return options
})

const canceller = computed<string>(() => {
    let canceller: string = 'user'
    switch (props.cancelStatus.id) {
        case SessionStatusEnum.ID_CUSTOMER_CANCELLED:
            canceller = 'customer'
            break
        case SessionStatusEnum.ID_CARER_CANCELLED:
            canceller = 'carer'
            break
        case SessionStatusEnum.ID_ADMIN_CANCELLED:
            canceller = 'admin'
            break
    }
    return canceller
})

const submit = async () => {
    if (isSubmitting.value) {
        return
    }

    resetErrors()

    setIsSubmitting()

    if (!props.sessionId) {
        addToastNotification({
            message: 'Invalid session ID. Please contact tech team.',
            type: 'danger'
        })
        setIsSubmitting(false)
        return
    }

    if (!props.cancelStatus?.id) {
        addToastNotification({
            message: 'Invalid cancel status. Please contact tech team.',
            type: 'danger'
        })
        setIsSubmitting(false)
        return
    }

    if (!inputs.value.cancel_reason) {
        errors.value.cancel_reason = 'Cancel reason is required.'
        setIsSubmitting(false)
        return
    }

    try {
        const data: UpdateSessionStatusFormData = {
            status_id: props.cancelStatus.id,
            cancel_reason: inputs.value.cancel_reason
        }

        if (inputs.value.cancel_reason_2) {
            data.cancel_reason_2 = inputs.value.cancel_reason_2
        }

        if (RefundMethodEnum.BANK_TRANSFER === inputs.value.refund_method) {
            data.refund_method = inputs.value.refund_method

            data.refund_bank_details = {
                bank_id: inputs.value.refund_bank_id ?? '',
                account_no: inputs.value.refund_account_no ?? ''
            }
        } else if (RefundMethodEnum.POINT_CONVERSION === inputs.value.refund_method) {
            data.refund_method = inputs.value.refund_method
        }

        await SessionService.updateStatus(props.sessionId, data)

        await emits('sessionCancelled')
        openModal(false)
        resetInputs()

    } catch (err) {
        const error = err as AxiosError

        if (error.response?.status === 422) {
            const responseData = error.response.data as { errors?: Record<string, string[]> }

            errors.value.cancel_reason = responseData.errors?.cancel_reason?.[0] ?? ''
            errors.value.refund_method = responseData.errors?.refund_method?.[0] ?? ''
            errors.value.refund_bank_id = responseData.errors?.['refund_bank_details.bank_id']?.[0] ?? ''
            errors.value.refund_account_no = responseData.errors?.['refund_bank_details.account_no']?.[0] ?? ''
        } else {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger'
            })
        }
    }

    setIsSubmitting(false)
}

const setIsSubmitting = (value = true) => {
    isSubmitting.value = value
}

const openModal = (open = true) => {
    if (open) {
        modal?.show()
    } else {
        modal?.hide()
    }
}

const resetInputs = () => {
    isEditingRefundDetails.value = false
    inputs.value.cancel_reason = ''
    inputs.value.cancel_reason_2 = ''
    inputs.value.refund_method = ''
    inputs.value.refund_bank_id = ''
    inputs.value.refund_account_no = ''
}

const resetErrors = () => {
    errors.value.cancel_reason = ''
    errors.value.refund_method = ''
    errors.value.refund_bank_id = ''
    errors.value.refund_account_no = ''
}

</script>
