<template>

    <!-- Original Details -->
    <div class="card mt-3 ">
        <div class="card-body">
            <h3 class="fw-bold">Original Details</h3>
            <h4 class="mb-0">Presence of Adult</h4>
            <div class="mb-3">{{ session.metadata?.adult_details ?? '-' }}</div>
            <h4 class="mb-0">Presence of Pets</h4>
            <div class="mb-3">{{ session.metadata?.pet_details ?? '-' }}</div>
            <h4 class="mb-0">Special Requirement</h4>
            <div class="mb-3">{{ session.metadata?.other_details ?? '-' }}</div>
        </div>
    </div>

    <form class="mt-4">
        <div class="pb-3">
            <div class="mt-3">
                <FormLabel label="Presence of Adult" />
                <textarea class="form-control" rows="5" maxlength="1000" @input="form.adult_details = $event.target?.value">{{ form.adult_details }}</textarea>
                <div class="text-end">
                    <span>{{ form.adult_details?.length }} / 1000</span>
                </div>
            </div>
            <div class="mt-3">
                <FormLabel label="Presence of Adult" />
                <textarea class="form-control" rows="5" maxlength="1000" @input="form.pet_details = $event.target?.value">{{ form.pet_details }}</textarea>
                <div class="text-end">
                    <span>{{ form.pet_details?.length }} / 1000</span>
                </div>
            </div>
            <div class="mt-3">
                <FormLabel label="Presence of Adult" />
                <textarea class="form-control" rows="5" maxlength="1000" @input="form.other_details = $event.target?.value">{{ form.other_details }}</textarea>
                <div class="text-end">
                    <span>{{ form.other_details?.length }} / 1000</span>
                </div>
            </div>
        </div>
        <div class="d-flex w-full justify-content-end mt-3">
            <button class="btn btn-primary" @click.prevent="submit" :disabled="isSubmitting">
                <span v-if="isSubmitting">Updating Details...</span>
                <span v-else>Update Details</span>
            </button>
        </div>
    </form>
</template>

<script setup lang="ts">
import FormLabel from '@/components/form/FormLabel.vue'
import SessionService from '@/services/SessionService'
import { useNotificationsStore } from '@/stores/notifications'
import Session from '@/types/Session'
import { AxiosError } from 'axios'
import { ref } from 'vue'

const emits = defineEmits(['success'])

const props = defineProps({
    session: { type: Object as () => Session, required: true }
})

const { addToastNotification } = useNotificationsStore()

const errors = ref<any>({
    adult_details: null,
    pet_details: null,
    other_details: null,
})

const form = ref<any>({
    adult_details: '',
    pet_details: '',
    other_details: '',
})

if (props.session?.metadata !== null) {
    const metadata = props.session?.metadata

    form.value.adult_details = metadata?.adult_details
    form.value.pet_details = metadata?.pet_details
    form.value.other_details = metadata?.other_details
}

const isSubmitting = ref(false)
const setIsSubmitting = (value = true) => isSubmitting.value = value

const submit = async () => {
    if (!props.session) {
        addToastNotification({
            message: 'Failed to submit form. Please contact tech team.',
            type: 'danger'
        })
        return
    }

    const data = {
        adult_details: form.value.adult_details,
        pet_details: form.value.pet_details,
        other_details: form.value.other_details,
    }

    try {
        setIsSubmitting()

        await SessionService.updateAdditionalDetails(props.session.id, data)

        addToastNotification({
            message: 'Additional details updated!',
            type: 'success'
        })

        emits('success')

    } catch (e) {
        const error = e as AxiosError
        if (error.response?.status === 422) {
            const responseData = error.response.data as { errors?: Record<string, string[]> }
            errors.value = responseData.errors
        } else {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger'
            })
        }

    } finally {
        setIsSubmitting(false)
    }
}
</script>