<template>
    <div class="navbar-expand-md">
        <div class="collapse navbar-collapse" id="navbar-menu">
            <div class="navbar navbar-light">
                <div class="container">
                    <ul class="navbar-nav">
                        <template v-for="(menu, index) in menus" :key="index">
                            <li class="nav-item" v-if="!menu.children" :class="isActive(menu.link)">
                                <router-link class="nav-link" :to="menu.link">
                                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                                        <icon :name="menu.icon" />
                                    </span>
                                    <span class="nav-link-title">{{ menu.name }}</span>
                                </router-link>
                            </li>

                            <li class="nav-item dropdown" :class="isSubMenuActive(menu.children)" v-else>
                                <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"
                                    data-bs-auto-close="true" role="button" aria-expanded="false">
                                    <span class="nav-link-icon d-md-none d-lg-inline-block">
                                        <icon :name="menu.icon" />
                                    </span>
                                    <span class="nav-link-title">{{ menu.name }}</span>
                                </a>
                                <div class="dropdown-menu">
                                    <div class="dropdown-menu-columns">
                                        <div class="dropdown-menu-column">
                                            <template v-for="(subMenu, indexSubMenu) in menu.children"
                                                :key="indexSubMenu">
                                                <a class="dropdown-item" :href="subMenu.link"
                                                    v-if="subMenu.normalLink">{{
                                                        subMenu.name
                                                    }}</a>
                                                <router-link class="dropdown-item" :to="subMenu.link" v-else>
                                                    {{ subMenu.name }}
                                                </router-link>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useGeneralStore } from '@/stores/general'

const route = useRoute()
const { telescopeUrl, horizonUrl } = storeToRefs(useGeneralStore())

type Menu = {
    [key: string]: any
    children?: Menu
}

const menus: Menu = ref<Array<Menu>>([
    {
        icon: 'home',
        name: 'Home',
        link: '/'
    },
    // {
    //     icon: 'dashboard',
    //     name: 'Dashboard',
    //     children: [
    //         {
    //             name: 'Bookings',
    //             link: '/dashboard/booking',
    //         },
    //         {
    //             name: 'Jobs',
    //             link: '/dashboard/job',
    //         },
    //         {
    //             name: 'Jobs Overview',
    //             link: '/dashboard/job-overview',
    //         },
    //         {
    //             name: 'Jobs Calendar',
    //             link: '/dashboard/job-calendar',
    //         },
    //         {
    //             name: 'Financials',
    //             link: '/dashboard/financial',
    //         },
    //         {
    //             name: 'Marketing',
    //             link: '/dashboard/marketing',
    //         },
    //     ],
    // },
    {
        icon: 'briefcase',
        name: 'Sessions',
        link: '/sessions'
    },
    {
        icon: 'shopping-cart',
        name: 'Orders',
        link: '/orders'
    },
    {
        icon: 'users',
        name: 'Users',
        children: [
            {
                name: 'Admin',
                link: '/users/admin'
            },
            {
                name: 'Customers',
                link: '/users/customers'
            },
        ]
    },
    {
        icon: 'users',
        name: 'Carers',
        children: [
            {
                name: 'All Carers',
                link: '/carers'
            },
            {
                name: 'Handholdings',
                link: '/carers/handholdings'
            }
        ]
    },
    {
        icon: 'notes',
        name: 'Scripts',
        link: '/scripts'
    },
    {
        icon: 'report',
        name: 'Reports',
        children: [
            {
                name: 'Invoices',
                link: '/reports/invoices'
            },
            {
                name: 'Invoice Weekly',
                link: '/reports/invoice-weekly'
            },
            {
                name: 'Reviews',
                link: '/reports/reviews'
            },
            {
                name: 'Bookings',
                link: '/reports/bookings'
            },
            {
                name: 'Sessions and Status',
                link: '/reports/jobs'
            },
            {
                name: 'Events',
                link: '/reports/events'
            },
            {
                name: 'Payout',
                link: '/reports/payouts'
            },
            {
                name: 'Payout Extra',
                link: '/reports/payouts-extra'
            },
            {
                name: 'Loyalty Points Transaction',
                link: '/loyalty-points'
            }
        ]
    },
    {
        icon: 'report-money',
        name: 'Payout',
        link: '/payouts'
    },
    {
        icon: 'clipboard-check',
        name: 'Tickets',
        link: '/tickets'
    },
    {
        icon: 'message-circle-2',
        name: 'Chats',
        link: '/chats'
    },
    {
        icon: 'speakerphone',
        name: 'Marketing',
        children: [
            {
                name: 'Announcements',
                link: '/marketings/announcements',
            },
            {
                name: 'Media Presets',
                link: '/marketings/content-media-presets'
            },
            {
                name: 'Content Types',
                link: '/marketings/content-types'
            },
            {
                name: 'Customer Dashboard',
                link: '/marketings/customer-dashboard'
            },
            {
                name: 'Cohorts',
                link: '/marketings/notifications/cohorts'
            },
            {
                name: 'Cohort Notifications',
                link: '/marketings/notifications/cohort-notifications'
            },
        ]
    },
    {
        icon: 'settings',
        name: 'System',
        children: [
            {
                name: 'Session Settings',
                link: '/settings/session-settings'
            },
            {
                name: 'Rates',
                link: '/settings/rates/'
            },
            {
                name: 'Holidays',
                link: '/settings/holidays'
            },
            {
                name: 'Promotions',
                link: '/settings/promotions'
            },
            {
                name: 'Vouchers',
                link: '/settings/vouchers'
            },
            {
                name: 'Organization',
                link: '/settings/organizations'
            },
            {
                name: 'Postcodes',
                link: '/settings/postcodes'
            },
            {
                name: 'Review',
                link: '/settings/reviews'
            },
            {
                name: 'Chat Admins',
                link: '/settings/chat-admins'
            },
            {
                name: 'Banks',
                link: '/settings/banks'
            }
        ]
    },
    {
        icon: 'code',
        name: 'Devtools',
        children: [
            {
                name: 'Telescope',
                link: telescopeUrl,
                normalLink: true
            },
            {
                name: 'Horizon',
                link: horizonUrl,
                normalLink: true
            }
        ]
    }
])

function isActive(path: string) {
    if (path === null) return

    return route.path.split('/')['1'] === path.split('/')['1'] ? 'active' : null
}

function isSubMenuActive(children: any) {
    const currentRoutePath = route.path.split('/')['1']
    if (!currentRoutePath) return

    const isActive = children.find((child: any) => {
        return child.link.split('/')['1'] === currentRoutePath
    })

    return isActive ? 'active' : null
}
</script>
