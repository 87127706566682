<template>
    <div class="card">
        <div class="card-body">
         
            <div class="d-flex mb-3  ">
                <div class="ms-auto">
                    <router-link class="btn btn-primary float-right"
                                :to="{ name: 'order-create' }"
                    >
                        <icon name="plus"/>
                        Add New Order
                    </router-link>
                </div>
            </div>
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md-3">
                    <label class="form-label">Transaction Date</label>
                    <div class="input-group">
                        <input type="text" id="datepicker" class="form-control" placeholder="Select date range"
                            readonly="true">
                        <span class="input-group-text">
                            <icon name="calendar"/>
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <label class="form-label">Customer Email</label>
                    <input type="email" class="form-control" v-model="inputFilter.email">
                </div>
                <div class="col-12 col-md-3">
                    <label class="form-label">Customer Name</label>
                    <input type="text" class="form-control" v-model="inputFilter.name">
                </div>
                <div class="col-12 col-md-3">
                    <label class="form-label">Customer ID</label>
                    <input type="text" class="form-control" placeholder="P20216288" v-model="inputFilter.id">
                </div>
                <div class="col-12 col-md-3">
                    <label class="form-label">Order No</label>
                    <input type="text" class="form-control" v-model="inputFilter.order_no">
                </div>
                <div class="col-12 col-md-3 mt-md-3">
                    <label class="form-label">Order Type</label>
                    <vue-select
                        :options="orderTypes" :reduce="(type: KeyLabel) => type.key"
                        class="text-capitalize" label="label" v-model="inputFilter.type"
                        @option:selected="fetchOrders"
                    />
                </div> 
                <div class="col-12 col-md-3 mt-md-3">
                    <label class="form-label">Order Primary/Extra</label>
                    <vue-select
                        :options="[{key:true,label:'Primary'},{key: false,label:'Extra'}]" :reduce="(type: KeyLabel) => type.key"
                        class="text-capitalize" label="label" v-model="inputFilter.is_primary"
                        @option:selected="fetchOrders"
                    />
                </div>  
                <div class="col-12 col-md-3 mt-md-3">
                    <label class="form-label">Order Status</label>
                    <vue-select
                        :options="orderStatuses" :reduce="(status: KeyLabel) => status.key"
                        class="text-capitalize" label="label" v-model="inputFilter.status"
                        @option:selected="fetchOrders"
                    />
                </div>
                <div class="row my-4">
                    <div class="col-12 col-md-auto ms-auto">
                        <button-filter @reset="resetFilter" @filter="fetchOrders"></button-filter>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-auto ms-auto">
                        <button class="btn btn-primary" @click="generateReport" :disabled="isLoading">
                            <span v-if="isLoading" class="spinner-border spinner-border-sm me-2" role="status"></span>
                            <icon v-else name="file-spreadsheet"/>
                            <span>Download</span>
                        </button>
                    </div>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                            @per-page-updated="updatePerPage" :is-showing-per-page="true"/>
            <div class="table-responsive">
                <table class="table table-vcenter text-nowrap datatable">
                    <thead>
                        <tr>
                            <th>Order No</th>
                            <th>Order Type</th>
                            <th class="w-10 text-center">Primary</th>
                            <th class="w-10 text-center">Status</th>
                            <th>User</th>
                            <th>Total Amount</th>
                            <th>Transaction Date</th>
                            <th class="text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="orders.length > 0">
                        <tr v-for="order in orders" :key="order.id">
                            <td>{{ order.no }}</td>
                            <td>{{ getTypeLabel(order.type) }}</td>
                            <td>
                                {{ order.metadata && order.metadata.is_primary !== undefined ? (order.metadata.is_primary ? 'Primary' : 'Extra') : 'N/A' }}
                            </td>
                            <td class="max-w-25">
                                <div class="badge badge-pill w-7-rem text-wrap" :class="getStatusBadge(order.status)">
                                {{ getStatusLabel(order.status, orderStatuses) }}
                                </div>
                            </td>
                            <td>
                                <UserNameId :user="order.user" :role="RoleNameEnum.USER"/>
                            </td>
                            <td>{{ toMYR(order.grandTotalAmount) }}</td>
                            <td>{{ formatDate(order.createdAt, 'DD-MMM-YYYY hh:mm:ss A') }}</td>
                            <td class="text-end">
                                <div class="btn-group">
                                    <router-link class="btn btn-icon btn-primary"
                                                :to="{ name: 'order-show', params: { orderId: order.id } }">
                                        <icon name="eye"/>
                                    </router-link>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                    <tr>
                        <td colspan="7" class="text-center">No data</td>
                    </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isLoading"/>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage"
                            @per-page-updated="updatePerPage"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import {toMYR} from '@/composable/useCurrency'
import {formatDate} from '@/composable/useDate'
import useDateRangePicker from '@/composable/useDateRangePicker'
import useMetaPage from '@/composable/useMetaPage'
import {getStatusBadge, getStatusLabel, getTypeLabel} from '@/composable/useOrder'
import OrderService from '@/services/OrderService'
import {useNotificationsStore} from '@/stores/notifications'
import {useResourcesStore} from '@/stores/resources'
import UserFilter from '@/types/Filter'
import KeyLabel from '@/types/KeyLabel'
import Order from '@/types/Order'
import date from '@/utils/date'
import {Core, easepick} from '@easepick/core'
import {storeToRefs} from 'pinia'
import {onMounted, ref, watch} from 'vue'
import UserNameId from '@/components/UserNameId.vue'
import {RoleNameEnum} from '@/enums/RoleEnum'

const {addToastNotification} = useNotificationsStore()
const {orderStatuses, orderTypes} = storeToRefs(useResourcesStore())
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage
} = useMetaPage()
const {easePickDefaultOptions} = useDateRangePicker()

const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'

const inputFilter = ref<UserFilter>({
    from_date: date.object().startOf('month').format(DEFAULT_DATE_FORMAT),
    to_date: date.object().endOf('month').format(DEFAULT_DATE_FORMAT),
    email: '',
    name: '',
    id: '',
    type: '',
    order_no: '',
    is_primary: '',
})

const isLoading = ref<boolean>(false)
const orders = ref<Array<Order>>([])

const fetchOrders = () => {
    isLoading.value = true

    const query = {
        ...{
            per_page: metaPage.value.perPage,
            page: metaPage.value.currentPage
        },
        ...inputFilter.value
    }
    OrderService.index(query).then(({data: {data, meta}}) => {
        orders.value = data
        updateMetaPage(meta)
    }).catch((error) => {
        addToastNotification({
            message: `Internal server error. Please contact tech team if the error persists.`,
            type: 'danger'
        })
        // console.error(error)
    }).finally(() => isLoading.value = false)
}

const generateReport = () => {
    isLoading.value = true

    const data = {...inputFilter.value}

    OrderService.generateReport(data).then(({data: {message}}) => {
        const response = message
        addToastNotification({
            message: response,
            type: 'success'
        })
    }).catch(() => {
        addToastNotification({
            message: `Internal server error. Please contact tech team if the error persists.`,
            type: 'danger'
        })
    }).finally(() => isLoading.value = false)
}

const resetFilter = () => {
    inputFilter.value = {
        from_date: date.object().startOf('month').format(DEFAULT_DATE_FORMAT),
        to_date: date.object().endOf('month').format(DEFAULT_DATE_FORMAT),
        email: '',
        name: '',
        id: '',
        type: ''
    }

    fetchOrders()
}

fetchOrders()

const shouldFetchOrders = () => {
    return inputFilter.value.is_primary === null || 
           inputFilter.value.type === null || 
           inputFilter.value.status === null
           metaPageTriggered.value;
};

watch(shouldFetchOrders, (newVal) => {
    if (!newVal) return
    
    fetchOrders();
});

watch(
    () => metaPageTriggered.value,
    () => fetchOrders()
)

onMounted(() => {
    const picker = new easepick.create({
        ...easePickDefaultOptions,
        element: <any>document.getElementById('datepicker'),
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value = {
                    from_date: e.detail.start.format(DEFAULT_DATE_FORMAT),
                    to_date: e.detail.end.format(DEFAULT_DATE_FORMAT)
                }
                picker.hide()
            })
        }
    })

    picker.setDateRange(inputFilter.value.from_date, inputFilter.value.to_date)
})
</script>

<style>
.w-7-rem {
    width: 7rem;
}
</style>