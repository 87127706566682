<template>
    <div class="card">
        <div class="card-header">
            <h3 class="card-title">Admin Logs</h3>
        </div>
        <div class="card-body">
            <!-- LOGS -->
            <ul class="list list-timeline px-0">
                <!-- ADD NEW LOG -->
                <li>
                    <div class="list-timeline-content">
                        <form @submit.prevent="submit" class="pb-4 border-bottom">
                            <div class="form-group">
                                <FormLabel label="Add New Log" />
                                <textarea v-model="newAdminLog" class="form-control" name="admin_log" rows="3"
                                    placeholder="Add new log.." @keyup.enter="submit"/>
                            </div>
                            <div class="d-flex pt-3 justify-content-end">
                                <div>
                                    <button type="submit" class="btn btn-primary">Add New Log</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li>
                <template v-if="adminLogs.length > 0">
                    <div class="list-timeline-content">
                        <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage"
                            @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
                    </div>
                    <li v-for="log in adminLogs" :key="`admin-logs-timeline-item-${log.id}`">
                        <div class="list-timeline-content">
                            <div class="card">
                                <div class="card-body">
                                    <div class="text-end gap-2">
                                        <a class="btn btn-icon btn-azure me-2" role="button" data-bs-toggle="modal"
                                            :data-bs-target="`#modal-update-admin-log-${log.id}`">
                                            <icon name="pencil" />
                                        </a>
                                        <a class="btn btn-icon btn-danger" role="button">
                                            <icon name="trash" @click="confirmDeleteData(log.id)" />
                                        </a>
                                    </div>
                                    <AdminLogForm :admin-log="log" :modal-id="`modal-update-admin-log-${log.id}`"
                                        @updated="fetchData" />
                                    <!-- CONTENT -->
                                    <p>{{ log.content }}</p>

                                    <!-- TIMESTAMP -->
                                    <div>
                                        <div class="text-muted" style="font-size: 0.8rem">
                                            <strong class="text-capitalize">
                                                {{ log.user?.name }}
                                            </strong>
                                        </div>
                                        <div class="text-muted" style="font-size: 0.8rem">
                                            {{ formatDate(log.createdAt, 'DD-MMM-YYYY hh:mm A') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <div class="list-timeline-content">
                        <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage"
                            @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="false" />
                    </div>
                </template>
                <template v-else>
                    <li>
                        <p class="text-center text-muted list-timeline-content">No logs</p>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import { PropType, ref, watch } from 'vue'
import FormLabel from './form/FormLabel.vue'
import AdminLogService from '@/services/AdminLogService'
import { AdminLoggableTypeEnum } from '@/enums/AdminLoggableTypeEnum'
import AdminLog from '@/types/AdminLog'
import _ from 'lodash'
import { formatDate } from '@/composable/useDate'
import { AxiosError } from 'axios'
import { useNotificationsStore } from '@/stores/notifications'
import useMetaPage from '@/composable/useMetaPage'
import AdminLogForm from '@/components/AdminLogForm.vue'

const { addToastNotification } = useNotificationsStore()

const props = defineProps({
    loggableType: {
        type: String as PropType<AdminLoggableTypeEnum>,
        required: true,
    },
    loggableId: { type: Number, required: true },
})

const { metaPage, metaPageTriggered, updateMetaPage, updatePerPage, prevPage, nextPage, gotoPage } = useMetaPage()

const newAdminLog = ref('')

const adminLogs = ref<AdminLog[]>([])

const submit = async () => {
    try {
        const { data } = await AdminLogService.store({
            loggable_type: props.loggableType,
            loggable_id: props.loggableId,
            content: newAdminLog.value,
        })
        newAdminLog.value = ''
        fetchData()
    } catch (e) {
        const error = e as AxiosError
        addToastNotification({
            message: 'Failed to add new admin log. Please contact tech team.',
            type: 'danger',
        })
    }
}

const fetchData = async () => {
    try {
        const query = {
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
            loggable_type: props.loggableType,
            loggable_id: props.loggableId,
        }

        const {
            data: { data, meta },
        } = await AdminLogService.index(query)

        adminLogs.value = data
        updateMetaPage(meta)
    } catch (e) {
        const error = e as AxiosError
        addToastNotification({
            message: 'Failed to add new admin log. Please contact tech team.',
            type: 'danger',
        })
    }
}

const deleteData = (id: number) => {
    AdminLogService.delete(id).then(() => {
        fetchData()
    })
}

const confirmDeleteData = (id: number) => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete this remark/log?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            deleteData(id)
        }
    })
}

watch(metaPageTriggered, fetchData)

fetchData()
</script>
