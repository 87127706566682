<template>
    <Teleport to="body">
        <kc-modal :modal-id="modalId" modal-size="md">
            <div class="modal-header">
                <h4 class="modal-title">Admin Log</h4>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <form @submit.prevent="updateAdminLog">
                <div class="modal-body bg-white">
                    <div class="form-group mb-3 row">
                        <label class="form-label col-form-label">
                            Log
                            <span class="text-red">*</span>
                        </label>
                        <div class="col">
                            <textarea
                                type="input"
                                class="form-control"
                                v-model="inputUser.content"
                                :class="{ 'is-invalid': hasError('content') }"
                            />
                            <small class="invalid-feedback" v-if="hasError('content')">{{ getError('content') }}</small>
                        </div>
                    </div>
                </div>
                <div class="modal-footer pt-2 border-top">
                    <button
                        class="btn btn-light"
                        type="button"
                        @click="closeModal"
                        :id="`btnDismissModal${modalId}`"
                        data-bs-dismiss="modal"
                    >
                        Close
                    </button>
                    <button class="btn btn-primary" type="submit">
                        <span>Save</span>
                    </button>
                </div>
            </form>
        </kc-modal>
    </Teleport>
</template>

<script setup lang="ts">
import useFormError from '@/composable/useFormError'
import { $externalResults, AdminLogRule } from '@/rules/AdminLogRule'
import AdminLogService from '@/services/AdminLogService'
import { useNotificationsStore } from '@/stores/notifications'
import UpdateAdminLogFormData from '@/types/formData/UpdateAdminLogFormData'
import useVuelidate from '@vuelidate/core'
import { AxiosError } from 'axios'
import { ref } from 'vue'

const props = defineProps({
    modalId: { type: String, default: 'modal-update-admin-log' },
    adminLog: { type: Object as () => object | any },
})

const emit = defineEmits(['updated'])

const { addToastNotification } = useNotificationsStore()

const inputUser = ref<UpdateAdminLogFormData>({
    content: props.adminLog.content,
})

const v$ = useVuelidate(AdminLogRule, { inputUser }, { $scope: false, $externalResults })
const { hasError, getError, clearAllErrors } = useFormError(v$)

const updateAdminLog = async () => {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate()

    if (!validated) {
        return
    }

    const data = { ...inputUser.value }

    AdminLogService.update(props.adminLog.id, data)
        .then(() => {
            addToastNotification({
                message: 'Successfully updated the log',
                type: 'success',
            })
            closeModal()
            emit('updated')
        })
        .catch((error: AxiosError) => {
            const { data, status } = error.response || {}

            if (status === 422 && data && typeof data === 'object' && 'errors' in data) {
                Object.assign($externalResults.value.inputUser, (data as { errors: Record<string, string[]> }).errors)
            } else {
                addToastNotification({
                    message: 'Internal server error. Please contact the tech team.',
                    type: 'danger',
                })
            }
        })
}

const closeModal = () => {
    document.getElementById(`btnDismissModal${props.modalId}`)?.click()
    clearAllErrors()
}
</script>
