export async function copyToClipboard(text: string): Promise<boolean> {
    try {
        await navigator.clipboard.writeText(text)
        return true
    } catch (error) {
        return false
    }
}

export function limitStringLength(input: string, maxLength: number): string {
    if (input.length > maxLength) {
        return input.slice(0, maxLength) + '...';
    }
    return input;
}

