import ErrorBag from '@/types/ErrorBag'
import { helpers, numeric, required } from '@vuelidate/validators'
import { ref } from 'vue'

export const $externalResults = ref<any>({
    inputUser: <ErrorBag>{},
})

export const rules = () => ({
    inputUser: {
        week_number: {
            required: helpers.withMessage(
                'This week field is required',
                required
            ),
            numeric: helpers.withMessage(
                'This week field must be numeric only',
                numeric
            ),
        },
        day_of_week: {
            required: helpers.withMessage(
                'This day field is required',
                required
            )
        },
        time_of_day: {
            required: helpers.withMessage(
                'This time field is required',
                required
            )
        },
        title: {
            required: helpers.withMessage(
                'This title field is required',
                required
            )
        },
        preview: {
            required: helpers.withMessage(
                'This preview field is required',
                required
            )
        },
        message: {
            required: helpers.withMessage(
                'This message field is required',
                required
            ),
        },
    },
})

export const CohortNotificationRule = rules()
