<template>
    <template v-if="handholding">
        <BasicCard class="mt-4" title="Kiddocarer Details">
            <div class="table-responsive">
                <table class="">
                    <tr>
                        <th>Name</th>
                        <td class="px-3 py-2">
                            <UserNameId :user="handholding.user" :role="RoleNameEnum.CARER" inline />
                        </td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <td class="px-3 py-2"> 
                            <StatusBadge :class="getHandholdingStatusBadge(handholding?.status)"
                            :label="getHandholdingStatusLabel(handholding.status)"
                            show
                            />
                        </td>
                    </tr>
                    <tr>
                        <th> Category </th>
                        <td class="px-3 py-2">{{ handholding?.category }}</td>
                    </tr>
                    <tr>
                        <th>Completed Sessions </th>
                        <td class="px-3 py-2">
                            {{ handholding?.completedSessionsCount }} / {{ handholding?.requiredSessionsCount }}
                        </td>
                    </tr>
                    <tr>
                        <th>Start Date </th>
                        <td class="px-3 py-2">{{ formatDate(handholding.createdAt, 'DD-MMM-YYYY hh:mm A') }}</td>
                    </tr>
                    <tr>
                        <th>Weeks of Handholding </th>
                        <td class="px-3 py-2">{{ handholding.weekCount ?? 'N/A' }}</td>
                    </tr>
                </table>
            </div>
        </BasicCard>

        <!-- LIST: COMPLETED SESSIONS -->
        <BasicCard class="mt-4" :title="`First ${handholding.requiredSessionsCount} Completed Sessions`">
            <div class="table-responsive">
                <table class="table table-vcenter">
                    <thead>
                        <th>Session No</th>
                        <th>Date</th>
                        <th class="text-center">Status</th>
                        <th class="text-center">Remarks</th>
                    </thead>
                    <tbody>
                        <tr v-for="session in firstCompletedSessions" :key="session.id" class="border-bottom ">
                            <td>
                                <SessionNo :session-id="session.id" :session-no="session.no" />
                            </td>
                            <td class="py-3">
                                <div>
                                    {{ formatDate(session.startedAt, 'DD-MMM-YYYY hh:mm A') }} <br>
                                </div>
                            </td>
                            <td>
                                <SessionChangeStatus :session="session" display-only />
                            </td>
                            <td class="text-center">{{ session.carerSessionReview?.remarks ?? '-' }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </BasicCard>

        <!-- ADMIN LOGS -->
        <AdminLogSection :loggable-type="AdminLoggableTypeEnum.CARER_HANDHOLDING" :loggable-id="handholding.id"
            class="mt-4" />
    </template>
    <template v-else>
        <p class="text-center"> Handholding not found </p>
    </template>

</template>

<script lang="ts" setup>
import BasicCard from '@/components/BasicCard.vue'
import { formatDate } from '@/composable/useDate'
import CarerHandholdingService from '@/services/CarerHandholdingService'
import { useNotificationsStore } from '@/stores/notifications'
import CarerHandholding from '@/types/CarerHandholding'
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { getSessionStatusBadge, getSessionTagLabel } from '@/composable/useSession'
import SessionChangeStatus from '../sessions/components/SessionChangeStatus.vue'
import UserNameId from '@/components/UserNameId.vue'
import { RoleNameEnum } from '@/enums/RoleEnum'
import AdminLogSection from '@/components/AdminLogSection.vue'
import { AdminLoggableTypeEnum } from '@/enums/AdminLoggableTypeEnum'
import SessionNo from '@/components/SessionNo.vue'
import { getHandholdingStatusBadge, getHandholdingStatusLabel } from '@/composable/useCarerHandholding'
import StatusBadge from '@/components/StatusBadge.vue'

const route = useRoute()
const { addToastNotification } = useNotificationsStore()

const handholdingId = route.params.id

const handholding = ref<CarerHandholding>()

const firstCompletedSessions = computed(() => handholding.value?.firstCompletedCarerSessions ?? [])

const fetchData = async () => {

    if (!handholdingId) {
        addToastNotification({
            type: 'danger',
            message: "Oops! Carer handholding not found."
        })
    }

    try {
        const { data } = await CarerHandholdingService.show(Number(handholdingId))
        handholding.value = data.data
    } catch (e) {

    }
}

onMounted(() => {
    fetchData()
})

</script>

<style scoped>
.stats-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
}
</style>