<template>
    <kc-modal :modal-id="modalId" modal-size="lg">
        <div class="modal-header">
            <h4 class="modal-title">{{ props.mode === 'create' ? 'Add' : 'Edit' }} Cohort Notification</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form @submit.prevent="saveCohortNotification">
            <div class="modal-body bg-white">
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Week <span class="text-red">*</span></label>
                    <div class="col">
                        <input type="number" class="form-control" :class="{ 'is-invalid': hasError('week_number') }" v-model="inputUser.week_number">
                        <small class="invalid-feedback" v-if="hasError('week_number')">{{ getError('week_number') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Day <span class="text-red">*</span></label>
                    <div class="col">
                        <vue-select
                        :options="[{key:'Monday',label:'Monday'},{key:'Tuesday',label:'Tuesday'},{key:'Wednesday',label:'Wednesday'},{key:'Thursday',label:'Thursday'},{key:'Friday',label:'Friday'},{key:'Saturday',label:'Saturday'},{key:'Sunday',label:'Sunday'}]" :reduce="(type: KeyLabel) => type.key"
                        :class="{ 'is-invalid': hasError('day_of_week') }" label="label" v-model="inputUser.day_of_week"/>
                        <small class="invalid-feedback" v-if="hasError('day_of_week')">{{ getError('day_of_week') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Time <span class="text-red">*</span></label>
                    <div class="col">
                        <input type="time" class="form-control" :class="{ 'is-invalid': hasError('time_of_day') }" v-model="inputUser.time_of_day"/>
                        <small class="invalid-feedback" v-if="hasError('time_of_day')">{{ getError('time_of_day') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Title <span class="text-red">*</span></label>
                    <div class="col">
                        <input type="input" class="form-control" :class="{ 'is-invalid': hasError('title') }" v-model="inputUser.title"/>
                        <small class="invalid-feedback" v-if="hasError('title')">{{ getError('title') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Preview <span class="text-red">*</span></label>
                    <div class="col">
                        <textarea type="input" class="form-control" :class="{ 'is-invalid': hasError('preview') }" v-model="inputUser.preview"/>
                        <small class="invalid-feedback" v-if="hasError('preview')">{{ getError('preview') }}</small>
                    </div>
                </div>
                <div class="form-group mb-3 row">
                    <label class="form-label col-form-label">Message <span class="text-red">*</span></label>
                    <div class="col">
                        <MdEditor editorId="createBody" language="en-US"  :toolbarsExclude="excludedToolbars" v-model="inputUser.message" :class="{ 'is-invalid': hasError('message') }" />
                        <small class="invalid-feedback" v-if="hasError('message')">{{ getError('message') }}</small>
                    </div>
                </div>
            </div>
            <div class="modal-footer pt-2 border-top">
                <button class="btn btn-light" type="button" @click="closeModal" :id="`btnDismissModal${modalId}`" data-bs-dismiss="modal">Close</button>
                <button class="btn btn-primary" type="submit">
                    <span>Save</span>
                </button>
            </div>
        </form>
    </kc-modal>
</template>

<script setup lang="ts">
import useFormError from '@/composable/useFormError';
import { $externalResults, CohortNotificationRule } from '@/rules/CohortNotificationRule';
import CohortNotificationService from '@/services/CohortNotificationService';
import { useNotificationsStore } from '@/stores/notifications';
import UserInput from '@/types/Input';
import KeyLabel from '@/types/KeyLabel';
import useVuelidate from '@vuelidate/core';
import { MdEditor } from 'md-editor-v3';
import { onMounted, ref } from 'vue';

const props = defineProps({
    modalId: { type: String, default: 'modal-add-cohort-notification' },
    mode: { type: String, default: 'create' },
    cohortNotification: { type: Object as () => object | any }
})

const emit = defineEmits(['updated-or-created'])

const { addToastNotification } = useNotificationsStore();

const inputUser = ref<UserInput>({
    week_number:'',
    day_of_week:'',
    time_of_day:'',
    title:'',
    preview:'',
    message:''
})

const excludedToolbars: Array<string> = [
    'task',
    'image',
    'table',
    'mermaid',
    'katex',
    'revoke',
    'next',
    'save',
    'pageFullscreen',
    'fullscreen',
    'htmlPreview',
    'catalog',
    'github',
]

const v$ = useVuelidate(CohortNotificationRule, { inputUser }, { $scope: false, $externalResults })
const { hasError, getError, clearAllErrors } = useFormError(v$)

const saveCohortNotification = async () => {
    $externalResults.value.inputUser = {}
    const validated = await v$.value.$validate();

    if(!validated) {
        console.log('something went wrong');
    }
    
    if (validated) {
        const data = { ...inputUser.value }

        const cohortNotificationService = props.mode === 'create'
            ? CohortNotificationService.store(data)
            : CohortNotificationService.update(props.cohortNotification.id, data)
        
            cohortNotificationService.then(() => {
            addToastNotification({
                message: `Successfully save the notification`,
                type: 'success',
            })
            closeModal()
            emit('updated-or-created')
        }).catch(({ response: { data, status } }) => {
            if (status === 422) {
                Object.assign($externalResults.value.inputUser, data.errors)
            } else {
                addToastNotification({
                    message: 'Internal server error. Please contact tech team.',
                    type: 'danger',
                })
            }
        })
    }
}

const closeModal = () => {
    document.getElementById(`btnDismissModal${props.modalId}`)?.click()
    clearAllErrors()

    if (props.mode === 'create') resetForm()
}

const resetForm = () => {
    $externalResults.value.inputUser = {}
    inputUser.value.week_number = ''
    inputUser.value.day_of_week = ''
    inputUser.value.time_of_day = ''
    inputUser.value.title = ''
    inputUser.value.preview = ''
    inputUser.value.message = ''
}

onMounted(() => {
    if (props.cohortNotification !== undefined) {
        Object.keys(props.cohortNotification).forEach((key) => {
            if (key in inputUser.value) {
                inputUser.value[key] = props.cohortNotification[key]
            }
            if (key === 'weekNumber') inputUser.value.week_number = props.cohortNotification[key]
            if (key === 'dayOfWeek') inputUser.value.day_of_week = props.cohortNotification[key]
            if (key === 'timeOfDay') inputUser.value.time_of_day = props.cohortNotification[key].slice(0, 5);
        })
    }
})
</script>