<template>
    <label :for="name" class="btn btn-outline-primary w-100">
        <i class="fas fa-upload me-2"></i>
        {{ displayLabel }}
        <input type="file" :name="name" :id="name" class="d-none" @change="onFileChange" accept="image/*">
    </label>
</template>

<script setup lang="ts">
import { SelectedUploadImage } from '@/services/SelectedUploadImage'
import { computed } from 'vue'

const props = defineProps({
    name: { type: String, required: true },
    label: { type: String, required: true },
    modelValue: { type: Object as () => SelectedUploadImage, required: true }
})

const emit = defineEmits<{
    (e: 'update:modelValue', value: SelectedUploadImage): void
}>()

const onFileChange = (event: Event) => {
    const target = event.target as HTMLInputElement
    if (target && target.files && target.files.length > 0) {
        const file = target.files[0]
        const fileUrl = URL.createObjectURL(file)
        emit('update:modelValue', { file, fileUrl })
    } else {
        emit('update:modelValue', { file: null, fileUrl: null })
    }
}

const displayLabel = computed(() => {
    if (props.modelValue.file) {
        return 'File Selected'
    } else {
        return props.label ?? 'Select file'
    }
})
</script>

<style scoped>
.btn-outline-primary {
    border-color: #ced4da;
    color: #495057;
}

.btn-outline-primary:hover {
    background-color: #f8f9fa;
    border-color: #ced4da;
    color: #495057;
}
</style>