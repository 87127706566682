<template>
    <div class="row dependent-container">
        <!-- Original Details -->
        <div class="col-md-6 mt-3 mb-3 mb-md-0">
            <div class="card ">
                <div class="card-body">
                    <h3 class="fw-bold mb-3">Original Dependents</h3>
                    <ul class="list-unstyled original-details-container">
                        <li v-for="dependent in session?.dependents" :key="`session-dependent-${dependent.id}`"
                            class="mb-2">
                            <ul>
                                <li class="px-2">
                                    <h5 class="card-title">{{ dependent.name }}</h5>
                                    <p class="card-text">
                                        Age: {{ dependent.age }}<br>
                                        Gender: {{ dependent.gender === 1 ? 'Male' : 'Female' }}<br>
                                        Type: {{ getDependentType(dependent.dependentTypeId) }}
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- Select new dependents -->
        <div class="col-md-6 border-left">
            <div class="h-100">
                <div class="card-body">
                    <h3 class="fw-bold mb-3">Select new dependents</h3>
                    <ul class="list-unstyled dependent-list">
                        <li v-for="dependent in dependentOptions" :key="`option-dependent-${dependent.id}`"
                            class="mb-2">
                            <div class="card py-1"
                                :class="{ 'border border-primary': selectedDependents.includes(dependent.id) }"
                                @click="toggleDependent(dependent.id)">
                                <div class="card-body px-3">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div>
                                            <h5 class="card-title">{{ dependent.name }}</h5>
                                            <p class="card-text">
                                                Age: {{ dependent.age }}<br>
                                                Gender: {{ dependent.gender === 1 ? 'Male' : 'Female' }}<br>
                                                Type: {{ getDependentType(dependent.dependentTypeId) }}
                                            </p>
                                        </div>
                                        <div>
                                            <input v-model="selectedDependents" :value="dependent.id" type="checkbox"
                                                :checked="selectedDependents.includes(dependent.id)" @click.stop>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-end mt-3">
        <button class="btn btn-primary" @click.prevent="updateDependents" :disabled="isUpdating">
            {{ isUpdating ? 'Updating...' : 'Update Dependents' }}
        </button>
    </div>
</template>

<script setup lang="ts">
import { DependentTypeEnum } from '@/enums/DependentTypeEnum'
import { SessionTypeEnum } from '@/enums/SessionTypeEnum'
import Dependent from '@/types/Dependent'
import Session from '@/types/Session'
import { computed, ref, watch } from 'vue'
import { useNotificationsStore } from '@/stores/notifications'
import SessionService from '@/services/SessionService'

const { addToastNotification } = useNotificationsStore()

const emit = defineEmits(['success'])

const props = defineProps({
    session: { type: Object as () => Session, required: true },
    customerDependents: { type: Array as () => Dependent[], required: true }
})

const selectedDependents = ref<number[]>([])
const isUpdating = ref(false)

const dependentOptions = computed(() => {
    if (!props.customerDependents) {
        return []
    }

    return props.customerDependents.filter((dependent: Dependent) => {
        if (props.session.sessionTypeId === SessionTypeEnum.ID_CHILDCARE) {
            return dependent.dependentTypeId === DependentTypeEnum.ID_CHILD
        } else if (props.session.sessionTypeId === SessionTypeEnum.ID_ELDERCARE) {
            return dependent.dependentTypeId === DependentTypeEnum.ID_ELDER
        }
        return true
    })
})


const getDependentType = (typeId: number) => {
    switch (typeId) {
        case DependentTypeEnum.ID_CHILD:
            return 'Child'
        case DependentTypeEnum.ID_ELDER:
            return 'Elder'
        default:
            return 'Unknown'
    }
}

const toggleDependent = (dependentId: number) => {
    const index = selectedDependents.value.indexOf(dependentId)
    if (index === -1) {
        selectedDependents.value.push(dependentId)
    } else {
        selectedDependents.value.splice(index, 1)
    }
}

const updateDependents = async () => {
    isUpdating.value = true
    try {
        const data = {
            dependent_ids: selectedDependents.value
        }
        await SessionService.update(props.session.id, data)
        addToastNotification({
            message: "Dependents updated successfully",
            type: 'success'
        })
        emit('success')
    } catch (e) {
        addToastNotification({
            message: "An error occurred when updating dependents",
            type: 'danger'
        })
    }
    isUpdating.value = false
}


</script>

<style lang="css" scoped>
.dependent-container {
    display: flex;
    flex-wrap: wrap;
}

.border-left {
    border-left: 1px solid #dee2e6;
}

@media (min-width: 768px) {

    .original-details-container,
    .dependent-list {
        height: 50vh;
        overflow-y: auto;
    }
}

@media (max-width: 767px) {
    .border-left {
        border-left: none;
        border-top: 1px solid #dee2e6;
        padding-top: 1rem;
    }

    .dependent-container {
        flex-direction: column;
    }

    .original-details-container,
    .dependent-list {
        height: auto;
        overflow-y: visible;
    }
}
</style>