import { defineStore } from 'pinia'
import { defineAsyncComponent, markRaw, ref } from 'vue'
import Session from '@/types/Session'
import SessionService from '@/services/SessionService'
import { useNotificationsStore } from '@/stores/notifications'


export const useShowSessionStore = defineStore(
    'showSession',
    () => {

        const { addToastNotification } = useNotificationsStore()

        const sessionId = ref<number>()
        const session = ref<Session>()

        const activeComponent = ref<object>({})
        const subMenus = ref<Array<any>>([])

        const isFetching = ref<boolean>(false)
        const setIsFetching = (value = true) => isFetching.value = value

        const fetchSession = async (reloadComponentSlug: string = '') => {
            setIsFetching()

            if (!sessionId.value) {
                return
            }

            try {
                const { data: { data } } = await SessionService.show(sessionId.value)
                session.value = data
                configureSubMenu()
                setActiveComponent(reloadComponentSlug)
            } catch (e) {
                addToastNotification({
                    message: `Failed to fetch session #${sessionId} due to internal server error. Please contact tech team.`,
                    type: 'danger'
                })
            }

            setIsFetching(false)
        }


        const setActiveComponent = (menu: any = null) => {
            if (typeof menu === 'string' && menu.length !== 0) {
                activeComponent.value = subMenus.value.find(
                    (subMenu) => subMenu.slug === menu
                )
            } else if (typeof menu === 'object') {
                activeComponent.value = menu
            } else {
                activeComponent.value = subMenus.value[1]
            }
        }

        const configureSubMenu = () => {
            subMenus.value = [
                {
                    title: 'Overview',
                    component: defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionOverview.vue')
                    ),
                    slug: 'session-overview',
                    props: {
                        session: session.value
                    }
                },
                {
                    title: 'Orders',
                    component: defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionOrders.vue')
                    ),
                    slug: 'session-orders',
                    props: {
                        session: session.value,
                        mainOrder: session.value?.order,
                        extraOrders: session.value?.orders
                    }
                },
                {
                    title: 'Dependents',
                    component: defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionDependents.vue')
                    ),
                    slug: 'session-dependents',
                    props: {
                        dependents: session.value?.dependents ?? []
                    }
                },
                {
                    title: 'Carer Applications',
                    component: defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionCarerApplications.vue')
                    ),
                    slug: 'session-carer-applications',
                    props: {
                        sessionId: session.value?.id,
                        preferredCarers: session.value?.preferredCarers
                    }
                },
                {
                    title: 'Review',
                    component: defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionReview.vue')
                    ),
                    slug: 'session-review',
                    props: {
                        sessionId: session.value?.id
                    }
                },
                {
                    title: 'Check List',
                    component: defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionChecklist.vue')
                    ),
                    slug: 'session-check-list',
                    props: {
                        document: session.value?.document
                    }
                },
                {
                    title: 'Change Logs',
                    component: markRaw(defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionActivityLogs/SessionActivityLog.vue')
                    )),
                    slug: 'session-activity-log',
                    props: {
                        activityLogs: session.value?.activityLogs
                    }
                }
            ]
        }

        return {
            sessionId,
            session,
            isFetching,
            subMenus,
            activeComponent,
            setActiveComponent,
            fetchSession
        }
    }
)