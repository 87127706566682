<template>
    <label class="form-check form-check-inline">
        <input class="form-check-input" type="checkbox" :name="name" :value="value" :checked="isChecked"
            @input="onCheckboxClicked">
        <span class="form-check-label">{{ label }}</span>
    </label>
</template>

<script setup lang="ts">

import { computed } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    modelValue: { type: [Array, Boolean], default: () => [] },
    value: { type: [String, Number, Boolean], required: true },
    name: { type: String, required: true },
    label: { type: String }
})

const isChecked = computed<Boolean>(() => {
    if (Array.isArray(props.modelValue)) {
        return props.modelValue.includes(props.value)
    }
    return props.modelValue === props.value
})

const onCheckboxClicked = () => {
    if (Array.isArray(props.modelValue)) {
        let updatedValue = [...props.modelValue]
        if (isChecked.value) {
            updatedValue.splice(updatedValue.indexOf(props.value), 1)
        } else {
            updatedValue.push(props.value)
        }
        emit('update:modelValue', updatedValue)
    } else {
        emit('update:modelValue', !props.modelValue)
    }
}
</script>
