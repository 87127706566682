<template>
    <div class="time-picker w-100">
        <select v-model="hours" @change="updateTime">
            <option v-for="hour in 12" :key="hour" :value="hour.toString().padStart(2, '0')">
                {{ hour.toString().padStart(2, '0') }}
            </option>
        </select>
        :
        <select v-model="minutes" @change="updateTime">
            <option v-for="minute in 60" :key="minute - 1" :value="(minute - 1).toString().padStart(2, '0')">
                {{ (minute - 1).toString().padStart(2, '0') }}
            </option>
        </select>
        <select v-model="period" @change="updateTime">
            <option value="AM">AM</option>
            <option value="PM">PM</option>
        </select>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

const props = defineProps({
    modelValue: {
        type: String,
        default: ''
    },
    minTime: {
        type: String,
        default: '12:00 AM'
    },
    maxTime: {
        type: String,
        default: '11:59 PM'
    }
})

const emit = defineEmits(['update:modelValue'])

const hours = ref('12')
const minutes = ref('00')
const period = ref('AM')

const updateTime = () => {
    const time = `${hours.value}:${minutes.value} ${period.value}`
    emit('update:modelValue', time)
}

watch(() => props.modelValue, (newValue) => {
    if (newValue) {
        const [time, newPeriod] = newValue.split(' ')
        const [newHours, newMinutes] = time.split(':')
        hours.value = newHours
        minutes.value = newMinutes
        period.value = newPeriod
    }
}, { immediate: true })
</script>

<style scoped>
.time-picker {
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
</style>