<template>
    <div class="card">
        <div class="card-body">
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md-3">
                    <label class="form-label">Transaction Date</label>
                    <div class="input-group">
                        <input type="text" id="datepicker" class="form-control" placeholder="Select date range" readonly="true">
                        <span class="input-group-text">
                            <icon name="calendar" />
                        </span>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <label class="form-label">Email</label>
                    <input type="email" class="form-control" v-model="inputFilter.email">
                </div>
                <div class="col-12 col-md-3">
                    <label class="form-label">Name</label>
                    <input type="text" class="form-control" v-model="inputFilter.name">
                </div>
                <div class="col-12 col-md-3">
                    <label class="form-label">ID</label>
                    <input type="text" class="form-control" placeholder="P20216288" v-model="inputFilter.id">
                </div>
                <div class="col-12 col-md-3 mt-md-3">
                    <label class="form-label">Transaction Type</label>
                    <vue-select
                        :options="types" :reduce="(type: OptionType) => type.value"
                        class="text-capitalize" label="name" v-model="inputFilter.type"
                        @option:selected="fetchLoyaltyPoints"
                    />
                </div>
                <div class="col-12 col-md-3 mt-md-3">
                    <label class="form-label">User Role</label>
                    <vue-select
                        :options="roles" :reduce="(type: OptionType) => type.value"
                        class="text-capitalize" label="name" v-model="inputFilter.role"
                        @option:selected="fetchLoyaltyPoints"
                    />
                </div>
                <div class="col-12 col-md-auto">
                    <button-filter @reset="resetFilter" @filter="fetchLoyaltyPoints"></button-filter>
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button class="btn btn-primary" @click="generateReport" :disabled="isLoading">
                        <span v-if="isLoading" class="spinner-border spinner-border-sm me-2" role="status"></span>
                        <icon v-else name="file-spreadsheet" />
                        <span>Download</span>
                    </button>
                </div>
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
            <div class="table-responsive">
                <table class="table table-vcenter text-nowrap datatable">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Role</th>
                            <th>Transaction Type</th>
                            <th>Amount</th>
                            <th>Description</th>
                            <th>Transaction Date</th>
                        </tr>
                    </thead>
                    <tbody v-if="loyaltyPoints.length > 0">
                        <tr v-for="loyaltyPoint in loyaltyPoints" :key="loyaltyPoint.id">
                            <td>{{ loyaltyPoint.user.mainProfile?.onlineId }}</td>
                            <td>{{ loyaltyPoint.user.name }}</td>
                            <td class="text-capitalize">{{ loyaltyPoint.user?.roles.some(role => role.name === RoleNameEnum.USER) ? 'customer' : 'carer' }}</td>
                            <td><span class="badge badge-pill" :class="[getTypeBadge(loyaltyPoint.type)]">{{ getTypeLabel(loyaltyPoint.type) }}</span></td>
                            <td>{{ loyaltyPoint.amount }}</td>
                            <td>{{ loyaltyPoint.description }}</td>
                            <td>{{ formatDate(loyaltyPoint.createdAt, 'DD-MMM-YYYY hh:mm:ss A') }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="7" class="text-center">No data</td>
                        </tr>
                    </tbody>
                </table>
                <overlay-spinner :is-showing="isLoading" />
            </div>
            <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { formatDate } from '@/composable/useDate'
import useDateRangePicker from '@/composable/useDateRangePicker'
import { getTypeBadge, getTypeLabel } from '@/composable/useLoyaltyPoint'
import useMetaPage from '@/composable/useMetaPage'
import { LoyaltyPointTypeEnum } from '@/enums/LoyaltyPointTypeEnum'
import { RoleIdEnum, RoleNameEnum } from '@/enums/RoleEnum'
import LoyaltyPointService from '@/services/LoyaltyPointService'
import { useNotificationsStore } from '@/stores/notifications'
import UserFilter from '@/types/Filter'
import LoyaltyPoint from '@/types/LoyaltyPoint'
import OptionType from '@/types/OptionType'
import date from '@/utils/date'
import { Core, easepick } from '@easepick/core'
import { onMounted, ref, watch } from 'vue'

const { addToastNotification } = useNotificationsStore()
const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()
const { easePickDefaultOptions } = useDateRangePicker()

const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY'

const types: OptionType[] = [
    { name: 'Earned', value: LoyaltyPointTypeEnum.TYPE_EARNED },
    { name: 'Redeemed', value: LoyaltyPointTypeEnum.TYPE_REDEEMED },
    { name: 'Expired', value: LoyaltyPointTypeEnum.TYPE_EXPIRED },
    { name: 'Refunded', value: LoyaltyPointTypeEnum.TYPE_REFUNDED },
]

const roles: OptionType[] = [
    { name: 'Customer', value: RoleIdEnum.ID_USER },
    { name: 'Carer', value: RoleIdEnum.ID_CARER },
]

const inputFilter = ref<UserFilter>({
    from_date: date.object().startOf('month').format(DEFAULT_DATE_FORMAT),
    to_date: date.object().endOf('month').format(DEFAULT_DATE_FORMAT),
    email: '',
    name: '',
    id: '',
    type: '',
    role: '',
})

const isLoading = ref<boolean>(false)
const loyaltyPoints = ref<Array<LoyaltyPoint>>([])

const fetchLoyaltyPoints = () => {
    isLoading.value = true
    
    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    }

    LoyaltyPointService.index(query).then(({ data: { data, meta }}) => {
        loyaltyPoints.value = data
        updateMetaPage(meta)            
    }).catch(() => {
        addToastNotification({
            message: `Internal server error. Please contact tech team if the error persists.`,
            type: 'danger',
        })
    }).finally(() => isLoading.value = false)
}

const generateReport = () => {
    isLoading.value = true

    const data = { ...inputFilter.value }

    LoyaltyPointService.generateReport(data).then(({ data: { message }}) => {
        const response = message
        addToastNotification({
            message: response,
            type: 'success',
        })
    }).catch(() => {
        addToastNotification({
            message: `Internal server error. Please contact tech team if the error persists.`,
            type: 'danger',
        })
    }).finally(() => isLoading.value = false)
}

const resetFilter = () => {
    inputFilter.value = {
        from_date: date.object().startOf('month').format(DEFAULT_DATE_FORMAT),
        to_date: date.object().endOf('month').format(DEFAULT_DATE_FORMAT),
        email: '',
        name: '',
        id: '',
        type: '',
        role: '',
    }

    fetchLoyaltyPoints()
}

fetchLoyaltyPoints()

watch(
    () => inputFilter.value.type === null,
    () => fetchLoyaltyPoints()
)

watch(
    () => inputFilter.value.role === null,
    () => fetchLoyaltyPoints()
)

watch(
    () => metaPageTriggered.value,
    () => fetchLoyaltyPoints()
)

onMounted(() => {
    const picker = new easepick.create({
        ...easePickDefaultOptions,
        element: <any>document.getElementById('datepicker'),
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value = {
                    from_date: e.detail.start.format(DEFAULT_DATE_FORMAT),
                    to_date: e.detail.end.format(DEFAULT_DATE_FORMAT),
                }
                picker.hide()
            })
        }
    })

    picker.setDateRange(inputFilter.value.from_date, inputFilter.value.to_date)
})
</script>