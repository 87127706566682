import { getQueryString } from '@/composable/http'
import httpClientV2 from './HttpClientV2'
import StoreAdminLogFormData from '@/types/formData/StoreAdminLogFormData'
import { AdminLoggableTypeEnum } from '@/enums/AdminLoggableTypeEnum'
import UpdateAdminLogFormData from '@/types/formData/UpdateAdminLogFormData'

export default {
    index(query: { loggable_type: AdminLoggableTypeEnum; loggable_id: number }) {
        const endpoint = '/backoffice/admin-logs'
        const queryString = getQueryString(query)

        return httpClientV2.get(`${endpoint}?${queryString}`)
    },

    store(data: StoreAdminLogFormData) {
        const endpoint = '/backoffice/admin-logs'
        return httpClientV2.post(endpoint, data)
    },

    update(id: number, data: UpdateAdminLogFormData) {
        const endpoint = '/backoffice/admin-logs'
        return httpClientV2.patch(`${endpoint}/${id}`, data)
    },

    delete(id: number) {
        const endpoint = '/backoffice/admin-logs'
        return httpClientV2.delete(`${endpoint}/${id}`)
    },
}
