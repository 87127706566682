<template>

    <!-- Original Details -->
    <div class="card mt-3 ">
        <div class="card-body">
            <h3 class="fw-bold">Original Details</h3>
            <ul>
                <li class="pb-1">Start Time: {{ formatDate(session.startedAt, 'DD-MMM-YYYY, hh:mm A (dddd)') }}</li>
                <li class="py-1">End Time: {{ formatDate(session.endedAt, 'DD-MMM-YYYY, hh:mm A (dddd)') }}</li>
                <li class="py-1">Duration: {{ session.totalHours }} hours</li>
            </ul>
        </div>
    </div>

    <!-- Reschedule -->
    <form class="mt-4">
        <div class="pb-3">
            <div class="mt-2">
                <FormLabel label="New Start Time" />
                <DatePicker v-model="form.started_at" :id="`datepicker-session-${session.id}-edit`"
                    :invalid="!!errors.started_at" />
                <ErrorMessage :has-error="!!errors.started_at" :message="errors.started_at?.[0]" />
            </div>
            <div class="mt-3">
                <FormLabel label="New Duration (Hours)" />
                <input class="form-control" :class="{ 'is-invalid': !!errors.duration }"
                    v-model.number="form.duration_hours" @input="errors.duration_hours = null" type="number" min="2"
                    max="24">
                <ErrorMessage :has-error="!!errors.duration_hours" :message="errors.duration_hours?.[0]" />
            </div>
            <div class="mt-3">
                <FormLabel label="New End Time" />
                <p class="border rounded p-2">
                    {{ newEndTimeDisplay ?? '-' }}
                </p>
            </div>
            <div class="mt-3">
                <FormLabel label="Remove Last-Minute Surcharge / Bonus?" />
                <RadioInput v-model="form.exclude_last_minute_bonus" :value="true" name="excludeLastMinuteBonus"
                    label="Yes" />
                <RadioInput v-model="form.exclude_last_minute_bonus" :checked="true" :value="false"
                    name="excludeLastMinuteBonus" label="No" />
                <ErrorMessage :has-error="!!errors.exclude_last_minute_bonus"
                    :message="errors.exclude_last_minute_bonus?.[0]" />
            </div>
        </div>
        <div class="d-flex w-full justify-content-end mt-3">
            <button class="btn btn-primary" @click.prevent="submit" :disabled="isSubmitting">
                <span v-if="isSubmitting">Rescheduling...</span>
                <span v-else>Reschedule</span>
            </button>
        </div>
    </form>
</template>

<script setup lang="ts">
import DatePicker from '@/components/form/DatePicker.vue'
import ErrorMessage from '@/components/form/ErrorMessage.vue'
import RadioInput from '@/components/form/RadioInput.vue'
import { formatDate } from '@/composable/useDate'
import SessionService from '@/services/SessionService'
import { useNotificationsStore } from '@/stores/notifications'
import RescheduleFormData from '@/types/formData/RescheduleFormData'
import Session from '@/types/Session'
import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import { computed, ref } from 'vue'
import FormLabel from '../form/FormLabel.vue'

const emits = defineEmits(['success'])

const props = defineProps({
    session: { type: Object as () => Session, required: true }
})

const { addToastNotification } = useNotificationsStore()


const errors = ref<any>({
    started_at: null,
    duration_hours: null,
    exclude_last_minute_bonus: null
})

const form = ref({
    started_at: props.session?.startedAt ? formatDate(props.session?.startedAt, 'DD-MMM-YYYY hh:mm A') : '',
    duration_hours: props.session?.totalHours ?? '',
    exclude_last_minute_bonus: false
})

const isSubmitting = ref(false)
const setIsSubmitting = (value = true) => isSubmitting.value = value

const newEndTimeDisplay = computed(() => {
    if (form.value.started_at && form.value.duration_hours) {
        const startDate = dayjs(form.value.started_at)
        const endDate = startDate.add(form.value.duration_hours, 'hour')
        return endDate.format('DD-MMM-YYYY hh:mm A')
    }
    return null
})

const submit = async () => {

    if (!props.session) {
        addToastNotification({
            message: 'Failed to submit form. Please contact tech team.',
            type: 'danger'
        })
        return
    }

    if (!form.value.started_at) {
        errors.value.started_at = ['Please select a start time.']
        return
    }

    if (!form.value.duration_hours) {
        errors.value.duration_hours = ['Please add a duration.']
        return
    }

    const data: RescheduleFormData = {
        started_at: form.value.started_at,
        duration_hours: form.value.duration_hours,
        exclude_last_minute_bonus: Boolean(form.value?.exclude_last_minute_bonus)
    }

    try {
        setIsSubmitting()

        await SessionService.reschedule(props.session.id, data)

        addToastNotification({
            message: 'Session successfully rescheduled!',
            type: 'success'
        })

        emits('success')

    } catch (e) {
        const error = e as AxiosError
        if (error.response?.status === 422) {
            const responseData = error.response.data as { errors?: Record<string, string[]> }
            errors.value = responseData.errors
        } else {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger'
            })
        }

    } finally {
        setIsSubmitting(false)
    }
}
</script>