<template>
    <div>
        <ChatDetailAccordion :id="`accordion-${props.label}-session`" title="Session Details">
            <ul class="list-group mt-3">
                <template v-if="session">
                    <li class="d-flex flex-column justify-content-between space-x-2 px-3 list-group-item bg-light text-dark"
                        style="padding-top: 12px; padding-bottom: 12px;">
                        <div class="text-muted space-y-2">
                            <ChatSessionDetailsItem icon-name="book-2">
                                <span> {{ session.no }} </span>
                                <SessionStatusBadge :status-id="session.sessionStatusId"
                                    :session-statuses="sessionStatuses" />
                            </ChatSessionDetailsItem>
                            <ChatSessionDetailsItem icon-name="calendar">
                                {{ session.startedAtDate }}
                            </ChatSessionDetailsItem>
                            <ChatSessionDetailsItem icon-name="clock">
                                {{ session.startedAtTime }} - {{ session.endedAtTime }}({{ session.totalHours }} hours)
                            </ChatSessionDetailsItem>
                            <ChatSessionDetailsItem icon-name="user">
                                {{ session.carer?.mainProfile?.onlineId }}
                                {{ session.carer?.mainProfile?.fullName }}
                            </ChatSessionDetailsItem>
                        </div>
                        <div v-if="shouldMonitor" class="mt-3">
                            <SessionMonitoringQuickView :session-event="session.event" :session-id="session.id"
                                :session-no="`${session.no}`" :carer-logs="session.carerLogs" :include-image=false />
                        </div>
                    </li>
                </template>
                <template v-else>
                    <li class="space-x-2 px-3 list-group-item bg-light text-dark"
                        style="padding-top: 12px; padding-bottom: 12px;">
                        {{ emptyTemplate }}
                    </li>
                </template>
            </ul>
        </ChatDetailAccordion>
        <hr class="my-3" />
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useResourcesStore } from '@/stores/resources'
import Session from '@/types/Session'
import ChatDetailAccordion from '@/components/chats/ChatDetailAccordion.vue'
import ChatSessionDetailsItem from '@/components/chats/ChatSessionDetailsItem.vue'
import SessionStatusBadge from '@/components/SessionStatusBadge.vue'
import SessionMonitoringQuickView from '@/components/SessionMonitoringQuickView.vue'

const { sessionStatuses } = storeToRefs(useResourcesStore())

const props = defineProps({
    session: { type: Object as () => Session | undefined, required: true },
    emptyTemplate: { type: String, required: true },
    label: { type: String, required: true },
    shouldMonitor: { type: Boolean, default: false }
})

</script>

<style scoped>
hr {
    margin-left: -16px;
    margin-right: -16px;
}

.list-group {
    border-radius: 8px;
}
</style>
