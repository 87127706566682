<template>
    <div class="table-responsive-sm" style="overflow-x: auto">
        <table class="table ">
            <thead>
            <tr>
                <th class="text-left">Name</th>
                <th class="text-center">Status</th>
                <th class="text-center">Travelling Tips</th>
                <th>Applied At</th>
                <th>Cancelled At</th>
                <th>Cancel Reason</th>
            </tr>
            </thead>
            <tbody v-if="cancelledCarerApplications?.length">
            <tr v-for="application in cancelledCarerApplications" :key="application.carer?.id">
                <td>
                <span v-if="application.carer">
                    <UserNameId :user="application.carer" :role="RoleNameEnum.CARER"/>
                    <span v-if="application.isPreferred" class="ms-1">
                        <icon name="star"/>
                    </span>
                </span>
                </td>
                <td class="text-center">
                    <SessionCarerApplicationStatus :application="application"
                                                   @status-changed="fetchCarerApplications"
                    />
                </td>
                <td class="col text-center">
                    <span v-if="application.negotiateAdditionalFee && application.additionalFee">
                        <span :class="strikeThrough(application.negotiationStatus == NegotiationStatusEnum.ACCEPTED)">{{ toMYR(application.additionalFee) }}</span>
                        &#8651;
                        <span :class="strikeThrough(application.negotiationStatus == NegotiationStatusEnum.REJECTED)">{{ toMYR(application.negotiateAdditionalFee) }}</span>
                    </span>
                    <span v-else>
                        {{ application.additionalFee ? toMYR(application.additionalFee) : 'N/A' }}
                    </span>
                    <div>{{ application.additionalFeeReason ? `(${ application.additionalFeeReason })` : '' }}</div>
                </td>
                <td>{{ formatDate(application.createdAt, 'DD-MMM-YYYY hh:mm:ss A') }}</td>
                <td>{{ formatDate(application.cancelledAt, 'DD-MMM-YYYY hh:mm:ss A') }}</td>
                <td>{{ application.cancelReason }}</td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
                <td colspan="8" class="text-center">No data</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup lang="ts">

import { NegotiationStatusEnum } from '@/enums/NegotiationStatusEnum'
import SessionCarerApplicationStatus from '@/components/SessionCarerApplication/SessionCarerApplicationStatus.vue'
import UserNameId from '@/components/UserNameId.vue'
import { toMYR } from '@/composable/useCurrency'
import { formatDate } from '@/composable/useDate'
import { RoleNameEnum } from '@/enums/RoleEnum'
import { useSessionCarerApplicationStore } from '@/stores/sessionCarerApplication'
import { storeToRefs } from 'pinia'

const store = useSessionCarerApplicationStore()
const {fetchCarerApplications, strikeThrough} = store
const {cancelledCarerApplications} = storeToRefs(store)
</script>