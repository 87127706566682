<template>
    <div class="card">
        <div class="card-body">
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Cohort Name</label>
                    <input class="form-control" type="text" v-model="inputFilter.name">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Cohort Status</label>
                    <vue-select
                        :options="[{key:true,label:'Completed'},{key: false,label:'Ongoing'}]" :reduce="(type: KeyLabel) => type.key"
                        class="text-capitalize" label="label" v-model="inputFilter.isCompleted"
                        @option:selected="fetchCohorts"
                    />
                </div>
                <div class="col-12 col-md">
                    <label for="onboardedStartDate" class="form-label">Cohort Start Date</label>
                    <div class="input-group">
                        <input type="text" id="onboardedStartDate" class="form-control bg-white" :value="startDateDisplay" readonly>
                        <span class="input-group-text"><icon name="calendar"/></span>
                    </div>
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetchCohorts"
                        :is-loading="isFetchingCohort"></button-filter>
                </div>
            </div>
            <div>
                <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Cohort</th>
                                <th class="text-center">Current Week Number</th>
                                <th class="text-center">Started At</th>
                                <th class="text-center">Completed At</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="cohorts.length > 0">
                            <tr v-for="cohort in cohorts" :key="cohort.id">
                                <td>{{ cohort.name }}</td>
                                <td class="text-center">{{ cohort.weekNumber === 0 ? '-' : cohort.weekNumber }}</td>
                                <td class="text-center">{{ cohort.startedAt ? formatDate(cohort.startedAt , 'DD-MMM-YYYY') :'-' }}</td>
                                <td class="text-center">{{ cohort.completedAt ? formatDate(cohort.completedAt  , 'DD-MMM-YYYY') :'-' }}</td>
                                <td>
                                    <div class="btn-group">
                                        <button class="btn btn-icon btn-primary" @click="showCohort(cohort.id)">
                                            <icon name="eye" />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="6" class="text-center">No data</td>
                            </tr>
                        </tbody>
                        <overlay-spinner :is-showing="isFetchingCohort" />
                    </table>
                </div>
                <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import useMetaPage from '@/composable/useMetaPage'
import Cohort from '@/types/Cohort'
import { ref, watch, onMounted, computed } from 'vue'
import router from '@/router'
import CohortService from '@/services/CohortService'
import { Core, easepick } from '@easepick/core'
import { formatDate } from '@/composable/useDate'
import KeyLabel from '@/types/KeyLabel'

const DEFAULT_DATE_FORMAT = 'DD-MMM-YYYY'

const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()

const cohorts = ref<Array<Cohort>>([])

const inputFilter = ref({
    name: null,
    isCompleted: null,
    startDate:null
})

const isFetchingCohort = ref<boolean>(false)
const startDateDisplay = computed(() => inputFilter.value.startDate ? formatDate(inputFilter.value.startDate, DEFAULT_DATE_FORMAT) : '')

onMounted(() => {
    const ampPlugin: object = {
        AmpPlugin: {
            dropdown: {
                months: true,
                years: true,
                minYear: 2020,
                maxYear: (new Date().getFullYear())
            },
            darkMode: false,
        }
    }

    new easepick.create({
        element: <any>document.getElementById('onboardedStartDate'),
        format: 'YYYY-MM-DD',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
        ],
        zIndex: 10,
        setup: (picker: Core) => {
            picker.on('select', (e: any) => {
                inputFilter.value.startDate = e.detail.date.format('YYYY-MM-DD')
                picker.hide()
                // fetchUser()
            })
        },
        plugins: [ampPlugin],
        ...ampPlugin
    })
})



const fetchCohorts = () => {
    isFetchingCohort.value = true

    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    } 

    CohortService.index(query).then(({ data: { data, meta } }) => {
        cohorts.value = data
        updateMetaPage(meta)
    }).finally(() => {
        isFetchingCohort.value = false
    })
}

const showCohort = (id:number)=> {
     router.push({name: 'notification-cohort-show', params: {'cohortId':id}})
}


const resetFilter = () => {
    inputFilter.value.name = null
    inputFilter.value.isCompleted = null
    inputFilter.value.startDate = null

    fetchCohorts()
}

fetchCohorts()

watch(
    () => inputFilter.value.isCompleted === null,
    () => fetchCohorts()
)

watch(
    () => metaPageTriggered.value,
    () => fetchCohorts()
)
</script>