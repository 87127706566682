<template>
    <div class="card">
        <div class="card-body">
            <div class="d-flex">
                <div class="ms-auto">
                    <a class="btn btn-primary float-right" data-bs-toggle="modal" data-bs-target="#modal-add-cohort-notification" aria-label="Add Cohort Notification">
                        <icon name="plus" />
                        Add Notification
                    </a>
                </div>
            </div>
            <div class="row mb-3 align-items-end gap-3 gap-md-0">
                <div class="col-12 col-md">
                    <label class="form-label">Notification Week</label>
                    <input class="form-control" type="text" v-model="inputFilter.weekNumber">
                </div>
                <div class="col-12 col-md">
                    <label class="form-label">Notification Day</label>
                    <vue-select
                        :options="[{key:'Monday',label:'Monday'},{key:'Tuesday',label:'Tuesday'},{key:'Wednesday',label:'Wednesday'},{key:'Thursday',label:'Thursday'},{key:'Friday',label:'Friday'},{key:'Saturday',label:'Saturday'},{key:'Sunday',label:'Sunday'}]" :reduce="(type: KeyLabel) => type.key"
                        class="text-capitalize" label="label" v-model="inputFilter.dayOfWeek"
                        @option:selected="fetchCohorts"
                    />
                </div>
                <div class="col-12 col-md-auto ms-auto">
                    <button-filter @reset="resetFilter" @filter="fetchCohorts"
                        :is-loading="isFetchingCohortNotifications"></button-filter>
                </div>
            </div>
            <div>
                <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" :is-showing-per-page="true" />
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Weeks</th>
                                <th>Day</th>
                                <th>Time</th>
                                <th>Title</th>
                                <th>Preview</th>
                                <th>Message</th>
                                <th>Created At</th>
                                <th>Action</th> 
                            </tr>
                        </thead>
                        <tbody v-if="cohortNotifications.length > 0">
                            <tr v-for="cohort in cohortNotifications" :key="cohort.id">
                                <td>{{ cohort.weekNumber }}</td>
                                <td>{{ cohort.dayOfWeek }}</td>
                                <td>{{ convertTo12HourFormat(cohort.timeOfDay)}}</td>
                                <td>{{ cohort.title }}</td>
                                <td>{{ cohort.preview }}</td>
                                <td>{{ limitStringLength(cohort.message,20) }}</td>
                                <td>{{ cohort.createdAt ? formatDate(cohort.createdAt , 'DD-MMM-YYYY hh:mm A') :'-' }}</td>
                                <td class="text-end">
                                    <div class="btn-group">
                                        <button class="btn btn-icon btn-primary" data-bs-toggle="modal" :data-bs-target="`#modal-edit-cohort-notification-${cohort.id}`" aria-label="Edit notification">
                                            <icon name="edit" />
                                        </button>
                                        <button class="btn btn-icon btn-danger" @click.prevent="deleteCohortNotification(cohort)">
                                            <icon name="trash" />
                                        </button>
                                    </div>
                                </td>
                                <cohort-notification-form :modal-id="`modal-edit-cohort-notification-${cohort.id}`" mode="update" :cohort-notification="cohort" @updated-or-created="fetchCohorts"/>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="8" class="text-center">No data</td>
                            </tr>
                        </tbody>
                        <overlay-spinner :is-showing="isFetchingCohortNotifications" />
                    </table>
                </div>
                <table-pagination :meta-page="metaPage" @prev-page="prevPage" @next-page="nextPage" @goto-page="gotoPage" @per-page-updated="updatePerPage" />
            </div>
        </div>
    </div>
    <cohort-notification-form modal-id="modal-add-cohort-notification" @updated-or-created="fetchCohorts"/>
</template>

<script setup lang="ts">
import useMetaPage from '@/composable/useMetaPage'
import NotificationCohort from '@/types/NotificationCohort'
import { ref, watch, onMounted, computed } from 'vue'
import router from '@/router'
import { formatDate, convertTo12HourFormat } from '@/composable/useDate'
import {limitStringLength} from '@/composable/useGeneral'
import KeyLabel from '@/types/KeyLabel'
import CohortNotificationService from '@/services/CohortNotificationService'
import { useNotificationsStore } from '@/stores/notifications'
import CohortNotificationForm from '@/pages/notifications/cohorts/CohortNotificationForm.vue'

const {
    metaPage,
    metaPageTriggered,
    updateMetaPage,
    updatePerPage,
    prevPage,
    nextPage,
    gotoPage,
} = useMetaPage()

const { addToastNotification } = useNotificationsStore()

const cohortNotifications = ref<Array<NotificationCohort>>([])

const inputFilter = ref({
    weekNumber: null,
    dayOfWeek: null,
})

const isFetchingCohortNotifications = ref<boolean>(false)

onMounted(() => {
})



const fetchCohorts = () => {
    isFetchingCohortNotifications.value = true

    const query = {
        ...{
            perPage: metaPage.value.perPage,
            page: metaPage.value.currentPage,
        },
        ...inputFilter.value,
    } 

    CohortNotificationService.index(query).then(({ data: { data, meta } }) => {
        cohortNotifications.value = data
        updateMetaPage(meta)
    }).finally(() => {
        isFetchingCohortNotifications.value = false
    })
}

const showCohort = (id:number)=> {
     router.push({name: 'notification-cohort-show', params: {'cohortId':id}})
}

const deleteCohortNotification = (notification: NotificationCohort) => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete ${notification.title} notification?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (result.isConfirmed) {
            CohortNotificationService.destroy(notification.id).then(() => {
                addToastNotification({
                    message: `${notification.title} deleted`,
                    type: 'success',
                })
                fetchCohorts();
            }).catch(() => {
                addToastNotification({
                    message: 'Internal server error. Please contact tech team.',
                    type: 'danger',
                })
            })
        }
    })
}


const resetFilter = () => {
    inputFilter.value.dayOfWeek = null
    inputFilter.value.weekNumber = null

    fetchCohorts()
}

fetchCohorts()

watch(
    () => inputFilter.value.dayOfWeek === null,
    () => fetchCohorts()
)

watch(
    () => metaPageTriggered.value,
    () => fetchCohorts()
)
</script>