<template>
    <!-- Original Details -->
    <div class="card mt-3">
        <div class="card-body">
            <h3 class="fw-bold mb-3">Original Address</h3>
            <p class="fw-bold">{{ session.address?.name }}</p>
            <p>{{ originalFullAddress }}</p>
            <p v-if="session.address?.remarks">Remarks: {{ session.address?.remarks }}</p>
        </div>
    </div>

    <hr>

    <div class="mt-3">
        <div class="address-container">
            <!-- Select Address -->
            <div class="address-list">
                <div class="d-flex justify-content-between align-items-center">
                    <h3>Select new address</h3>
                    <button @click.prevent="toggleIsCreatingAddress" class="btn btn-icon">
                        <icon :name="isCreatingAddress ? 'address-book' : 'plus'" class="text-cyan" />
                    </button>
                </div>

                <ul class="list-unstyled mt-3">
                    <li v-for="address in addressOptions" :key="`option-address-${address.id}`" class="py-2">
                        <div class="card py-1" :class="{ 'border border-primary': selectedAddressId === address.id }"
                            @click="selectAddress(address.id)">
                            <div class="card-body px-0">
                                <div class="d-flex px-3">
                                    <div class="w-75">
                                        <p class="fw-bold">{{ address.name }}</p>
                                        <p>{{ address.fullAddress }}</p>
                                        <p v-if="address.remarks">Remarks: {{ address.remarks }}</p>
                                    </div>
                                    <div v-if="address.isPrimary" class="col-1 ms-auto">
                                        <span class="badge btn-outline-primary float-end">Primary</span>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end px-3" v-if="selectedAddressId === address.id">
                                    <button @click.prevent="confirmChange" class="btn btn-primary btn-confirm">
                                        CONFIRM CHANGE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- Add New Address -->
            <div v-if="isCreatingAddress" class="create-address-form">
                <h3>Add new address</h3>
                <CreateAddressForm :user-id="session.customerUserId" @success="handleNewAddressCreated" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { getFullAddress } from '@/composable/useSession'
import { useResourcesStore } from '@/stores/resources'
import Address from '@/types/Address'
import Session from '@/types/Session'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import CreateAddressForm from '@/components/Address/CreateAddressForm.vue'
import SessionService from '@/services/SessionService'
import { AxiosError } from 'axios'
import { useNotificationsStore } from '@/stores/notifications'

const { addToastNotification } = useNotificationsStore()

const emit = defineEmits(['success'])

const props = defineProps({
    session: { type: Object as () => Session, required: true },
    customerAddresses: { type: Array as () => Address[], required: true },
})

const isCreatingAddress = ref(false)
const toggleIsCreatingAddress = () => isCreatingAddress.value = !isCreatingAddress.value

const selectedAddressId = ref<number | null>(null)
const selectAddress = (addressId: number) => selectedAddressId.value = addressId

const { addressStates, postcodes } = storeToRefs(useResourcesStore())

const addressOptions = computed(() => {
    if (!props.customerAddresses) {
        return []
    }

    return props.customerAddresses.map((address: Address) => ({
        id: address.id,
        name: address.name,
        fullAddress: getFullAddress(address, addressStates.value, postcodes.value),
        remarks: address.remarks,
        isPrimary: address.isPrimary
    }))
})

const originalFullAddress = computed(() => getFullAddress(props.session.address, addressStates.value, postcodes.value))

const isSubmitting = ref(false)
const setIsSubmitting = (value = true) => isSubmitting.value = value

const errors = ref<any>({
    address_id: null
})

const confirmChange = async () => {
    if (!selectedAddressId.value) return

    setIsSubmitting(true)

    try {
        const data = {
            address_id: selectedAddressId.value
        }
        await SessionService.update(props.session.id, data)

        addToastNotification({
            message: `Address for Session ${props.session?.no} has been updated`,
            type: 'success',
        })

        emit('success')
    } catch (error) {
        if (error instanceof AxiosError) {
            if (error.response?.status === 422) {
                const responseData = error.response.data as { errors?: Record<string, string[]> }
                if (responseData.errors) {
                    Object.entries(responseData.errors).forEach(([field, messages]) => {
                        errors.value[field] = messages
                    })
                }
            } else {
                const errorMessage = error.response?.data?.message || 'An unexpected error occurred'
                addToastNotification({
                    message: errorMessage,
                    type: 'danger'
                })
            }
        } else {
            addToastNotification({
                message: 'An unexpected error occurred',
                type: 'danger'
            })
        }
    } finally {
        setIsSubmitting(false)
    }
}

</script>

<style lang="css" scoped>
.btn-confirm {
    font-size: 0.8rem;
}

/* Mobile-specific styles */
@media (max-width: 767px) {
    .btn-confirm {
        font-size: 0.7rem;
    }
}

.address-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.address-list {
    flex: 1;
    height: 50dvh;
    overflow-y: auto;
    padding: 1rem;
}

.create-address-form {
    flex: 1;
    height: 50dvh;
    overflow-y: auto;
    padding: 1rem;
    background-color: #F2EBDC;
    border-radius: 0.25rem;
}

@media (max-width: 768px) {
    .address-container {
        flex-direction: column;
    }

    .address-list,
    .create-address-form {
        width: 100%;
        height: auto;
        max-height: 50dvh;
    }

    .create-address-form {
        order: 2;
    }
}
</style>