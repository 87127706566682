import httpClientV2 from '@/services/HttpClientV2'

export default {
    index() {
        return httpClientV2.get(`backoffice/platform-content-types`)
    },

    store(data: object = {}) {
        return httpClientV2.post('backoffice/platform-content-types', data)
    },

    update(id: number, data: object = {}) {
        return httpClientV2.patch(`backoffice/platform-content-types/${id}`, data)
    },

    updateRows(data: object = {}) {
        return httpClientV2.patch('backoffice/platform-content-types/update-rows', data)
    },

    destroy(id: number) {
        return httpClientV2.delete(`backoffice/platform-content-types/${id}`)
    },
}