import { getQueryString } from '@/composable/http'
import httpClientV2 from '@/services/HttpClientV2'

const endpoint = '/backoffice/cohort-notifications'

export default {
    index(query: object = {}) {
        return httpClientV2.get(`${endpoint}?${getQueryString(query)}`)
    },
    store(data: object = {}) {
        return httpClientV2.post(`${endpoint}`, data)
    },
    show(id:number) {
        return httpClientV2.get(`${endpoint}/${id}`)
    },
    update(id: number, data: object = {}) {
        return httpClientV2.patch(`${endpoint}/${id}`, data)
    },
    destroy(id: number) {
        return httpClientV2.delete(`${endpoint}/${id}`)
    }
}