import { getQueryString } from "@/composable/http"
import httpClientV2 from "./HttpClientV2"

const endpoint = 'backoffice/carers/handholdings'

export default {
    index(query: object = {}) {
        return httpClientV2.get(`${endpoint}?${getQueryString(query)}`)
    },
    show(id: number) {
        return httpClientV2.get(`${endpoint}/${id}`)
    },
    statusStats(query: object = {}) {
        return httpClientV2.get(`${endpoint}/status-stats?${getQueryString(query)}`)
    },
    todaySessionStats() {
        return httpClientV2.get(`${endpoint}/today-session-stats`)
    },
}