<template>
    <div class="text-end mb-3">
        <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#content-media-presets-form-modal"
            @click.prevent="contentMediaPreset = undefined">
            Add Media Preset
        </button>
    </div>
    <div class="card">
        <overlay-spinner :is-showing="isLoading"></overlay-spinner>
        <div class="card-body">
            <ul v-if="contentMediaPresets.length" class="list-unstyled border rounded">
                <li class="p-3 d-flex align-items-start justify-content-between"
                    :class="[{ 'border-bottom': index !== contentMediaPresets.length - 1 }]"
                    v-for="(mediaPreset, index) in contentMediaPresets" :key="mediaPreset.id">
                    <div class="d-flex align-items-center w-50">
                        {{ mediaPreset.name }}
                    </div>
                    <div class="d-flex align-items-center w-50">
                        {{ `${mediaPreset.preset?.width ?? 0}px X ${mediaPreset.preset?.height ?? 0}px (${mediaPreset.preset?.width_setting} width setting)` }}
                    </div>
                    <div class="d-flex gap-4 align-items-center">
                        <div class="btn-group">
                            <button type="button" class="btn btn-icon" data-bs-toggle="modal"
                                data-bs-target="#content-media-presets-form-modal" @click="setMediaPreset(mediaPreset)">
                                <icon name="pencil" class="text-primary" />
                            </button>
                            <button class="btn btn-icon" @click.prevent="deleteMediaPreset(mediaPreset.id, mediaPreset.name)">
                                <icon name="trash" class="text-danger" />
                            </button>
                        </div>
                    </div>
                </li>
            </ul>
            <span v-else>No data</span>
        </div>
    </div>

    <ContentMediaPresetsForm :media-preset="contentMediaPreset" @save="fetchContentMediaPresets" />
</template>

<script setup lang="ts">
import ContentMediaPresetsForm from '@/pages/marketings/content-media-presets/ContentMediaPresetsForm.vue'
import PlatformContentMediaPresetService from '@/services/PlatformContentMediaPresetService'
import { useNotificationsStore } from '@/stores/notifications'
import PlatformContentMediaPreset from '@/types/PlatformContentMediaPreset'
import { ref } from 'vue'

const contentMediaPresets = ref<Array<PlatformContentMediaPreset>>([])
const contentMediaPreset = ref<PlatformContentMediaPreset>()

const emits = defineEmits<{
    (e: 'save'): void
}>()

const { addToastNotification } = useNotificationsStore()

const isLoading = ref<boolean>(false)

const fetchContentMediaPresets = async () => {
    isLoading.value = true

    try {
        const response = await PlatformContentMediaPresetService.index()
        contentMediaPresets.value = response.data.data
    } catch (error) {
        console.log(error)
    } finally {
        isLoading.value = false
    }
}

const setMediaPreset = (mediaPreset: PlatformContentMediaPreset) => contentMediaPreset.value = mediaPreset

const deleteMediaPreset = (id: number, name: string) => {
    window.Swal.fire({
        title: 'Confirmation',
        text: `Are you sure you want to delete media preset ${name}?`,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
    }).then((result: any) => {
        if (!result.isConfirmed) {
            return
        }

        isLoading.value = true

        PlatformContentMediaPresetService.destroy(id)
            .then(() => {
                addToastNotification({
                    message: `Media preset ${name} deleted`,
                    type: 'success'
                })

                fetchContentMediaPresets()
            })
            .catch(() => {
                addToastNotification({
                    message: `Internal server error. Please contact Tech if error persists.`,
                    type: 'danger'
                })

                isLoading.value = false
            })

    })
}

fetchContentMediaPresets()

</script>