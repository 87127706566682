<template>
    <template v-if="sessionEvent">
        <div class="w-10-rem">
            <button @click="openModal()" class="btn btn-sm btn-green normal-whitespace">
                {{ label }}
            </button>
        </div>
        <Teleport to="body">
            <kc-modal :modal-id="modalId" :modal-title="`Monitoring Session ${sessionNo}`" modal-size="xl">

                <div class=" w-100">
                    <ul class="nav nav-tabs card-header-tabs " data-bs-toggle="tabs">
                        <li class="nav-item">
                            <a :href="`#tabs-session-events-${sessionId}`" class="nav-link active" data-bs-toggle="tab">
                                Session Events
                            </a>
                        </li>
                        <li class="nav-item">
                            <a :href="`#tabs-carer-logs-${sessionId}`" class="nav-link" data-bs-toggle="tab">
                                Carer Logs
                            </a>
                        </li>
                    </ul>
                    <div class="tab-content mt-4">
                        <div class="tab-pane active show  pt-4 pb-4 bg-white" style="height: 70dvh; overflow-y: scroll;"
                            :id="`tabs-session-events-${sessionId}`">
                            <SessionEventsTimeline :current-event="sessionEvent" :session-id="sessionId"
                                timeline-only />
                        </div>
                        <div class="tab-pane" :id="`tabs-carer-logs-${sessionId}`">
                            <SessionCarerLogs :carer-logs="carerLogs" :includeImage="includeImage" />
                        </div>
                    </div>
                </div>
            </kc-modal>
        </Teleport>
    </template>
    <template v-else>
        <button class="btn btn-sm btn-secondary w-100" disabled>
            Not Started
        </button>
    </template>
</template>

<script setup lang="ts">

import { computed, onMounted } from 'vue'
import SessionEvent from '@/types/SessionEvent'
import Modal from 'bootstrap/js/dist/modal'
import { storeToRefs } from 'pinia'
import { useResourcesStore } from '@/stores/resources'
import SessionCarerLog from '@/types/SessionCarerLog'
import SessionCarerLogs from '@/pages/sessions/components/SessionCarerLogs.vue'
import SessionEventsTimeline from '@/components/SessionEventsTimeline/SessionEventsTimeline.vue'

const props = defineProps({
    sessionId: { type: Number, required: true },
    sessionNo: { type: String, required: true },
    sessionEvent: { type: Object as () => SessionEvent },
    carerLogs: { type: Array as () => Array<SessionCarerLog> },
    includeImage: { type: Boolean, default: true }
})

let modalElement: HTMLElement | null = null
let modal: Modal | null = null

const { sessionEventTypes } = storeToRefs(useResourcesStore())

const modalId = computed<string>(() => `session-${props.sessionId}-event-logs-modal`)

const label = computed(() => {
    const status = sessionEventTypes.value.find(type => type.id === props.sessionEvent?.sessionEventTypeId)
    return status?.adminLabel ?? 'N/A'
})

const openModal = (open = true) => open ? modal?.show() : modal?.hide()

onMounted(() => {
    modalElement = document.getElementById(modalId.value)

    if (!modalElement) {
        return
    }

    modal = new Modal(modalElement)
})

</script>

<style scoped>
.w-10-rem {
    width: 10rem;
}

.normal-whitespace {
    white-space: normal;
}
</style>
