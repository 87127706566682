<template>
    <div class="page">
        <header class="navbar navbar-expand-md navbar-light d-print-none">
            <div class="container d-flex justify-content-between w-full" id="navbar-container">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3 mr-auto">
                    <router-link :to="{ name: 'home-page' }">
                        <img src="/kiddocare-logo-small.png" width="110" height="32" alt="Kiddocare"
                            class="navbar-brand-image">
                    </router-link>
                    <span class="fw-normal fs-4 ms-2">Version {{ generalStore.backofficeVersion }}</span>
                </h1>
                <div class="navbar-nav flex-row order-md-last">
                    <web-notification-container />
                    <div class="nav-item dropdown">
                        <a href="#" class="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown"
                            aria-label="Open user menu">
                            <span class="avatar avatar-sm"></span>
                            <div class="d-none d-xl-block ps-2">
                                <div>{{ generalStore.user.name }}</div>
                                <div class="mt-1 small text-muted">
                                    {{ generalStore.user?.roles ? generalStore.user?.roles[0]?.name : null }}</div>
                            </div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                            <router-link :to="{ name: 'profile-page' }" class="dropdown-item">Settings</router-link>
                            <a @click="logout" class="dropdown-item cursor-pointer">Logout</a>
                        </div>
                    </div>
                </div>
            </div>
        </header>

        <navbar />

        <div class="page-wrapper">
            <div class="container" v-if="$route.meta.title">
                <div class="page-header d-print-none">
                    <div class="row g-2 align-items-center">
                        <div class="col">
                            <a href="#" @click.prevent="$router.go(-1)" role="button" class="mb-2 d-inline-block"
                                v-if="showBackButton($route.meta.title)">
                                <icon name="chevron-left" />
                                <span>Back</span>
                            </a>
                            <h2 class="page-title">
                                {{ $route.meta.title }}
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page-body">
                <div class="container" id="page-container">
                    <router-view />
                    <!--                    <chat-widget v-if="showChatWidget"></chat-widget>-->
                </div>
            </div>
        </div>

        <ToastNotificationContainer :toast-notifications="toastNotifications" type="general" position="top" />
        <ToastNotificationContainer :toast-notifications="chatNotifications" type="chat" position="top-end" />
    </div>
</template>

<script setup lang="ts">
import Navbar from '@/components/Navbar.vue'
import ToastNotificationContainer from '@/components/ToastNotificationContainer.vue'
import WebNotificationContainer from '@/components/WebNotificationContainer.vue'
import { now } from '@/composable/useDate'
import router from '@/router'
import AuthService from '@/services/AuthService'
import CdnService from '@/services/CdnService'
import GeneralService from '@/services/GeneralService'
import { useGeneralStore } from '@/stores/general'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import { useSendbirdStore } from '@/stores/sendbird'
import WebNotificationData from '@/types/WebNotificationData'
import SendbirdChat, { SendbirdChatParams, SendbirdError } from '@sendbird/chat'
import { GroupChannelModule, SendbirdGroupChat } from '@sendbird/chat/groupChannel'
import * as Sentry from '@sentry/vue'
import Echo from 'laravel-echo'
import { storeToRefs } from 'pinia'
import Pusher from 'pusher-js'
import { onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const showChatWidget = ref<Boolean>()
const route = useRoute()
const generalStore = useGeneralStore()
const { sendbird, isSendbirdConnected, errors: sendbirdErrors } = storeToRefs(useSendbirdStore())

const notificationStore = useNotificationsStore()
const { addWebNotification, addToastNotification } = notificationStore
const { toastNotifications, chatNotifications } = storeToRefs(notificationStore)

const {
    races,
    banks,
    religions,
    nationalities,
    maritalStatuses,
    occupations,
    languages,
    addressStates,
    carerStates,
    carerTypes,
    dependentTypes,
    carerApplicationType,
    bookingStatuses,
    jobStatuses,
    sessionStatuses,
    sessionTypes,
    sessionServiceTypes,
    sessionEventsTimeline,
    sessionRateAmounts,
    sessionRateAmountsOrderable,
    sessionEventTypes,
    sessionDependentRoutineTypes,
    sessionCarerApplicationStatuses,
    carerApplicationStatuses,
    carerPayoutScheduleTypes,
    carerReviewQuestions,
    defaultCheckinData,
    defaultCheckoutData,
    postcodes,
    invoiceItemTypes,
    paymentMethods,
    paymentProviders,
    institutions,
    carerHighestEducation,
    carerEducationStatuses,
    carerWorkStatuses,
    carerEmploymentStatuses,
    carerBreadwinnerStatuses,
    carerIncomeCategories,
    carerOnboardingStatuses,
    platformContentMediaPresets,
    rateHours,
    rateChildren,
    rateExtras,
    announcementStatuses,
    announcementRecipients,
    carerAssessmentTypes,
    carerIntroQuizzes,
    carerPsychometricQuestions,
    carerIntroQuizStatuses,
    carerPsychometricTypes,
    carerBctTrainingMethods,
    carerBctAssessmentMethods,
    carerSopTrainingMethods,
    carerTrainers,
    deactivateAccountOptions,
    paymentStatuses,
    sessionCancelReasonOptions,
    platformContentTypes,
    orderStatuses,
    orderTypes,
    carerPayoutTypes,
    carerPayoutStatuses,
    carerPayoutSchedules,
    sessionSettingsDetails
} = storeToRefs(useResourcesStore())

const logout = async () => {
    try {
        await AuthService.logout()
        await sendbird.value.disconnect()
        isSendbirdConnected.value = false
        Sentry.setUser(null)
    } catch (ex) {

    }

    localStorage.removeItem('resourceHash')
    localStorage.removeItem('resourceData')
    await router.push({ name: 'login-page' })
}

const showBackButton = (title: string) => {
    let keywords = ['Create', 'Edit']
    return keywords.find(keyword => title.includes(keyword))
}

const initResourcesData = (data: any) => {
    races.value = data.races
    banks.value = data.banks
    languages.value = data.languages
    religions.value = data.religions
    addressStates.value = data.states
    nationalities.value = data.nationalities
    maritalStatuses.value = data.maritalStatuses
    occupations.value = data.occupations
    carerStates.value = data.carerStatuses
    carerTypes.value = data.carerTypes
    dependentTypes.value = data.dependentTypes
    carerApplicationType.value = data.carerApplicationType
    carerReviewQuestions.value = data.carerReviewQuestions
    defaultCheckinData.value = data.defaultCheckinData
    defaultCheckoutData.value = data.defaultCheckoutData
    jobStatuses.value = data.jobStatuses
    sessionCarerApplicationStatuses.value = data.sessionCarerApplicationStatuses
    sessionStatuses.value = data.sessionStatuses
    sessionTypes.value = data.sessionTypes
    sessionServiceTypes.value = data.sessionServiceTypes
    sessionEventsTimeline.value = data.sessionEventsTimeline
    sessionRateAmounts.value = data.sessionRateAmounts
    sessionRateAmountsOrderable.value = data.sessionRateAmountsOrderable
    sessionEventTypes.value = data.sessionEventTypes
    sessionDependentRoutineTypes.value = data.sessionDependentRoutineTypes
    sessionCarerApplicationStatuses.value = data.sessionCarerApplicationStatuses
    bookingStatuses.value = data.bookingStatuses
    carerPayoutScheduleTypes.value = data.carerPayoutScheduleTypes
    carerApplicationStatuses.value = data.carerApplicationStatuses
    invoiceItemTypes.value = data.invoiceItemTypes
    paymentMethods.value = data.paymentMethods
    paymentProviders.value = data.paymentProviders
    postcodes.value = data.postcodes
    institutions.value = data.institutions
    carerHighestEducation.value = data.carerHighestEducation
    carerEducationStatuses.value = data.carerEducationStatuses
    carerWorkStatuses.value = data.carerWorkStatuses
    carerEmploymentStatuses.value = data.carerEmploymentStatuses
    carerBreadwinnerStatuses.value = data.carerBreadwinnerStatuses
    carerIncomeCategories.value = data.carerIncomeCategories
    carerOnboardingStatuses.value = data.carerOnboardingStatuses
    platformContentMediaPresets.value = data.platformContentMediaPresets
    rateHours.value = data.rateHours
    rateChildren.value = data.rateChildren
    rateExtras.value = data.rateExtras
    announcementStatuses.value = data.announcementStatuses
    announcementRecipients.value = data.announcementRecipients
    carerAssessmentTypes.value = data.carerAssessmentTypes
    carerIntroQuizzes.value = data.carerIntroQuizzes
    carerPsychometricQuestions.value = data.carerPsychometricQuestions
    carerIntroQuizStatuses.value = data.carerIntroQuizStatuses
    carerPsychometricTypes.value = data.carerPsychometricTypes
    carerBctTrainingMethods.value = data.carerBctTrainingMethods
    carerBctAssessmentMethods.value = data.carerBctAssessmentMethods
    carerSopTrainingMethods.value = data.carerSopTrainingMethods
    carerTrainers.value = data.carerTrainers
    deactivateAccountOptions.value = data.deactivateAccountOptions
    sessionCancelReasonOptions.value = data.sessionCancelReasonOptions
    paymentStatuses.value = data.paymentStatuses
    platformContentTypes.value = data.platformContentTypes
    orderStatuses.value = data.orderStatuses
    orderTypes.value = data.orderTypes
    carerPayoutTypes.value = data.carerPayoutTypes
    carerPayoutStatuses.value = data.carerPayoutStatuses
    carerPayoutSchedules.value = data.carerPayoutSchedules
    sessionSettingsDetails.value = data.sessionSettingsDetails
}

const initSendbird = async () => {
    const sendbirdParams: SendbirdChatParams<[GroupChannelModule]> = {
        appId: import.meta.env.VITE_SENDBIRD_APP_ID,
        localCacheEnabled: true,
        modules: [
            new GroupChannelModule()
        ]
    }

    try {
        sendbird.value = await SendbirdChat.init(sendbirdParams) as SendbirdGroupChat
        await sendbird.value.connect(generalStore.user.id.toString(), generalStore.sendbird.token)
        isSendbirdConnected.value = true
    } catch (e) {

        const error = e as SendbirdError

        addToastNotification({
            type: 'danger',
            title: 'Sendbird Error',
            message: `[${error.code} - ${error.name}] ${error.message}`
        })

        sendbirdErrors.value?.push(`[${error.code} - ${error.name}] ${error.message}`)
    }
}

onMounted(() => {
    setInterval(() => {
        AuthService.ping().catch(() => {
            router.push({ name: 'login-page' })
        })
    }, 900000)
})

window.Pusher = Pusher

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_WEB_SOCKET_KEY ?? null,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? null,
    forceTLS: false,
    wsHost: import.meta.env.VITE_WEB_SOCKET_HOST ?? null,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authorizer: (channel: any, options: any) => {
        return {
            authorize: (socketId: string, callback: any) => {
                AuthService.broadcast({
                    socket_id: socketId,
                    channel_name: channel.name
                })
                    .then((response) => {
                        callback(false, response.data)
                    })
                    .catch((error) => {
                        callback(true, error)
                    })
            }
        }
    }
})
const initEcho = () => {
    window.Pusher = Pusher
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_WEB_SOCKET_KEY ?? null,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? null,
        forceTLS: false,
        wsHost: import.meta.env.VITE_WEB_SOCKET_HOST ?? null,
        encrypted: true,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        authorizer: (channel: any, options: any) => {
            return {
                authorize: (socketId: string, callback: any) => {
                    AuthService.broadcast({
                        socket_id: socketId,
                        channel_name: channel.name
                    })
                        .then((response) => {
                            callback(false, response.data)
                        })
                        .catch((error) => {
                            callback(true, error)
                        })
                }
            }
        }
    })

    window.Echo.private(`users.${generalStore.user.id}`)
        .notification((data: any) => {
            addWebNotification({
                id: data.id as string,
                data: data as WebNotificationData,
                readAt: null,
                createdAt: now()
            })

            addToastNotification({
                id: 0,
                title: data.title,
                message: data.body,
                type: data.notificationType,
                actionType: data.actionType,
                actionUrl: data.actionUrl,
                actionText: data.actionText
            })
        })
}

const initSentry = () => {
    Sentry.setUser({
        id: generalStore.user.id.toString(),
        email: generalStore.user.email
    })
}

const init = async () => {
    const responseInit = await GeneralService.init()

    generalStore.user = responseInit.data.user

    generalStore.sendbird = responseInit.data.sendbird
    generalStore.telescopeUrl = responseInit.data.telescopeUrl
    generalStore.horizonUrl = responseInit.data.horizonUrl

    const resourceDataString = localStorage.getItem('resourceData')
    let resourceData: null

    if (responseInit.data.resourceHash !== generalStore.resourceHash || resourceDataString == null) {
        const response = await CdnService.getResources(responseInit.data.resourceHash)
        resourceData = response.data

        localStorage.setItem('resourceHash', responseInit.data.resourceHash)
        localStorage.setItem('resourceData', JSON.stringify(resourceData))
    } else {
        resourceData = JSON.parse(resourceDataString)
    }

    initResourcesData(resourceData)
    initEcho()
    initSentry()
    await initSendbird()

    generalStore.isInitCompleted = true
}

watch(
    () => route.name,
    (val) => {
        showChatWidget.value = val !== 'chat-index'
    }, { immediate: true }
)

onMounted(() => {
    setInterval(() => {
        AuthService.ping().catch(() => {
            router.push({ name: 'login-page' })
        })
    }, 900000)
})

init()

</script>
