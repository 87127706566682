<template>
    <div class="input-group">
        <input type="text" :id="id" class="form-control bg-white" :class="{ 'is-invalid': invalid }" :value="modelValue"
            readonly>
        <span class="input-group-text">
            <icon name="calendar" />
        </span>
    </div>
</template>

<script setup lang="ts">
import {  onMounted, onUnmounted, watch } from 'vue'
import { easepick } from '@easepick/core'
import { AmpPlugin } from '@easepick/amp-plugin'
import { TimePlugin } from '@easepick/time-plugin'

const props = defineProps({
    modelValue: {
        type: String as () => string | null,
    },
    id: {
        type: String,
        default: 'datepicker-input'
    },
    invalid: {
        type: Boolean,
        default: false
    },
    minDate: String,
    maxDate: String,
    includeTime: {
        type: Boolean,
        default: true
    }
})

const emit = defineEmits(['update:modelValue','onUpdate'])

let picker: any = null

const initDatePicker = () => {
    const input = document.getElementById(props.id)
    if (!input) {
        console.error('Date input element not found')
        return
    }

    const plugins : any[] = [AmpPlugin]
    if (props.includeTime) {
        plugins.push(TimePlugin)
    }

    picker = new easepick.create({
        element: input,
        format: props.includeTime ? 'DD-MMM-YYYY hh:mm A' : 'DD-MMM-YYYY',
        css: [
            'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.0/dist/index.css',
            ...(props.includeTime ? ['https://cdn.jsdelivr.net/npm/@easepick/time-plugin@1.2.0/dist/index.css'] : [])
        ],
        plugins: plugins,
        TimePlugin: props.includeTime ? { format12: true } : undefined,
        AmpPlugin: {
            resetButton: true,
            darkMode: false, 
        },
        autoApply: false,
        zIndex: 10,
        setup: (picker: any) => {
            picker.on('select', (e: any) => {
                const selectedDate = e.detail.date?.format(props.includeTime ? 'DD-MMM-YYYY hh:mm A' : 'DD-MMM-YYYY');
                emit('update:modelValue', selectedDate)
                emit('onUpdate', selectedDate)
                picker.hide()
            })
        }
    })

    if (props.minDate) {
        picker.setMinDate(props.minDate)
    }
    if (props.maxDate) {
        picker.setMaxDate(props.maxDate)
    }
}

onMounted(() => {
    initDatePicker()
})

onUnmounted(() => {
    if (picker) {
        picker.destroy()
    }
})


watch(() => props.minDate, (newVal) => {
    if (picker && newVal) {
        picker.setMinDate(newVal)
    }
})

watch(() => props.maxDate, (newVal) => {
    if (picker && newVal) {
        picker.setMaxDate(newVal)
    }
})
</script>