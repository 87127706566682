<template>
    <component :is="timelineOnly ? 'div' : BasicCard" title="Events" class="mb-3">
        <ul class="list list-timeline" style="margin-right: auto; margin-left: 0;">
            <li v-for="(item, index) in timeline" :key="`session-event-timeline-item-${index}`">
                <div class="list-timeline-content pt-0">
                    <div class="list-timeline-icon" :class="getIconClass(item)">
                        <icon :name="getIconName(item)" />
                    </div>
                    <div class="card" :class="getCardClass(item)">
                        <div class="card-body">

                            <!-- TITLE -->
                            <h5 class="card-title mb-0">{{ _.startCase(item.title) }}</h5>

                            <!-- SUBTITLE -->
                            <p v-if="getSubtitle(item)">{{ getSubtitle(item) }}</p>

                            <!-- TIMESTAMP -->
                            <div v-if="isCompletedEvent(item) && item.createdAt">
                                <div class=" text-muted" style="font-size: 0.8rem;">
                                    {{ formatDate(item.createdAt, 'DD-MMM-YYYY hh:mm:ss A') }}
                                </div>
                                <div>
                                    <div class="text-muted" style="font-size: 0.8rem;">
                                        Performed by <strong class="text-capitalize">
                                            {{ getPerformedByName(item) }}
                                        </strong>
                                    </div>
                                </div>
                            </div>

                            <!-- EVENT: CARER ON THE WAY -->
                            <template v-if="item.sessionEventTypeId === SessionEventTypeEnum.ID_STATUS_LOCATION_START">
                                <p v-if="item.eta" class="mt-3">ETA: {{ item.eta }}</p>

                                <div v-if="isActiveEvent(item) || currentEvent?.sessionEventTypeId < SessionEventTypeEnum.ID_STATUS_LOCATION_ARRIVED"
                                    class="d-flex justify-content-end border-top pt-3">
                                    <CarerOnTheWayForm :session-id="sessionId" class="mt-3"
                                        :button-label="item.eta ? 'Update' : 'Begin'" />
                                </div>
                            </template>

                            <!-- EVENT: CARER HAS ARRIVED -->
                            <div v-if="item?.sessionEventTypeId === SessionEventTypeEnum.ID_STATUS_LOCATION_ARRIVED && isActiveEvent(item)"
                                class="d-flex justify-content-end">
                                <CarerHasArrivedForm :session-id="sessionId" class="mt-3" />
                            </div>

                            <!-- SECTION: COMPLETED EVENT -->
                            <div v-if="isCompletedEvent(item) && item.imageUrl" class="mt-4">
                                <button @click="toggleImages(index)" class="btn btn-sm btn-outline-secondary mb-2">
                                    {{ isExpanded(index) ? 'Hide Images' : 'Show Images' }}
                                </button>
                                <div v-if="isExpanded(index)" class="image-container">
                                    <img :src="item.imageUrl" class="img-fluid rounded event-image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </component>
</template>


<script setup lang="ts">
import BasicCard from '@/components/BasicCard.vue'
import { formatDate } from '@/composable/useDate'
import { useResourcesStore } from '@/stores/resources'
import SessionEvent from '@/types/SessionEvent'
import SessionEventTimelineItem from '@/types/SessionEventTimelineItem'
import { SessionEventTypeEnum } from '@/types/SessionEventTypeEnum'
import _ from 'lodash'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import CarerOnTheWayForm from '@/components/SessionEventsTimeline/CarerOnTheWayForm.vue'
import CarerHasArrivedForm from '@/components/SessionEventsTimeline/CarerHasArrivedForm.vue'

const props = defineProps({
    currentEvent: { type: Object as () => SessionEvent, default: {} },
    sessionId: { type: Number, required: true },
    timelineOnly: { type: Boolean, default: false }
})


const { sessionEventsTimeline } = storeToRefs(useResourcesStore())

const timeline = computed((): SessionEventTimelineItem[] => {
    const result: SessionEventTimelineItem[] = []

    sessionEventsTimeline.value?.forEach(item => {
        const eventLog = props.currentEvent?.eventLogs?.find(event => event?.sessionEventTypeId === item?.sessionEventTypeId)

        result.push({
            id: item.sessionEventTypeId,
            sessionId: props.sessionId,
            sessionEventTypeId: item.sessionEventTypeId,
            title: item.title,
            subtitles: item.subtitles,
            eta: eventLog?.eta,
            imageUrl: eventLog?.imageUrl ?? '',
            causer: eventLog?.causer,
            isCompleted: !!eventLog,
            createdAt: eventLog?.createdAt?.toString() ?? '',
        })

        // Add In Session filler after Check-In
        if (item.sessionEventTypeId === SessionEventTypeEnum.ID_STATUS_PENDING_CHECKLIST_APPROVAL) {
            result.push({
                title: "In Session",
                isCompleted: false,
            })
        }
    })

    return result
})

const expandedEvents = ref<Set<number>>(new Set())

const toggleImages = (eventIndex: number) => {
    if (expandedEvents.value.has(eventIndex)) {
        expandedEvents.value.delete(eventIndex)
    } else {
        expandedEvents.value.add(eventIndex)
    }
}

const isExpanded = (eventIndex: number) => expandedEvents.value.has(eventIndex)

const getSubtitle = (item: SessionEventTimelineItem) => {
    const { sessionEventTypeId, isCompleted, subtitles } = item
    const currentEventType = props.currentEvent?.sessionEventTypeId

    switch (sessionEventTypeId) {
        case SessionEventTypeEnum.ID_STATUS_LOCATION_START:
        case SessionEventTypeEnum.ID_STATUS_LOCATION_ARRIVED:
            return !isCompleted ? subtitles?.before : null
        case SessionEventTypeEnum.ID_STATUS_PENDING_CHECKLIST_APPROVAL:
        case SessionEventTypeEnum.ID_STATUS_PENDING_REPORT_APPROVAL:
            if (!isCompleted) return subtitles?.before
            if (currentEventType === sessionEventTypeId) return subtitles?.pending
            if (currentEventType === SessionEventTypeEnum.ID_STATUS_REPORT_DECLINED) return subtitles?.declined
            if (currentEventType === SessionEventTypeEnum.ID_STATUS_REPORT_APPROVED) return subtitles?.approved
            break
    }
    return null
}


const isCompletedEvent = (item: SessionEventTimelineItem) => {
    const { sessionEventTypeId, isCompleted } = item
    const currentEventType = props.currentEvent?.sessionEventTypeId

    if (!sessionEventTypeId) {
        return currentEventType >= SessionEventTypeEnum.ID_STATUS_CHECKLIST_APPROVED
    }

    switch (sessionEventTypeId) {
        case SessionEventTypeEnum.ID_STATUS_LOCATION_START:
        case SessionEventTypeEnum.ID_STATUS_LOCATION_ARRIVED:
        case SessionEventTypeEnum.ID_STATUS_COMPLETE:
            return isCompleted
        case SessionEventTypeEnum.ID_STATUS_PENDING_CHECKLIST_APPROVAL:
            return currentEventType >= SessionEventTypeEnum.ID_STATUS_CHECKLIST_APPROVED || isCompleted
        case SessionEventTypeEnum.ID_STATUS_PENDING_REPORT_APPROVAL:
            return currentEventType >= SessionEventTypeEnum.ID_STATUS_REPORT_APPROVED || isCompleted
    }
    return false
}

const isActiveEvent = (item: SessionEventTimelineItem) => {
    const currentEventType = props.currentEvent?.sessionEventTypeId

    // If session event has not started, set Carer On The Way as active
    if (!currentEventType) {
        return item?.sessionEventTypeId === SessionEventTypeEnum.ID_STATUS_LOCATION_START
    }

    if (!item?.sessionEventTypeId && currentEventType === SessionEventTypeEnum.ID_STATUS_CHECKLIST_APPROVED) {
        return true
    }

    const activeEventMap: Record<SessionEventTypeEnum, SessionEventTypeEnum> = {
        [SessionEventTypeEnum.ID_STATUS_LOCATION_START]: SessionEventTypeEnum.ID_STATUS_LOCATION_ARRIVED,
        [SessionEventTypeEnum.ID_STATUS_LOCATION_ARRIVED]: SessionEventTypeEnum.ID_STATUS_PENDING_CHECKLIST_APPROVAL,
        [SessionEventTypeEnum.ID_STATUS_PENDING_CHECKLIST_APPROVAL]: SessionEventTypeEnum.ID_STATUS_PENDING_CHECKLIST_APPROVAL,
        [SessionEventTypeEnum.ID_STATUS_CHECKLIST_DECLINED]: SessionEventTypeEnum.ID_STATUS_PENDING_CHECKLIST_APPROVAL,
        [SessionEventTypeEnum.ID_STATUS_CHECKLIST_APPROVED]: SessionEventTypeEnum.ID_STATUS_PENDING_REPORT_APPROVAL,
        [SessionEventTypeEnum.ID_STATUS_PENDING_REPORT_APPROVAL]: SessionEventTypeEnum.ID_STATUS_PENDING_REPORT_APPROVAL,
        [SessionEventTypeEnum.ID_STATUS_REPORT_DECLINED]: SessionEventTypeEnum.ID_STATUS_PENDING_REPORT_APPROVAL,
        [SessionEventTypeEnum.ID_STATUS_REPORT_APPROVED]: SessionEventTypeEnum.ID_STATUS_COMPLETE,
        [SessionEventTypeEnum.ID_STATUS_COMPLETE]: SessionEventTypeEnum.ID_STATUS_COMPLETE,
    }

    return item.sessionEventTypeId === activeEventMap[currentEventType as keyof typeof activeEventMap]
}

const getIconName = (event: SessionEventTimelineItem) => {
    if (isActiveEvent(event)) {
        return 'arrow-right'
    } else if (isCompletedEvent(event)) {
        return 'circle-check'
    } else {
        return 'loader-2'
    }
}

const getIconClass = (event: SessionEventTimelineItem) => {
    if (isActiveEvent(event)) {
        return 'bg-primary'
    } else if (isCompletedEvent(event)) {
        return 'bg-success'
    } else {
        return 'bg-muted'
    }
}

const getCardClass = (event: SessionEventTimelineItem) => {
    if (isActiveEvent(event)) {
        return 'border-primary'
    } else if (isCompletedEvent(event)) {
        return 'border-success'
    } else {
        return 'border-muted'
    }
}

const getPerformedByName = (item: SessionEventTimelineItem) => {
    let onlineId = item.causer?.mainProfile?.onlineId ?? ''
    let name = item.causer?.name ?? 'System'

    return (onlineId != '' ? `[${onlineId}] ` : ' ') + name
}
</script>

<style scoped>
.event-header {
    display: flex;
    flex-direction: column;
}

.event-image {
    width: 80%;
    /* Default width for all screen sizes */
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 4px;
    max-width: 70dvw;
}

.list-timeline {
    width: 100%;
    padding-left: 0;
    list-style: none;
}

/* Mobile-specific styles */
@media (max-width: 767px) {
    .event-image {
        width: 90%;
    }
}

/* Desktop styles */
@media (min-width: 768px) {
    .event-header {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .list-timeline {
        width: 60%;
        margin: 0 auto;
    }

}
</style>